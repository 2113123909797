import React, { useEffect } from 'react';

// ColorPalette para Carrossel
const ColorPalette = ({ onCombinationSelect, selectedComboId, colorCombinations }) => {
    useEffect(() => {
        // Se não houver combo selecionado e houver combinações disponíveis
        if (!selectedComboId && colorCombinations && colorCombinations.length > 0) {
            onCombinationSelect(colorCombinations[0].colors, colorCombinations[0].comboId);
        }
    }, []);

    const handleCombinationClick = (combination) => {
        onCombinationSelect(combination.colors, combination.comboId, combination.backgroundId);
    };

    const isMobile = window.innerWidth <= 768;
    const displayedColorCombinations = isMobile ? colorCombinations.slice(0, 16) : colorCombinations;

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {displayedColorCombinations.map((combo, index) => (
                <div
                    key={combo.comboId || index}
                    style={{
                        display: 'flex',
                        cursor: 'pointer',
                        margin: '4px',
                        marginTop: isMobile ? (combo.colors.length === 2 ? '10px' : '7px') : (combo.colors.length === 2 ? '30px' : '5px'),
                        height: isMobile ? '27px' : '32px',
                        border: combo.comboId === selectedComboId ? '6px solid #015ECC' : '6px solid lightgray',
                        borderRadius: '5px'
                    }}
                    onClick={() => handleCombinationClick(combo)}
                >
                    {combo.colors.map((color, idx) => (
                        <div 
                            key={idx} 
                            style={{ 
                                width: combo.colors.length === 2 ? (isMobile ? '28px' : '36px') : (isMobile ? '18px' : '25px'),
                                height: isMobile ? '15px' : '20px',
                                background: color.includes('gradient') ? color : color
                            }}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};


// ColorPaletteStories modificado
const ColorPaletteStories = ({ onCombinationSelect, selectedComboId, colorCombinations }) => {
    useEffect(() => {
        if (!selectedComboId && colorCombinations?.length > 0) {
            onCombinationSelect(colorCombinations[0]);  // Passa o objeto completo
        }
    }, []);

    const handleCombinationClick = (combo) => {
        onCombinationSelect(combo);  // Passa o objeto completo
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {colorCombinations.map((combo, index) => (
                <div
                    key={combo.comboId || index}
                    style={{
                        display: 'flex',
                        cursor: 'pointer',
                        margin: '4px',
                        height: '32px',
                        border: combo.comboId === selectedComboId ? '6px solid #8E59FF' : '6px solid lightgray',
                        borderRadius: '5px'
                    }}
                    onClick={() => handleCombinationClick(combo)}
                >
                    {combo.colors.map((color, idx) => (
                        <div 
                            key={idx} 
                            style={{ 
                                width: '36px',
                                height: '20px',
                                background: color.includes('gradient') ? color : color
                            }}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};

// Exporta ambos os componentes
export { ColorPalette, ColorPaletteStories };
export default ColorPalette; // Mantém a exportação padrão para compatibilidade