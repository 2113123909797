import React, { useState, useEffect, useRef, useCallback } from 'react';
import './ControlPanel.css';
import axios from 'axios';
import categories from '../utils/categories';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import '@sweetalert2/theme-material-ui/material-ui.css';
import { FaQuestionCircle } from 'react-icons/fa';
import { Info_1, Info_2, Info_3, Info_4, Info_5, Info_6, Info_7 } from '../utils/toastMessages';
import { useNavigate, useParams } from 'react-router-dom';
import { MdMenu } from "react-icons/md";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

const initializeTestingPrompts = () => {
    const initialState = {
        anxiety: null,
        headline: null,
        carousel: null,
        image: null
    };

    Object.keys(initialState).forEach(category => {
        const key = `testingPromptId_${category}`;
        const value = localStorage.getItem(key);
        if (value) {
            initialState[category] = value;
        }
    });

    return initialState;
};

const ControlPanel = () => {

    const [systemText, setSystemText] = useState("");
    const [userText, setUserText] = useState("");
    const [category, setCategory] = useState("");
    const [getCategory, setGetCategory] = useState("");
    const [targetAudience, setTargetAudience] = useState("");
    const [niche, setNiche] = useState("");
    const [prompts, setPrompts] = useState([]);
    const [responseText, setResponseText] = useState("");
    const [responseImage, setResponseImage] = useState("");
    const [editingId, setEditingId] = useState(null);
    const [testGrid, setTestGrid] = useState({});
    const prevCategoryRef = useRef();
    const [gridOrder, setGridOrder] = useState([]);
    const [niches, setNiches] = useState([]);
    const [tones, setTones] = useState([]);
    const [allTones, setAllTones] = useState([]);
    const [temperatures, setTemperatures] = useState([0.5]);
    const { userId } = useParams();
    const [isAdmin, setIsAdmin] = useState(null);
    const navigate = useNavigate();
    const [testingPrompts, setTestingPrompts] = useState(initializeTestingPrompts());
    const [isGPT4Enabled, setIsGPT4Enabled] = useState(false);

    const [googleSlideLink, setGoogleSlideLink] = useState('');
    const [responseJson, setResponseJson] = useState('');

    const handleFetchJson = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/google-slides', {
                link: googleSlideLink,
            });
            setResponseJson(JSON.stringify(response.data, null, 2));
        } catch (error) {
            console.error('Erro ao buscar JSON:', error);
            Swal.fire('Erro', 'Não foi possível buscar os dados do Google Slides.', 'error');
        }
    };

    const handleChange = (event) => {
        setIsGPT4Enabled(event.target.checked);
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + `/control/${userId}`)
            .then(response => {
                if (response.data.success) {
                    setIsAdmin(true);
                } else {
                    console.log("Acesso negado:", response.data.message);
                    setIsAdmin(false);
                }
            })
            .catch(error => {
                console.log("Erro na chamada:", error);
                setIsAdmin(false);
            });
    }, [userId]);


    useEffect(() => {
        if (isAdmin === false) {
            Swal.fire({
                title: 'Erro',
                text: 'Você não está autorizado a acessar esta tela.',
                icon: 'error'
            });
            navigate('/login');
        }
    }, [isAdmin, navigate]);

    const handleIconClick = (infoType) => {
        // Código para lidar com o clique no ícone vai aqui
        // Por exemplo, você pode abrir um modal ou mostrar um toast
        Swal.fire({
            title: 'Informação Importante',
            icon: 'info',
            text: infoType,
            showConfirmButton: true,
            customClass: {
                container: 'my-swal'
            }
            //confirmButtonText: 'Fechar'
        });
    };

    const fetchPrompts = useCallback(async () => {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/prompts', {
            params: {
                category: category !== "" ? category : undefined, // Passa a categoria como parâmetro, a menos que seja a opção vazia
            },
        });

        const data = response.data;

        const filteredData = data.filter(item =>
            ["anxiety", "headline", "carousel", "image"].includes(item.category)
        );

        console.log(filteredData);
        setPrompts(filteredData);
    }, [category]);


    useEffect(() => {
        fetchPrompts();
        prevCategoryRef.current = category;
    }, [category, fetchPrompts]);


    const prevCategory = prevCategoryRef.current;

    const handleEdit = (id) => {
        const selectedPrompt = prompts.find(prompt => String(prompt._id) === String(id));
        setSystemText(selectedPrompt.prompt.system);
        setUserText(selectedPrompt.prompt.user);
        setEditingId(id);
        setGetCategory(selectedPrompt.category);

    };


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + `/niches/${userId}`)
            .then(response => {
                setNiches(response.data.niches);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/tones')
            .then(response => {
                setAllTones(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);


    const handleSave = async (e) => {
        e.preventDefault();

        // Salva os valores atuais
        const currentSystemText = systemText;
        const currentUserText = userText;

        // Limpa os campos
        setSystemText("");
        setUserText("");

        if (category === "") {
            Swal.fire('Erro', "Por favor, escolha uma categoria", 'error');
            // Restaura os valores antigos
            setSystemText(currentSystemText);
            setUserText(currentUserText);
            return;
        }

        if (systemText === "") {
            Swal.fire('Erro', "Por favor, preencha ao menos o System", 'error');
            return;
        }


        const selectedPrompt = prompts.find(prompt => prompt._id === editingId);

        let prompt = {
            system: systemText,
            user: userText,
            category: category,
            userId: userId
        }

        if ((selectedPrompt && selectedPrompt.edit === false) || getCategory !== category) {
            // Se o prompt atual não for editável, crie um novo
            axios.post(process.env.REACT_APP_API_BASE_URL + '/prompts', { ...prompt, edit: true })
                .then(response => {
                    Swal.fire('Sucesso', 'Registro Inserido com Sucesso!', 'success');
                    fetchPrompts();
                })
                .catch(error => {
                    console.log(error);
                    Swal.fire('Erro', error.toString(), 'error');
                });
        } else {
            const result = await Swal.fire({
                title: 'Criação ou Edição de Prompt',
                text: 'Deseja criar um novo prompt ou alterar o atual?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Criar',
                cancelButtonText: 'Alterar'
            });

            if (result.isConfirmed) {
                axios.post(process.env.REACT_APP_API_BASE_URL + '/prompts', { ...prompt, edit: true })
                    .then(response => {
                        Swal.fire('Sucesso', 'Registro Inserido com Sucesso!', 'success');
                        fetchPrompts();
                    })
                    .catch(error => {
                        console.log(error);
                        Swal.fire('Erro', error.toString(), 'error');
                    });
            } else {
                axios.patch(`${process.env.REACT_APP_API_BASE_URL}/prompts/${selectedPrompt._id}`, prompt)
                    .then(response => {
                        Swal.fire('Sucesso', 'Registro Alterado com Sucesso!', 'success');
                        fetchPrompts();
                    })
                    .catch(error => {
                        console.log(error);
                        Swal.fire('Erro', error.toString(), 'error');
                    });
            }
        }
    }


    const handleSelectPrompt = (selectedPrompt) => {
        setTestGrid((prevTestGrid) => {
            if (prevTestGrid[selectedPrompt.category] === selectedPrompt) {
                const newTestGrid = { ...prevTestGrid };
                delete newTestGrid[selectedPrompt.category];

                // Também remova a temperatura para a categoria
                setTemperatures(prevTemperatures => {
                    const newTemperatures = { ...prevTemperatures };
                    delete newTemperatures[selectedPrompt.category];
                    return newTemperatures;
                });

                setGridOrder(oldOrder => oldOrder.filter(cat => cat !== selectedPrompt.category));

                return newTestGrid;
            } else {
                setGridOrder(oldOrder => {
                    if (!oldOrder.includes(selectedPrompt.category)) {
                        return [...oldOrder, selectedPrompt.category];
                    } else {
                        return oldOrder;
                    }
                });

                // Adicione uma temperatura padrão para a nova categoria
                setTemperatures(prevTemperatures => ({ ...prevTemperatures, [selectedPrompt.category]: 0.5 }));

                return { ...prevTestGrid, [selectedPrompt.category]: selectedPrompt };
            }
        });
    }

    // Esta é a ordem específica que você deseja que as categorias sigam
    const itemOrder = ['anxiety', 'headline', 'carousel', 'image'];

    const sortedGridOrder = gridOrder.sort((a, b) => {
        const indexA = itemOrder.indexOf(a);
        const indexB = itemOrder.indexOf(b);

        // Se ambas as categorias estão em itemOrder, compare seus índices
        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        }

        // Se apenas a está em itemOrder, a deve vir primeiro
        if (indexA !== -1) {
            return -1;
        }

        // Se apenas b está em itemOrder, b deve vir primeiro
        if (indexB !== -1) {
            return 1;
        }

        // Se nenhuma das categorias está em itemOrder, compare-as normalmente
        return a.localeCompare(b);
    });


    const handleDeleteTestPrompt = (category) => {
        const newTestGrid = { ...testGrid };
        delete newTestGrid[category];
        setTestGrid(newTestGrid);
    };

    async function runPrompts() {


        setResponseImage("");
        setResponseText("");
        let thirdHeadline = "";  // Aqui nós definimos a variável para a terceira headline
        let imagePrompt = null;

        Swal.fire({
            //position: 'top-end',
            icon: 'success',
            title: 'Estamos processando as suas respostas',
            showConfirmButton: false,
            timer: 2000
        })

        try {
            // Este objeto armazenará os prompts de cada categoria
            const prompts = {};

            // Este objeto armazenará as respostas de cada categoria
            const responses = {};

            let nicheData, archetype, stack, targetAudienceValue;



            // Percorre as categorias na ordem correta
            for (const category of sortedGridOrder) {
                // Pega os dados dessa categoria

                // Recupera os dados de nicho e tom
                if (category === "image") {
                    nicheData = "";
                    archetype = "";
                    stack = "";
                    targetAudienceValue = "";
                } else {
                    if (!niche || !tones || !targetAudience) {
                        throw new Error("Público alvo, nicho e tom devem estar definidos para todas as categorias exceto para 'image'");
                    }

                    nicheData = niches.find(nicheData => nicheData.niche === niche);
                    archetype = tones.split(',')[0];
                    stack = tones.split(',')[1];
                    targetAudienceValue = targetAudience;
                }

                // TESTAR SEM ANXIETY

                /* if (category === 'headline' && !responses.anxiety) {
                    throw new Error('A categoria "headline" necessita de uma resposta da categoria "anxiety"');
                } */

                // Verifica se a categoria é 'carousel' e se não há resposta de 'headline' ou 'anxiety'
                if (category === 'carousel' && (!responses.headline) /*|| !responses.anxiety)*/) {
                    throw new Error('A categoria "carousel" necessita de uma resposta das categorias "headline" e "anxiety"');
                }

                const categoryData = testGrid[category];

                if (!categoryData) {
                    continue; // Pule para a próxima iteração do loop
                }

                // Constrói o prompt desta categoria
                let systemText = categoryData.prompt.system;
                let userText = categoryData.prompt.user;

                // Substitui os placeholders pelos valores reais
                systemText = systemText
                    .replace('${audience}', targetAudienceValue)
                    .replace('${niche}', nicheData.niche)
                    .replace('${archetype}', archetype)
                    .replace('${stack}', stack);

                userText = userText
                    .replace('${audience}', targetAudienceValue)
                    .replace('${niche}', nicheData.niche)
                    .replace('${archetype}', archetype)
                    .replace('${stack}', stack);

                // Substituir `${headline}` com a terceira headline se ela existir
                if (thirdHeadline) {
                    systemText = systemText.replace('${headline}', thirdHeadline);
                    userText = userText.replace('${headline}', thirdHeadline);
                }

                // Se já tivermos uma resposta de anxiety, usamos na substituição
                if (responses.anxiety) {
                    systemText = systemText.replace('${anxiety}', responses.anxiety);
                    userText = userText.replace('${anxiety}', responses.anxiety);
                }

                // Se já tivermos uma resposta de headline, usamos na substituição
                if (responses.headline) {
                    systemText = systemText.replace('${headline}', responses.headline);
                    userText = userText.replace('${headline}', responses.headline);
                }

                if (imagePrompt) {

                    systemText = imagePrompt;
                }

                // Adiciona os prompts ao objeto prompts
                prompts[category] = { system: systemText, user: userText };

                // Faz a requisição ao servidor para executar o prompt e obter a resposta
                const response = await fetch(process.env.REACT_APP_API_BASE_URL + (category === 'image' ? '/prompts/runImage' : '/prompts/run'), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ category, prompts, temperatures: category === 'image' ? undefined : temperatures[category], useGPT4: isGPT4Enabled }),
                });

                if (response.ok) {
                    const result = await response.json();
                    if (category === 'image') {
                        responses[category] = result.imageUrl;
                        setResponseImage(result.imageUrl);  // Aqui nós definimos a imagem no estado
                    } else {
                        responses[category] = result[category];
                        if (category === 'headline') {
                            const newHeadlines = result[category].split('\n').filter(line => line.trim() !== '');
                            if (newHeadlines.length >= 3) {
                                thirdHeadline = newHeadlines[2];  // Atualizamos a terceira headline aqui
                            }
                        }
                        if (category === 'carousel') {
                            let regex = /(?:Image:|Imagem:|Image - |Imagem - )(.*)/i;
                            let resultado = result[category].match(regex);
                            if (resultado && resultado[1]) {
                                imagePrompt = resultado[1].trim(); // Imprimirá: "aqui vai o texto que vem depois da palavra imagem"
                            }
                        }
                    }
                    let allResponses = "";
                    const categoriesMap = {
                        'anxiety': 'Medos & Ansiedades',
                        'headline': 'Headlines',
                        'carousel': 'Carrossel',
                        // Inclua quaisquer outras categorias aqui
                    };

                    for (const resp in responses) {
                        const categoryInPortuguese = categoriesMap[resp] || resp;
                        allResponses += `${categoryInPortuguese}:\n\n${responses[resp]}\n\n`;
                    }
                    setResponseText(allResponses);
                } else {
                    throw new Error('Failed to get response from server');
                }
            }

            // Agora, 'prompts' contém os prompts para todas as categorias, na ordem correta
            // E 'responses' contém as respostas para cada categoria, na ordem correta
            return { prompts, responses };

        } catch (error) {
            Swal.fire('Erro', error.toString(), 'error');
        }
    }

    const handleTest = (prompt) => {
        setTestingPrompts(prevState => {
            if (prevState[prompt.category] === prompt._id) {
                return { ...prevState, [prompt.category]: null };
            } else {
                return { ...prevState, [prompt.category]: prompt._id };
            }
        });

        // Opcional: Sincronize com o localStorage
        const key = `testingPromptId_${prompt.category}`;
        const storedId = localStorage.getItem(key);
        const promptIdAsString = prompt._id.toString(); // Converta o ID para uma string, se for necessário

        if (storedId === promptIdAsString) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, promptIdAsString);
        }
    };

    const mainOpen = () => {

        Swal.fire({
            title: 'Você tem certeza que deseja ir para a página principal?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            },
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/main');
            }
        });
    };


    const handleApprove = (prompt) => {
        // Inverter o valor atual de 'approve'
        const newApprovalStatus = !prompt.approve;
        // Atualizar no banco de dados (você precisaria definir a URL e método adequados)
        axios.patch(`${process.env.REACT_APP_API_BASE_URL}/prompts/${prompt._id}`, { approve: newApprovalStatus })
            .then(response => {
                // Atualizar o estado do componente para refletir a mudança
                // (isso dependerá de como você está gerenciando o estado dos seus prompts)
                fetchPrompts();
                const updatedPrompts = prompts.map(p =>
                    p._id === prompt._id
                        ? { ...p, approve: newApprovalStatus } // Use 'newApprovalStatus' aqui
                        : p
                );
                setPrompts(updatedPrompts);
            })
            .catch(error => {
                alert(error);
                Swal.fire('Erro', error.toString(), 'error');
            });

    }
    return (

        <div className="control-panel-container">
            <div className="image-container_01">
                <MdMenu
                    className="menu-icon"
                    onClick={mainOpen}
                />
            </div>
            <div className="control-panel">
                <div className="logo-container-panel">
                    <img
                        src="https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"
                        alt="Logo"
                        className="logo-image-panel"
                    />
                </div>

                <h2 style={{ color: '#757575' }}>Painel de Controle </h2>
                <Tabs variant="enclosed" className="tabSilver" colorScheme="purple">
                    <TabList className="tablist_control">
                        <Tab className="tab_control">Criação de Prompts</Tab>
                        <Tab className="tab_control">Integração Google Slides</Tab>
                    </TabList>
                    <TabPanels className="tabPanels_control">
                        <TabPanel className="tabpanel_control">
                            <div className="form-container">
                                <div className="form-container-padding"> {/* Adicionado este elemento */}
                                    <h2 style={{ color: 'grey' }}>Inserção e Edição de Prompts <FaQuestionCircle onClick={() => handleIconClick(Info_1)} style={{ cursor: 'pointer' }} /> </h2>
                                    <form onSubmit={handleSave}>
                                        <label>
                                            System:
                                        </label>
                                        <div className="form-row">
                                            <input type="text" value={systemText} onChange={e => setSystemText(e.target.value)} />
                                            <select style={{ fontSize: '13px' }}
                                                value={category}
                                                onChange={e => {
                                                    if (prevCategory !== "" || e.target.value === "") {
                                                        setSystemText("");
                                                        setUserText("");
                                                    }
                                                    setCategory(e.target.value);
                                                }}
                                            >
                                                <option value="">Selecione a Categoria</option>
                                                {categories.map((category, index) =>
                                                    <option key={index} value={category.name}>{category.translation}</option>
                                                )}
                                            </select>
                                        </div>
                                        <label>
                                            User:
                                        </label>
                                        <div className="form-row">
                                            <input type="text" value={userText} onChange={e => setUserText(e.target.value)} />
                                            <input className="submit-button" type="submit" value="Salvar" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="prompt-grid">
                                <div className="prompt-grid-padding">
                                    <h2 style={{ color: 'grey' }}>Prompts <FaQuestionCircle onClick={() => handleIconClick(Info_6)} style={{ cursor: 'pointer' }}></FaQuestionCircle>
                                        <FaQuestionCircle onClick={() => handleIconClick(Info_7)} style={{ cursor: 'pointer' }}></FaQuestionCircle> </h2>
                                    <div className="grid-row">
                                        <div className="grid-header-2">Categoria</div>
                                        <div className="grid-header">System</div>
                                        <div className="grid-header">User</div>
                                        <div className="grid-header-2">Selecionar</div>
                                        <div className="grid-header-3">Editar</div>
                                        <div className="grid-header-3">Testar</div>
                                        <div className="grid-header-3">Aprovado</div>
                                    </div>
                                    {prompts.map((prompt, index) => (
                                        <div key={index} className="grid-row">
                                            <div className="grid-cell-2">{prompt.category}</div>
                                            <div className="grid-cell" title={prompt.prompt.system}>
                                                {prompt.prompt.system}
                                            </div>
                                            <div className="grid-cell" title={prompt.prompt.user}>
                                                {prompt.prompt.user}
                                            </div>
                                            <div className="grid-cell-2">
                                                <button className="btn-button" style={{ width: '100%', fontSize: '9px' }} onClick={() => handleSelectPrompt(prompt)}>
                                                    {testGrid[prompt.category] === prompt ? 'Deselecionar' : 'Selecionar'}
                                                </button>
                                            </div>
                                            <div className="grid-cell-3"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleEdit(prompt._id)}
                                                title="Editar"
                                            >
                                                <span>✏️</span>
                                            </div>
                                            <div
                                                className="grid-cell-3"
                                                style={{ cursor: 'pointer', textAlign: 'center', fontSize: '20px' }}
                                                onClick={() => handleTest(prompt)}
                                                title="Testar"
                                            >
                                                {testingPrompts[prompt.category] === prompt._id ? '🧪' : '🔧'}
                                            </div>
                                            <div className="grid-cell-3" style={{ textAlign: 'center' }}>
                                                <span
                                                    style={{ cursor: 'pointer', fontSize: '20px', marginRight: '10px' }}
                                                    onClick={() => handleApprove(prompt)}
                                                    title="Aprovado" >
                                                    {prompt.approve ? '✅' : '❌'}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <h1 style={{ color: '#757575' }}>Painel de Controle (Testes de Prompts)</h1>
                            <div className="prompt-tester">
                                <div className="prompt-tester-padding">
                                    <div>
                                        <h2 style={{ color: 'grey' }}>Seleção de Prompts <FaQuestionCircle onClick={() => handleIconClick(Info_2)} style={{ cursor: 'pointer' }} />
                                            <FaQuestionCircle onClick={() => handleIconClick(Info_5)} style={{ cursor: 'pointer' }} /> </h2>
                                    </div>
                                    <label>Assunto:</label>
                                    <div className="input-container">
                                        <input type="text-2" value={targetAudience} onChange={e => setTargetAudience(e.target.value)} />
                                        {/*  <select style={{ marginLeft: '0px' }} value={niche} onChange={e => setNiche(e.target.value)}>
                                <option value="">Selecione o nicho</option>
                                {niches.map((nicheData, index) => (
                                    <option key={index} value={nicheData.niche}>
                                        {nicheData.niche}
                                    </option>
                                ))}
                            </select>  */}
                                        <select value={tones} onChange={e => setTones(e.target.value)}>
                                            <option value="">Selecione o tom</option>
                                            {allTones.map((toneData, index) => (
                                                <option key={index} value={`${toneData.archetype},${toneData.stack}`}>
                                                    {toneData.tone}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="grid-row">
                                        <div className="grid-header-2">Categoria</div>
                                        <div className="grid-header">System</div>
                                        <div className="grid-header">User</div>
                                        <div className="grid-header-2">Sensibilidade</div>
                                        <div className="grid-header-2">Excluir</div>
                                    </div>
                                    {gridOrder.map((category) => {
                                        const prompt = testGrid[category];
                                        if (prompt) {
                                            return (
                                                <div className="tester-row">
                                                    <div className="grid-cell-2">{category}</div>
                                                    <div className="grid-cell" title={prompt.prompt.system}>
                                                        {prompt.prompt.system}
                                                    </div>
                                                    <div className="grid-cell" title={prompt.prompt.user}>
                                                        {prompt.prompt.user}
                                                    </div>
                                                    <div className="grid-cell-2 custom-cell">
                                                        <input style={{ width: '97%' }} type="range" min="0" max="10" value={temperatures[category] * 10} onChange={(e) => {
                                                            setTemperatures(prevTemperatures => ({ ...prevTemperatures, [category]: e.target.value / 10 }));
                                                        }} />
                                                        <div style={{ fontSize: '9px' }}>{temperatures[category]}</div>
                                                    </div>
                                                    <div className="grid-cell-2">
                                                        <button className="btn-button" style={{ width: '100%' }} onClick={() => handleDeleteTestPrompt(category)}>Excluir</button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return null;
                                    })}
                                    <div className="tester-buttons">
                                        <div className="labelRight">
                                            <label className="customLabel">
                                                <span className="labelText">
                                                    <input
                                                        type="checkbox"
                                                        className="customCheckbox"
                                                        checked={isGPT4Enabled}
                                                        onChange={handleChange}
                                                    />
                                                    GPT-4</span>
                                            </label>
                                        </div>
                                        <button onClick={runPrompts}>Executar/Testar</button>
                                    </div>
                                </div>
                            </div >
                            <div className="response-section">
                                <div className="response-section-padding">
                                    <h2 style={{ color: 'grey' }} >Respostas <FaQuestionCircle onClick={() => handleIconClick(Info_3)} style={{ cursor: 'pointer' }} />
                                        <FaQuestionCircle onClick={() => handleIconClick(Info_4)} style={{ cursor: 'pointer' }} /></h2>
                                    <div className="response-row">
                                        <textarea value={responseText} onChange={e => setResponseText(e.target.value)} />
                                        <img src={responseImage} alt="" className="response-image" />
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="tabpanel_control">
                            <div className="google-slides-panel">
                                <div className="google-container">
                                    <h2 style={{ color: 'grey' }}>Importação e Configuração de Templates </h2>  
                                        <label>
                                            Insira o link do Google Slides:
                                        </label>
                                        <div className="form-row">
                                            <input type="text" value={googleSlideLink} placeholder="https://docs.google.com/presentation/d/..."  onChange={(e) => setGoogleSlideLink(e.target.value)} />
                                            <button onClick={handleFetchJson} className="submit-button">
                                                Buscar JSON
                                            </button>
                                        </div>
                                </div>
                                <div className="google-container">
                                    <textarea
                                        value={responseJson}
                                        readOnly
                                        placeholder="O JSON retornado aparecerá aqui."
                                        className="response-textarea"
                                    />
                                </div>
                            </div>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div >
        </div >

    );
}

export default ControlPanel;
