import React, { useState, useEffect, memo } from 'react';
import {
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter,
    Button, Stack, Checkbox, Text, ChakraProvider
} from '@chakra-ui/react';
import { MdRemoveRedEye } from 'react-icons/md';
import { SketchPicker } from 'react-color';
import { ColorPalette } from './colorPalette';
import axios from 'axios';


const PreviewBox = ({ colors, fonts }) => {
    const [fontsLoaded, setFontsLoaded] = useState(false);

    useEffect(() => {
        const checkFonts = async () => {
            if (fonts.titleFont && fonts.textFont) {
                // Esperar um momento para as fontes carregarem
                await new Promise(resolve => setTimeout(resolve, 100));

                const titleLoaded = document.fonts.check(`12px "${fonts.titleFont}"`);
                const textLoaded = document.fonts.check(`12px "${fonts.textFont}"`);

                console.log('Status das fontes:', {
                    titleFont: fonts.titleFont,
                    titleLoaded,
                    textFont: fonts.textFont,
                    textLoaded
                });

                setFontsLoaded(titleLoaded && textLoaded);
            }
        };

        checkFonts();
    }, [fonts.titleFont, fonts.textFont]);

    const titleStyle = {
        color: colors.Titulos || colors.textColor,
        fontFamily: fonts.titleFont ?
            `"${fonts.titleFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif` :
            'system-ui, sans-serif',
        margin: 0,
        fontWeight: '700'  // Garantir que a fonte carregue com peso bold
    };

    const textStyle = {
        color: colors.Textos || colors.secondaryText,
        fontFamily: fonts.textFont ?
            `"${fonts.textFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif` :
            'system-ui, sans-serif',
        margin: 0,
        fontWeight: '400'  // Peso normal para o texto
    };

    return (
        <div className="previewContainer">
            <div className="previewContent"
                style={{
                    backgroundColor: colors.Fundo || colors.backgroundColor
                }}
            >
                <div className="postPreview">
                    <div className="titleWithIcon">
                        <h4 style={titleStyle}>
                            Título do seu Post
                        </h4>
                        <MdRemoveRedEye
                            className="iconEye"
                            style={{
                                color: colors.Titulos || colors.textColor
                            }}
                        />
                    </div>
                    <p style={textStyle}>
                        Texto do seu Post.
                    </p>
                </div>
            </div>
        </div>
    );
};


const StyleCustomization = ({
    fontList,
    initialFonts = { titleFont: '', textFont: '' },
    colorCombinations,
    onStyleChange,
    initialColors
}) => {

    // Definir cores default
    const DEFAULT_COLORS = {
        Titulos: '#ffffff',
        Textos: '#ffffff',
        Fundo: '#ffffff'
    };

    // Estados
    const [colors, setColors] = useState(initialColors || DEFAULT_COLORS);
    const [standardColors, setStandardColors] = useState(initialColors);
    const [customColors, setCustomColors] = useState(() => {
        return JSON.parse(localStorage.getItem('customColors')) || initialColors;
    });
    const [fonts, setFonts] = useState(initialFonts);
    const [saveAsDefault, setSaveAsDefault] = useState(() => {
        return localStorage.getItem('useCustomStyle') === 'true';
    });
    const [activeTab, setActiveTab] = useState(0);

    // Estados para o preview
    const [previewColors, setPreviewColors] = useState(initialColors);

    // Estados UI
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [selectedColorType, setSelectedColorType] = useState(null);
    const [selectedComboId, setSelectedComboId] = useState(colorCombinations[0]?.comboId || null);
    const [initialTab, setInitialTab] = useState(0);


    useEffect(() => {
        // Garante que temos as combinações de cores e que não estamos usando estilo customizado
        if (colorCombinations?.length > 0) {
            const firstCombo = colorCombinations[0];
            const initialColors = {
                Titulos: firstCombo.colors[0],
                Textos: firstCombo.colors[1],
                Fundo: firstCombo.colors[2]
            };

            // Definir cores iniciais
            setStandardColors(initialColors);

            // Se não tiver estilo customizado, atualiza o preview também
            if (!saveAsDefault) {
                
                setPreviewColors(initialColors);
                setSelectedComboId(firstCombo.comboId);

                onStyleChange({
                    colors: initialColors,
                    fonts: { titleFont: '', textFont: '' },
                    useCustomStyle: false,
                    comboId: firstCombo.comboId
                });
            }
        }
    }, [colorCombinations]);





    const handleColorSelection = (colors, comboId) => {
        // Criar objeto de cores novas
        const newColors = {
            Titulos: colors[0],
            Textos: colors[1],
            Fundo: colors[2]
        };

        // Atualizar estados das cores
        setStandardColors(newColors);
        setPreviewColors(newColors);
        setSelectedComboId(comboId);

        if (activeTab === 0) {
            // Desmarcar checkbox quando selecionar cor na tab 1
            setSaveAsDefault(false);

            // Limpar localStorage
            localStorage.setItem('useCustomStyle', 'false');
            localStorage.removeItem('customColors');
            localStorage.removeItem('customFonts');

            // Resetar fontes para vazio
            const emptyFonts = { titleFont: '', textFont: '' };
            setFonts(emptyFonts);

            // Notificar mudanças
            onStyleChange({
                colors: newColors,
                fonts: emptyFonts,
                useCustomStyle: false,
                comboId
            });
        }
    };


    // Primeiro useEffect: Carrega as fontes do Google Fonts
    useEffect(() => {
        const loadFonts = async () => {
            try {
                const validFonts = fontList.filter(font => font.value);
                const fontFamilies = validFonts
                    .map(font => `family=${font.value.replace(/ /g, '+')}:wght@400;700`)
                    .join('&');

                const url = `https://fonts.googleapis.com/css2?${fontFamilies}&display=swap`;

                const existingLink = document.querySelector(`link[href^="https://fonts.googleapis.com/css2"]`);
                if (existingLink) {
                    existingLink.href = url;
                } else {
                    const link = document.createElement('link');
                    link.href = url;
                    link.rel = 'stylesheet';
                    document.head.appendChild(link);
                }

                await document.fonts.ready;

                validFonts.forEach(font => {
                    const isLoaded = document.fonts.check(`12px "${font.value}"`);
                    console.log(`Fonte ${font.value} carregada:`, isLoaded);
                });

            } catch (error) {
                console.error('Erro ao carregar fontes:', error);
            }
        };

        loadFonts();
    }, [fontList]);

    useEffect(() => {
        const loadSavedStyles = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/profiles/get-style`, {
                    userId: localStorage.getItem('userId'),
                    totenId: localStorage.getItem('totenId')
                });

                if (response.data?.colors) {
                    const hasCustomColors =
                        response.data.colors.Titulos !== '#ffffff' ||
                        response.data.colors.Textos !== '#ffffff' ||
                        response.data.colors.Fundo !== '#ffffff';

                    if (hasCustomColors) {
                        // Se tem cores customizadas, primeiro seta os estados
                        setCustomColors(response.data.colors);
                        setPreviewColors(response.data.colors);
                        setSaveAsDefault(true);
                        setActiveTab(1);  // Define a tab ativa como 1

                        if (response.data.fonts) {
                            const savedFonts = {
                                titleFont: response.data.fonts.titleFont || '',
                                textFont: response.data.fonts.textFont || ''
                            };

                            setFonts(savedFonts);
                            localStorage.setItem('customFonts', JSON.stringify(savedFonts));

                            onStyleChange({
                                colors: response.data.colors,
                                fonts: savedFonts,
                                useCustomStyle: true
                            });
                        }

                        localStorage.setItem('useCustomStyle', 'true');
                        localStorage.setItem('customColors', JSON.stringify(response.data.colors));
                    } else {
                        // Se não tem cores customizadas
                        setSaveAsDefault(false);
                        setActiveTab(0);  // Define a tab ativa como 0

                      
                        //   setPreviewColors(standardColors);
                        setFonts({ titleFont: '', textFont: '' });

                        localStorage.setItem('useCustomStyle', 'false');
                        localStorage.removeItem('customColors');
                        localStorage.removeItem('customFonts');
                    }
                }
            } catch (error) {
                console.error('Erro ao carregar estilos:', error);
                setActiveTab(0);
                setSaveAsDefault(false);
            }
        };

        loadSavedStyles();
    }, []);


    // Handlers
    const openColorPicker = (colorType) => {
        setSelectedColorType(colorType);
        setShowColorPicker(true);
    };

    // Handler para o color picker na segunda tab
    const handleColorChange = (color) => {
        const newColors = {
            ...customColors,
            [selectedColorType]: color.hex
        };

        setCustomColors(newColors);
        setPreviewColors(newColors);

        if (saveAsDefault) {
            localStorage.setItem('customColors', JSON.stringify(newColors));
            saveToDatabase(newColors, fonts);

            onStyleChange({
                colors: newColors,
                fonts,
                useCustomStyle: true
            });
        }
    };


    const handleFontChange = async (type, value) => {
        if (!value) return;

        console.log(`Alterando fonte ${type}:`, value);

        // Verificar se a fonte está disponível
        const isAvailable = document.fonts.check(`12px "${value}"`);
        console.log(`Fonte ${value} disponível:`, isAvailable);

        if (!isAvailable) {
            const link = document.createElement('link');
            link.href = `https://fonts.googleapis.com/css2?family=${value.replace(/ /g, '+')}:wght@400;700&display=swap`;
            link.rel = 'stylesheet';
            document.head.appendChild(link);
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        const newFonts = {
            ...fonts,
            [type]: value
        };

        setFonts(newFonts);

        // Se estiver usando estilo personalizado, salva as alterações
        if (saveAsDefault) {
            localStorage.setItem('customFonts', JSON.stringify(newFonts));
            saveToDatabase(customColors, newFonts); // Salva no banco com as cores atuais
        }

        onStyleChange?.({
            colors: customColors,
            fonts: newFonts,
            useCustomStyle: saveAsDefault
        });
    };

    const handleSaveDefault = async (e) => {
        const isChecked = e.target.checked;
        setSaveAsDefault(isChecked);

        if (isChecked) {
            try {
                // Primeiro, verificar se temos fontes customizadas no state atual
                const currentFonts = fonts;
                const hasFontsSelected = currentFonts.titleFont || currentFonts.textFont;

                // Se não temos fontes selecionadas, tentar recuperar do banco
                if (!hasFontsSelected) {
                    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/profiles/get-style`, {
                        userId: localStorage.getItem('userId'),
                        totenId: localStorage.getItem('totenId')
                    });

                    if (response.data && response.data.fonts) {
                        const savedFonts = {
                            titleFont: response.data.fonts.titleFont || '',
                            textFont: response.data.fonts.textFont || ''
                        };
                        setFonts(savedFonts);
                        localStorage.setItem('customFonts', JSON.stringify(savedFonts));
                    }
                } else {
                    // Se temos fontes selecionadas, usar as atuais
                    localStorage.setItem('customFonts', JSON.stringify(currentFonts));
                }

                // Salvar as cores customizadas
                localStorage.setItem('useCustomStyle', 'true');
                localStorage.setItem('customColors', JSON.stringify(customColors));
                setPreviewColors(customColors);

                // Salvar no banco de dados
                saveToDatabase(customColors, hasFontsSelected ? currentFonts : fonts);

                onStyleChange({
                    colors: customColors,
                    fonts: hasFontsSelected ? currentFonts : fonts,
                    useCustomStyle: true
                });
            } catch (error) {
                console.error('Erro ao recuperar fontes:', error);
            }
        } else {
            // Quando desmarcar a checkbox:
            // 1. Limpar localStorage
            localStorage.setItem('useCustomStyle', 'false');
            localStorage.removeItem('customColors');
            localStorage.removeItem('customFonts');

            // 2. Resetar fontes para vazio
            const emptyFonts = { titleFont: '', textFont: '' };
            setFonts(emptyFonts);

            // 3. Voltar para cores padrão da tab 1
            setPreviewColors(standardColors);

            // 4. Notificar mudança com fontes vazias e cores padrão
            onStyleChange({
                colors: standardColors,
                fonts: emptyFonts,
                useCustomStyle: false,
                comboId: selectedComboId
            });

            // 5. Mudar para a primeira tab
            setActiveTab(0);
        }
    };

    const saveToDatabase = async (colors, fonts) => {
        try {
            console.log('Salvando no banco:', { colors, fonts });

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/profiles/save-style`, {
                userId: localStorage.getItem('userId'),
                totenId: localStorage.getItem('totenId'),
                colors: {
                    Titulos: colors.Titulos || '#ffffff',
                    Textos: colors.Textos || '#ffffff',
                    Fundo: colors.Fundo || '#ffffff'
                },
                fonts: {
                    titleFont: fonts.titleFont || '',
                    textFont: fonts.textFont || ''
                },
                useCustomStyle: true // Adiciona este flag
            });

            if (response.data.success) {
                console.log('Estilo salvo com sucesso');
            } else {
                console.error('Erro ao salvar estilo');
            }
        } catch (error) {
            console.error('Erro ao salvar estilo:', error);
        }
    };


    // Handler para a troca de tabs
    const handleTabChange = (index) => {
        setActiveTab(index);

        if (index === 0) {
            // Na tab padrão, sempre reseta fontes e cores
            const emptyFonts = { titleFont: '', textFont: '' };
            setFonts(emptyFonts);
            setPreviewColors(standardColors);

            // Reseta também o estado do checkbox
            setSaveAsDefault(false);
            localStorage.setItem('useCustomStyle', 'false');

            onStyleChange({
                colors: standardColors,
                fonts: emptyFonts,
                useCustomStyle: false
            });
        } else {
            // Na tab 2, verifica se estava usando customizado antes
            const wasCustom = localStorage.getItem('useCustomStyle') === 'true';
            setSaveAsDefault(wasCustom);

            if (wasCustom) {
                setPreviewColors(customColors);
                onStyleChange({
                    colors: customColors,
                    fonts,
                    useCustomStyle: true
                });
            } else {
                setPreviewColors(standardColors);
                onStyleChange({
                    colors: standardColors,
                    fonts,
                    useCustomStyle: false
                });
            }
        }
    };

    return (
        <>
            <PreviewBox colors={previewColors} fonts={fonts} />
            <Tabs
                index={activeTab}
                onChange={handleTabChange}
                variant="enclosed"
                className="tabSilverDesign"
                colorScheme="purple"
            >
                <TabList className="tablist_control_design">
                    <Tab className="tab_control_design">Padrão</Tab>
                    <Tab className="tab_control_design">Estilo Próprio</Tab>
                </TabList>

                <TabPanels className="tabPanels_control_design">
                    <TabPanel className="tabpanel_control_design">
                        <div className="colorGroup">
                            <ColorPalette
                                onCombinationSelect={handleColorSelection}
                                selectedComboId={selectedComboId}
                                colorCombinations={colorCombinations}
                            />
                        </div>
                    </TabPanel>

                    <TabPanel className="tabpanel_control_design">
                        <div className="style-customization-container">
                            <div className="color-section">
                                <div className="header-with-checkbox">
                                    <label className="style-label">Paleta de Cores</label>
                                </div>
                                <div className="color-grid">
                                    {['Titulos', 'Textos', 'Fundo'].map((colorType) => (
                                        <div key={colorType} className="color-item">
                                            <span className="color-name">{colorType}</span>
                                            <div
                                                className="color-picker"
                                                style={{ backgroundColor: previewColors[colorType] }}
                                                onClick={() => openColorPicker(colorType)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="font-selection">
                                <div className="font-row">
                                    {['title', 'text'].map((type) => (
                                        <div key={type} className="font-group">
                                            <label className="font-label">
                                                {type === 'title' ? 'Título:' : 'Texto:'}
                                            </label>
                                            <select
                                                className="font-select"
                                                value={fonts[`${type}Font`] || ''}
                                                onChange={(e) => handleFontChange(`${type}Font`, e.target.value)}
                                            >
                                                <option value="" disabled>Selecione uma fonte</option>
                                                {fontList.filter(font => font.value).map(font => (
                                                    <option key={font.value} value={font.value}>
                                                        {font.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <ChakraProvider>
                                <Stack className="stk" spacing={4} direction='row'>
                                    <Checkbox
                                        size='lg'
                                        colorScheme='purple'
                                        isChecked={saveAsDefault}
                                        onChange={handleSaveDefault}
                                    >
                                        <Text color="#757575" fontSize="sm">
                                            Usar este estilo como padrão
                                        </Text>
                                    </Checkbox>
                                </Stack>
                            </ChakraProvider>
                        </div>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {showColorPicker && (
                <ChakraProvider>
                    <Modal isOpen={showColorPicker} onClose={() => setShowColorPicker(false)}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalBody display="flex" justifyContent="center">
                                <SketchPicker
                                    color={colors[selectedColorType]}
                                    onChange={handleColorChange}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    onClick={() => setShowColorPicker(false)}
                                    sx={{
                                        backgroundColor: '#8E59FF',
                                        color: 'white',
                                    }}
                                >
                                    Confirmar
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </ChakraProvider>
            )}
        </>
    );
};

export default memo(StyleCustomization);