import React, { useState, useEffect, useCallback, memo } from 'react';
import axios from 'axios';

// Componente de slide memorizado
const TemplateSlide = memo(({ template, currentSlide, onNext, onPrev, onSelect, isSelected }) => {

    const boxClassName = `templateBox ${isSelected ? 'selected' : ''}`.trim();

    return (
        <div
            className={boxClassName}
            onClick={(e) => {
                e.stopPropagation(); // Previne propagação do evento
                onSelect(template.name);
                console.log('Selecionado:', template.name, 'isSelected:', isSelected);
            }}
        >
            <div className="templateContainer">
                <div className="slideContainer">
                    <img
                        src={template.slides[currentSlide]?.thumbnailUrl}
                        alt={`Template ${template.name}`}
                        className="templateImage"
                    />
                    <button
                        className="navButton prevButton"
                        onClick={() => onPrev(template.id)}
                    >
                        <span>‹</span>
                    </button>
                    <button
                        className="navButton nextButton"
                        onClick={() => onNext(template.id)}
                    >
                        <span>›</span>
                    </button>
                </div>
            </div>
            <div className="templateName">
                {template.name} ({template.slides?.length || 0} slides)
            </div>
        </div>
    );
});

// Componente principal com lógica otimizada
const TemplateGrid = ({ setSelectedFormat, selectedFormat }) => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentSlides, setCurrentSlides] = useState({});

    // Fetch templates apenas uma vez na montagem inicial
    useEffect(() => {
        let isMounted = true;

        const fetchTemplates = async () => {
            try {
                setLoading(true);
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/templates');

                if (isMounted) {
                    setTemplates(response.data);
                    // Inicializa o estado dos slides atuais
                    const initialSlides = {};
                    response.data.forEach(template => {
                        initialSlides[template.id] = 0;
                    });
                    setCurrentSlides(initialSlides);
                }
            } catch (error) {
                console.error('Erro ao buscar templates:', error);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        fetchTemplates();

        return () => {
            isMounted = false;
        };
    }, []);

    const handleSelect = (templateName) => {
        console.log('Selecionando template:', templateName);
        setSelectedFormat(templateName); // Atualiza o estado com o nome do template
    };

    // Handlers memorizados para evitar recriações desnecessárias
    const nextSlide = useCallback((templateId) => {
        setCurrentSlides(prev => {
            const currentIndex = prev[templateId];
            const template = templates.find(t => t.id === templateId);
            const totalSlides = template.slides.length;
            return {
                ...prev,
                [templateId]: (currentIndex + 1) % totalSlides
            };
        });
    }, [templates]);

    const prevSlide = useCallback((templateId) => {
        setCurrentSlides(prev => {
            const currentIndex = prev[templateId];
            const template = templates.find(t => t.id === templateId);
            const totalSlides = template.slides.length;
            return {
                ...prev,
                [templateId]: (currentIndex - 1 + totalSlides) % totalSlides
            };
        });
    }, [templates]);

    if (loading) {
        return <div className="templateGrid">Carregando templates...</div>;
    }

    return (
        <div className="templateGrid">
            {templates.map(template => {

                const isSelected = selectedFormat === template.name;
                console.log(`Template ${template.name}:`, { isSelected, selectedFormat });

                // console.log('selectedFormat:', selectedFormat, 'template.name:', template.name); // Adicione este log para depurar
                return (
                    <TemplateSlide
                        key={template.id}
                        template={template}
                        currentSlide={currentSlides[template.id]}
                        onNext={nextSlide}
                        onPrev={prevSlide}
                        onSelect={handleSelect}
                        isSelected={selectedFormat === template.name}
                    />
                );
            })}
        </div>
    );
};

export default memo(TemplateGrid);