import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import Joyride from 'react-joyride';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import axios from 'axios';
import "./MainScreen.css";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import '@sweetalert2/theme-material-ui/material-ui.css';
import 'animate.css';
import { Info_9, Info_13, Info_14 } from '../utils/toastMessages';
import { colorCombinations } from '../utils/colorCombinations';
import { colorCombinationsStories } from '../utils/colorCombinations';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ColorPaletteStories } from '../utils/colorPalette';
import ImageSelector from '../utils/imageSelector';
import TemplateGrid from '../utils/templateGrid';
import StyleCustomization from "../utils/styleCustomization";
import CustomDrawer from './CustomDrawer';
import translations from '../utils/translations.json';
import { useLocation } from 'react-router-dom';
import fontList from '../utils/fontList';
import { FaInstagram } from 'react-icons/fa';
import { useCanva } from './CanvaContext';
import {
    Center,
    ChakraProvider,
    Divider,
    RadioGroup,
    Radio,
    Stack,
    Checkbox,
    Text,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    useMediaQuery,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button
} from "@chakra-ui/react";
import { SketchPicker } from 'react-color';
import { MdMenu, MdRestartAlt, MdCopyAll, MdSave, MdShare, MdBolt, MdStarHalf, MdDownload, MdAssistant, MdEdit, MdOutlineCompareArrows, MdCheck, MdWhatshot, MdFavorite, MdDateRange, MdRemoveRedEye } from "react-icons/md";


SwiperCore.use([Navigation, Pagination]);

export default function MainScreen() {

    const [selectedCarousel, setSelectedCarousel] = useState(null);
    const [targetAudience, setTargetAudience] = useState('');
    const [instagramUrl, setInstagramUrl] = useState('');
    const [inputTitle, setInputTitle] = useState('');
    const [inputTitleInsta, setInputTitleInsta] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [inputText, setInputText] = useState('');
    const [topic, setTopic] = useState("");
    const [swiperRef, setSwiperRef] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [outcomes, setOutcomes] = useState([]);
    const [selectedOutcome, setSelectedOutcome] = useState('');
    const [isLoadingOutcomes, setIsLoadingOutcomes] = useState(false);
    const [headlines, setHeadlines] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [selectedHeadline, setSelectedHeadline] = useState(null);
    const [carousels, setCarousels] = useState([]);
    const [isLoadingCarousel, setIsLoadingCarousel] = useState(false);
    const [images, setImages] = useState([]);
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(false);
    const [isCarouselType, setIsCarouselType] = useState(false);
    const [shouldShowStylePrompt, setShouldShowStylePrompt] = useState(false);
    const [carouselEdit, setCarouselEdit] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [isntaId, setIsntaId] = useState('');
    const [selectedFormat, setSelectedFormat] = useState(null);
    const userName = localStorage.getItem('username');
    const nameUser = localStorage.getItem('name');
    const lastnameUser = localStorage.getItem('lastname');
    const userId = localStorage.getItem('userId');
    const typeofPlan = localStorage.getItem('typeofPlan');
    const creditsString = localStorage.getItem('credits');
    let credits = creditsString ? JSON.parse(creditsString) : null;
    const navigate = useNavigate();
    const catAnxiety = localStorage.getItem('testingPromptId_anxiety');
    const catHeadline = localStorage.getItem('testingPromptId_headline');
    const catCarousel = localStorage.getItem('testingPromptId_carousel');
    const catImage = localStorage.getItem('testingPromptId_image');
    const [total_tokens, setTotalTokens] = useState('');
    const [total_time_api, setTimeApi] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [hasSelectedHeadlineBefore, setHasSelectedHeadlineBefore] = useState(false);
    const [selectedColors, setSelectedColors] = useState(colorCombinations[0].colors);
    const [titleFont, setTitleFont] = useState(''); // valor padrão
    const [textFont, setTextFont] = useState(''); // valor padrão
    const [selectedComboId, setSelectedComboId] = useState(colorCombinations[0].comboId);
    const [selectedBackGroundId, setSelectedBackGroundId] = useState('');
    const [isCaptionVisible, setIsCaptionVisible] = useState(false);
    const imageUrl = `https://blob01instasuccess.blob.core.windows.net/fotos-logos/${selectedFormat}_${selectedComboId}.png`;
    const [resumingSession, setResumingSession] = useState(false);
    const [caption, setCaption] = useState("");
    const [isLoadingCap, setIsLoadingCap] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedPCI, setIsCheckedPCI] = useState(false);
    const [isCheckedPart, setIsCheckedPart] = useState(false);
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
    const [isCheckboxDisabledPCI, setIsCheckboxDisabledPCI] = useState(true);
    const [isTablet] = useMediaQuery('(max-width: 1280px)');
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const [currentTabPage, setCurrentTabPage] = useState(0);
    const [selectedSlides, setSelectedSlides] = useState(""); // Ajuste este valor conforme a seleção do usuário (3, 5, ou 10)
    const [isStories, setIsStories] = useState(false);
    const [selectedSlidesStories, setSelectedSlidesStories] = useState("");
    const tabsPerPage = isStories ? selectedSlidesStories : (isMobile ? 5 : selectedSlides);
    const [tabIndex, setTabIndex] = useState(0);
    const [tamplateId, setTamplateId] = useState('');
    const [isFetchingTemplates, setIsFetchingTemplates] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [textParts, setTextParts] = useState([]);
    const [summary, setSummary] = useState('');
    const [selectedLayValue, setSelectedLayValue] = useState('sqr');
    //const [accessToken, setAccessToken] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [designId, setDesignId] = useState("");
    const [canvasUrl, setCanvasUrl] = useState("");
    const [isTotenId, setIsTotenId] = useState("");
    const [profileId, setProfileId] = useState("");
    const [tokenExpiriedFace, setTokenExpiriedFace] = useState(false);
    const { checkCanvaConnection } = useCanva();
    const [initialStyleLoaded, setInitialStyleLoaded] = useState(false);

    const location = useLocation();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'pt');
    const t = (screen, key) => translations[language][screen][key];


    const [styleConfig, setStyleConfig] = useState({
        colors: {
            Titulos: '#FFFFFF',
            Textos: '#FFFFFF',
            Fundo: '#000000'
        },
        fonts: {
            titleFont: fontList[0].value,
            textFont: fontList[0].value
        }
    });


    // Mantenha apenas o previewColors para a visualização
    const [previewColors, setPreviewColors] = useState({
        backgroundColor: styleConfig.colors.Fundo,
        textColor: styleConfig.colors.Titulos,
        secondaryText: styleConfig.colors.Textos
    });


    const [colors, setColors] = useState({
        Titulos: '#ffffff',
        Textos: '#ffffff',
        Fundo: '#ffffff'
    });

    const labels = {
        Titulos: t('profile', 'titles'),
        Textos: t('profile', 'texts'),
        Fundo: t('profile', 'background')
    };


    // Novos estados
    const [saveAsDefault, setSaveAsDefault] = useState(false);

    // Função para atualizar o preview em tempo real
    useEffect(() => {
        setPreviewColors({
            backgroundColor: colors.Fundo,
            textColor: colors.Titulos,
            secondaryText: colors.Textos
        });
    }, [colors]);

    useEffect(() => {

        if (isStories) {
            // Para Stories, sempre usa o combo padrão
            if (colorCombinationsStories?.length > 0) {
                const firstCombo = colorCombinationsStories[0];
                handleColorSelection(firstCombo, firstCombo.comboId);
            }
        }
    }, []);



    useEffect(() => {

        console.log('styleConfig atualizado:', styleConfig); // Debug

        // Se estiver usando estilo customizado, garantir que as fontes estão definidas
        if (styleConfig.useCustomStyle) {

            const customFonts = JSON.parse(localStorage.getItem('customFonts')) || {};
            if (!styleConfig.fonts.titleFont && !styleConfig.fonts.textFont) {
                setStyleConfig(prev => ({
                    ...prev,
                    fonts: {
                        titleFont: customFonts.titleFont || prev.fonts.titleFont,
                        textFont: customFonts.textFont || prev.fonts.textFont
                    }
                }));
            }
        }
    }, [styleConfig.useCustomStyle]);



    const handleColorSelection = (colorsOrCombination, comboId, backgroundId) => {

        if (isStories) {

            const combination = colorsOrCombination;

            setSelectedComboId(combination.comboId);

            const newStyleConfig = {
                colors: {
                    Titulos: combination.colors[0],
                    Textos: combination.colors[0],
                    Fundo: combination.colors[1]
                },
                fonts: {
                    titleFont: fontList[0].value,
                    textFont: fontList[0].value
                }
            };

            setStyleConfig(newStyleConfig);

            setPreviewColors({
                backgroundColor: combination.colors[1],
                textColor: combination.colors[0],
                secondaryText: combination.colors[0]
            });

            if (combination.backgroundId) {
                setSelectedBackGroundId(combination.backgroundId);
            }

        }

    };


    // Na tela principal
    const handleStyleChange = ({ colors, fonts, useCustomStyle, comboId }) => {

        // Se for Stories, não permita customização
        if (isStories) return;

        console.log('handleStyleChange recebeu:', { colors, fonts, useCustomStyle, comboId }); // Debug

        // Atualiza styleConfig garantindo que as fontes são incluídas
        const newStyleConfig = {
            colors,
            fonts: {
                titleFont: fonts.titleFont || styleConfig.fonts.titleFont,
                textFont: fonts.textFont || styleConfig.fonts.textFont
            },
            useCustomStyle
        };

        setStyleConfig(newStyleConfig);

        // Atualiza preview
        setPreviewColors({
            backgroundColor: colors.Fundo,
            textColor: colors.Titulos,
            secondaryText: colors.Textos
        });

        if (comboId) {
            setSelectedComboId(comboId);
        }

        // Debug do novo estado
        console.log('Novo styleConfig:', newStyleConfig);
    };

    /* ## PUBLICAR NO INSTAGRAM (INÍCIO) ## */

    function totenIdToIndex(totenId) {
        const suffix = totenId.replace('perfil', ''); // Remove o prefixo 'perfil'
        const index = parseInt(suffix, 10) - 1; // Converte a parte numérica para integer e ajusta para base zero
        return index;
    }


    const showInstagramAccount = async (userId, totenId) => {
        try {

            // Faz uma requisição ao backend para obter as informações básicas do Instagram, usando POST e enviando userId e totenId no corpo.
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/users/basic-instagram-info', {
                userId: userId,
                totenId: totenIdToIndex(totenId)
            });

            const instagramAccount = response.data;

            // Caso não encontre uma conta de Instagram conectada
            if (!instagramAccount.instagramUsername || !instagramAccount.instagramPicture) {
                Swal.fire({
                    title: t('main', 'noInstagramAccountTitle'),
                    text: t('main', 'noInstagramAccountText'),
                    icon: 'warning',
                    confirmButtonText: t('main', 'confirmButtonText'),
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
                return;
            }

            // Exibir o popup com as informações da conta do Instagram para confirmação
            const { value: confirmed } = await Swal.fire({
                title: t('main', 'instagramPostAccountTitle'),
                html: `
                    <div class="scrollable-container-pop-up" style="display: flex; align-items: center; justify-content: center;">
                        <div class="account-item" style="display: flex; align-items: center; margin-bottom: 0px;">
                            <img src="${instagramAccount.instagramPicture}" alt="${instagramAccount.instagramUsername}" style="width: 65px; height: 65px; border-radius: 50%; margin-right: 10px;" />
                            <span style="font-size: 18px; font-weight: bold;">${instagramAccount.instagramUsername}</span>
                        </div>
                    </div>
                `,
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                },
                confirmButtonText: t('main', 'confirmPostButton'),
                cancelButtonText: t('main', 'cancelPostButton'),
                showCancelButton: true,
                allowOutsideClick: true,
                heightAuto: false
            });

            // Se o usuário confirmou a seleção
            if (confirmed) {
                // Chama a função de publicação passando `userId`, `totenId` e `selectedAccountId`
                publishCarrossel(userId, totenId);
            }
        } catch (error) {
            console.error('Erro ao buscar informações da conta do Instagram:', error);

            Swal.fire({
                title: t('main', 'errorTitle'),
                text: t('main', 'instagramAccountError'),
                icon: 'error',
                confirmButtonText: t('main', 'confirmButtonText'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    };

    const publishCarrossel = async (userId, totenId) => {
        try {

            setIsLoadingImage(true);
            setLoadingMessage(isStories ? t('main', 'publishingStories') : t('main', 'publishingCarousel'));

            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/publish-media', {
                userId: userId,
                totenId: totenId,
                presentationId: carouselEdit,
                mediaType: isStories ? 'STORY' : 'CAROUSEL',
                firstTime: true

            });

            setIsLoadingImage(false); // Finaliza o loading da imagem

            // Se o servidor respondeu com sucesso

            if (response.status === 200) {

                setIsLoadingImage(false);

                Swal.fire({
                    title: t('main', 'success'),
                    text: t('main', 'publishingSuccess'),
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            } else {
                // Se a resposta não foi o que esperávamos

                setIsLoadingImage(false);

                Swal.fire({
                    title: t('main', 'errorTitle'),
                    text: t('main', 'publishingError'),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            }
        } catch (error) {
            // Erro na comunicação com a API ou no processamento da resposta

            console.error('Erro ao publicar no Instagram:', error);

            setIsLoadingImage(false);

            Swal.fire({
                title: t('main', 'errorTitle'),
                text: t('main', 'instagramPublishError'),
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    };


    /* ## AGENDAMENTO DE POSTS ## */

    // Função de Agendamento no Front-end

    async function agendamento(userId, totenId) {

        const presentationId = carouselEdit;

        try {

            // Buscar o agendamento existente para o conteúdo
            const existingScheduleResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/getSchedulePost`, {
                params: { presentationId }
            });

            let existingSchedule = null;

            if (existingScheduleResponse.status === 200) {
                existingSchedule = existingScheduleResponse.data;
            }


            if (!existingSchedule) {

                let userChoice = localStorage.getItem('popupChoice_3');

                if (userChoice !== 'checked') {

                    Swal.fire({
                        title: t('main', 'confirmCreditUsage'),
                        showCancelButton: true,
                        confirmButtonText: t('main', 'yes'),
                        cancelButtonText: t('main', 'no'),
                        input: 'checkbox',
                        inputValue: 0,
                        inputPlaceholder: t('main', 'doNotShowAgain'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (result.value === 1) {

                                localStorage.setItem('popupChoice_3', 'checked');

                            }
                        } else {

                            return;
                        }

                    });
                }
            }

            // Preencher o pop-up com valores existentes, se houver
            const { value: formValues } = await Swal.fire({
                title: t('main', 'agendarPost'),
                html: `
                  <style>
                    .swal2-popup .swal-custom-input, .swal2-popup .swal-custom-select {
                      width: 100%;
                      padding: 10px;
                      border-radius: 5px;
                      border: 1px solid #ccc;
                      margin-top: 10px;
                      font-size: 14px;
                    }
                    .swal2-popup .swal-custom-label {
                      font-weight: bold;
                      font-size: 14px;
                      margin-top: 10px;
                    }
                    .scrollable-container-pop-up {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      padding: 15px;
                    }
                  </style>
                  <div class="scrollable-container-pop-up">
                    <label for="scheduledTime" class="swal-custom-label">${t('main', 'dateHour')}</label>
                    <input type="datetime-local" id="scheduledTime" class="swal-custom-input" 
                           value="${existingSchedule ? moment(existingSchedule.localScheduledTime).format('YYYY-MM-DDTHH:mm') : ''}" />
                    
                    <label for="timezone" class="swal-custom-label">${t('main', 'dateFuso')}</label>
                    <select id="timezone" class="swal-custom-select">
                      <option value="America/Sao_Paulo" ${existingSchedule && existingSchedule.timezone === 'America/Sao_Paulo' ? 'selected' : ''}>${t('main', 'saoPaulo')}</option>
                      <option value="Europe/Lisbon" ${existingSchedule && existingSchedule.timezone === 'Europe/Lisbon' ? 'selected' : ''}>${t('main', 'lisboa')}</option>
                      <option value="Europe/Madrid" ${existingSchedule && existingSchedule.timezone === 'Europe/Madrid' ? 'selected' : ''}>${t('main', 'madrid')}</option>
                      <option value="Europe/Rome" ${existingSchedule && existingSchedule.timezone === 'Europe/Rome' ? 'selected' : ''}>${t('main', 'roma')}</option>
                    </select>
                  </div>
                `,
                focusConfirm: false,
                showCancelButton: true,
                cancelButtonText: t('main', 'cancelPostButton'),
                confirmButtonText: t('main', 'confirmPostButton'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                },
                preConfirm: () => {
                    const scheduledTime = document.getElementById('scheduledTime').value;
                    const timezone = document.getElementById('timezone').value;

                    console.log('Valor capturado de timezone:', timezone); // Log para verificação

                    if (!scheduledTime) {
                        Swal.showValidationMessage(t('main', 'pleaseDate'));
                        return false;
                    }

                    if (!timezone) {
                        Swal.showValidationMessage(t('main', 'pleaseFuso'));
                        return false;
                    }

                    return {
                        scheduledTime,
                        timezone
                    };
                }
            });

            if (formValues) {
                // Coletar valores do formulário
                const { scheduledTime, timezone } = formValues;

                const localMoment = moment.tz(scheduledTime, timezone);

                // Horário local (exatamente como o usuário inseriu)
                const localScheduledTime = localMoment.format('YYYY-MM-DDTHH:mm:ss');

                // Horário para o servidor (MongoDB converterá para UTC automaticamente)
                const serverScheduledTime = localMoment.toDate();

                // const localScheduledTime = moment(scheduledTime).format('YYYY-MM-DDTHH:mm:ss');
                //const serverScheduledTime = moment.tz(scheduledTime, timezone).format('YYYY-MM-DDTHH:mm:ss');

                // Se já existe um agendamento, enviar um PUT para atualizar
                if (existingSchedule) {
                    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/schedulePost`, {
                        userId,
                        totenId: totenIdToIndex(totenId),
                        presentationId,
                        localScheduledTime,
                        serverScheduledTime,
                        timezone,
                        status: 'agendado',
                        platform: 'Instagram'
                    });

                } else {
                    // Senão, criar um novo agendamento
                    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/schedulePost`, {
                        userId,
                        totenId: totenIdToIndex(totenId),
                        presentationId,
                        localScheduledTime,
                        serverScheduledTime,
                        timezone,
                        status: 'agendado',
                        platform: 'Instagram'
                    }).then(response => {
                        // Atualizar os créditos a partir da resposta do servidor
                        const newCredits = response.data.credits;
                        localStorage.setItem('credits', JSON.stringify(newCredits));

                    }).catch(error => {
                        console.error("Erro ao agendar o post:", error);
                    });
                }

                Swal.fire({
                    title: t('history', 'cancelScheduleSuccessTitle'),
                    text: t('main', 'pleaseSchedule'),
                    icon: 'success',
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: t('history', 'errorTitle'),
                text: t('main', 'notPost'),
                icon: 'error',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    }

    /* ## EDITAR NO CANVA (INÍCIO) ## */

    const handleEditChoice = () => {

        Swal.fire({
            title: t('main', 'editCarouselTitle'),
            text: t('main', 'editCarouselText'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('main', 'editCarouselConfirmButton'),
            cancelButtonText: t('main', 'editCarouselCancelButton'),
            reverseButtons: true,
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle',
                confirmButton: 'custom-confirm-button-class' // Classe customizada para o botão de confirmar
            },
            onOpen: () => {
                // Aqui adicionamos o ícone ao botão depois que o Swal é aberto
                const confirmButton = document.querySelector('.custom-confirm-button-class');
                if (confirmButton) {
                    confirmButton.innerHTML = `<img src="https://www.canva.dev/assets/connect/1.svg" alt="Canva Icon" style="width: 16px; height: 16px; vertical-align: middle; margin-right: 5px;"> ${confirmButton.textContent}`;
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                handleCanvasConnect();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                window.open("https://docs.google.com/presentation/d/" + carouselEdit, '_blank');
            }
        });

    };

    const handleCanvasConnect = async () => {

        toast.info(
            t('main', 'openingCanva'), {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 6000 // Defina um tempo para o toast se fechar automaticamente após alguns segundos
        });

        try {

            const presentationId = carouselEdit;

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/edit-canvas`, {
                userId,
                presentationId
            });

            const { success, message, canvasUrl, designId } = response.data;

            if (success) {

                setDesignId(designId);
                setCanvasUrl(canvasUrl);

                if (!isMobile) {

                    window.open(canvasUrl, '_blank');

                } else {

                    Swal.fire({
                        title: t('main', 'confirmCanvaConnectionTitle'),
                        text: t('main', 'confirmCanvaConnectionText'),
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: t('main', 'confirmCanvaConnectionConfirmButton'),
                        cancelButtonText: t('main', 'confirmCanvaConnectionCancelButton'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }

                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.open(canvasUrl, '_blank');
                        }
                    });

                }

            } else {

                throw new Error(message);
            }
        } catch (error) {
            console.error('Erro ao conectar com o Canva:', error);
            Swal.fire({
                title: t('main', 'canvaConnectionErrorTitle'),
                text: t('main', 'canvaConnectionErrorText'),
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    };

    async function validateCanvaToken(userId) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/validate-canva-token`, {
                userId
            });
            return response.data.success; // Retorna true se o token é válido, false se não é válido
        } catch (error) {
            console.error('Erro ao validar token do Canva:', error);
            return false; // Retorna false em caso de erro na requisição
        }
    }



    const [canProceed, setCanProceed] = useState(null);
    const [alertShown, setAlertShown] = useState(false);
    const isFirstRun = useRef(true);
    const isValidating = useRef(true);

    useEffect(() => {

        if (userId && !alertShown) {

            if (localStorage.getItem('firstAccess') === 'true') {
                setRunJoyride(true);
                localStorage.removeItem('firstAccess');
            }

            let sentence = '';  // Variável para armazenar a mensagem
            let instagramConnected = true;  // Variável para verificar conexão com Instagram
            let canvaConnected = true;  // Variável para verificar conexão com Canva

            // Verifica validade da conexão com Instagram
            const tokenExpiry = localStorage.getItem('tokenExpiry');

            if (!tokenExpiry || tokenExpiry === 'undefined' || tokenExpiry === 'null' || new Date(tokenExpiry) < new Date()) {
                instagramConnected = false;  // Instagram não está conectado
                setTokenExpiriedFace(true);
            } else {
                setTokenExpiriedFace(false);
            }


            if (isValidating.current) {

                isValidating.current = false;
                // Verifica validade da conexão com Canva
                //  validateCanvaToken(userId).then(isValid => {
                checkCanvaConnection().then(isValid => {
                    if (!isValid) {
                        canvaConnected = false;  // Canva não está conectado
                    }

                    // Define a mensagem dependendo das conexões
                    if (!instagramConnected && !canvaConnected) {
                        sentence = t('main', 'bothConnections'); // Texto se os dois não estiverem conectados
                    } else if (!instagramConnected) {
                        sentence = t('main', 'instagramConnection'); // Texto se apenas Instagram não está conectado
                    } else if (!canvaConnected) {
                        sentence = t('main', 'canvaConnection'); // Texto se apenas Canva não está conectado
                    }

                    // Se houver uma mensagem, exibe o toast
                    if (sentence) {
                        if (isFirstRun.current) {
                            const toastId = toast.info(sentence, {
                                position: toast.POSITION.BOTTOM_LEFT,
                                autoClose: 5000,
                                onClose: () => {

                                    setAlertShown(true);
                                }
                            });
                            isFirstRun.current = false;
                        }
                    }

                    setCanProceed(true);
                });
            }
        }

    }, [userId, alertShown]);



    /* ## EDITAR NO CANVAS (FIM) ## */

    const handleEdit = () => {
        const parts = carousels.split(/(?=Slide \d+:)/).map(part => {
            let slideNumber = part.match(/Slide (\d+):/);
            if (!slideNumber) return null; // Skip if no slide number is found

            let bodyText = part.replace(/Slide \d+:/, '').trim(); // Remove the "Slide X:" prefix
            if (bodyText.startsWith('Headline:')) {
                return { prefix: `Slide ${slideNumber[1]} (Headline):`, suffix: bodyText.replace('Headline:', '').trim() };
            } else if (bodyText.startsWith('Texto:')) {
                return { prefix: `Slide ${slideNumber[1]} (Texto):`, suffix: bodyText.replace('Texto:', '').trim() };
            }
            return { prefix: '', suffix: bodyText };
        }).filter(p => p); // Remove any null entries

        console.log('DESCOBRINDO O TEXTO QUE ESTÁ SENDO SALVO:');
        console.log(parts);

        setTextParts(parts);
        setIsEditing(true);
    };

    const handleSave = async () => {

        const combinedText = textParts.map((part, index) => {
            // Correção para verificar corretamente se é 'Headline' ou 'Texto' e manter a formatação adequada
            const isHeadline = part.prefix.includes('Headline');
            const slideType = isHeadline ? 'Headline:' : 'Texto:';
            return `Slide ${index + 1}:\n${slideType} ${part.suffix.trim()}\n\n`;
        }).join('');

        console.log('DESCOBRINDO O RESULTADO FINAL: ');
        console.log(combinedText);

        setCarousels(combinedText);
        setIsEditing(false);

        // Envio para o servidor
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/save-edited-carousel', {
                userId: userId, // supondo que você tenha o userId disponível no estado ou props
                userHistoryId: sessionId, // o ID da história do usuário que você está editando
                editedCarousel: combinedText
            });
            console.log('Resposta do servidor:', response.data);
        } catch (error) {
            console.error('Erro ao salvar o carrossel editado:', error);
        }
    };

    const handleTextChange = (index, newText) => {
        setTextParts(prevParts => prevParts.map((part, idx) => {
            if (idx === index) {
                return { ...part, suffix: newText };  // Manter o prefixo intacto e atualizar apenas o sufixo
            }
            return part;
        }));
    };

    const handleRadioChange = (value) => {
        setSelectedLayValue(value);
    };

    const handleSlideChange = (event) => {
        setSelectedSlides(event.target.value);
    };

    const [isGPT4Enabled, setIsGPT4Enabled] = useState(() => {
        const saved = localStorage.getItem('isGPT4Enabled');
        return saved === 'true';
    });

    //let fraseIndexCarousel = 0;

    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (event) => {
        const newIsGPT4Enabled = event.target.checked;
        setIsGPT4Enabled(newIsGPT4Enabled);
        localStorage.setItem('isGPT4Enabled', newIsGPT4Enabled.toString());
    };


    const [sliderStates, setSliderStates] = useState({
        slide1: {
            searchTerm: '',
            currentSearchTermIndex: 0,
            thumbnailImages: [],
            isLoading: false,
            userSearchTerm: '',
            searchTerms: [],
            searchTermsPort: [],
            text: '', // Campo adicionado para o texto
            loaded: true,
            selectedImage: null,
            selectedBankImage: null,
            selectedAIImage: null,
            selectedUploadImage: null,
            uploadedImageUrl: null,
            selectedAVG: null,
            flip: false,
            imageSourceType: 'ia',
            isProcessing: false,
            isUploading: false

        },
        slide2: {
            searchTerm: '',
            currentSearchTermIndex: 0,
            thumbnailImages: [],
            isLoading: false,
            userSearchTerm: '',
            searchTerms: [],
            searchTermsPort: [],
            text: '', // Campo adicionado para o texto
            loaded: false,
            isLoading: false,
            selectedImage: null,
            selectedBankImage: null,
            selectedAIImage: null,
            selectedUploadImage: null,
            uploadedImageUrl: null,
            selectedAVG: null,
            flip: false,
            imageSourceType: 'ia',
            isProcessing: false,
            isUploading: false
        },
        slide3: {
            searchTerm: '',
            currentSearchTermIndex: 0,
            thumbnailImages: [],
            isLoading: false,
            userSearchTerm: '',
            searchTerms: [],
            searchTermsPort: [],
            text: '', // Campo adicionado para o texto
            loaded: false,
            isLoading: false,
            selectedImage: null,
            uploadedImageUrl: null,
            selectedAVG: null,
            selectedBankImage: null,
            selectedAIImage: null,
            selectedUploadImage: null,
            flip: false,
            imageSourceType: 'ia',
            isProcessing: false,
            isUploading: false
        },
        slide4: {
            searchTerm: '',
            currentSearchTermIndex: 0,
            thumbnailImages: [],
            isLoading: false,
            userSearchTerm: '',
            searchTerms: [],
            searchTermsPort: [],
            text: '', // Campo adicionado para o texto
            loaded: false,
            isLoading: false,
            selectedImage: null,
            selectedBankImage: null,
            selectedAIImage: null,
            selectedUploadImage: null,
            uploadedImageUrl: null,
            selectedAVG: null,
            flip: false,
            imageSourceType: 'ia',
            isProcessing: false,
            isUploading: false
        },
        slide5: {
            searchTerm: '',
            currentSearchTermIndex: 0,
            thumbnailImages: [],
            isLoading: false,
            userSearchTerm: '',
            searchTerms: [],
            searchTermsPort: [],
            text: '', // Campo adicionado para o texto
            loaded: false,
            isLoading: false,
            selectedImage: null,
            selectedBankImage: null,
            selectedAIImage: null,
            selectedUploadImage: null,
            uploadedImageUrl: null,
            selectedAVG: null,
            flip: false,
            imageSourceType: 'ia',
            isProcessing: false,
            isUploading: false
        },
        slide6: {
            searchTerm: '',
            currentSearchTermIndex: 0,
            thumbnailImages: [],
            isLoading: false,
            userSearchTerm: '',
            searchTerms: [],
            searchTermsPort: [],
            text: '', // Campo adicionado para o texto
            loaded: false,
            isLoading: false,
            selectedImage: null,
            selectedBankImage: null,
            selectedAIImage: null,
            selectedUploadImage: null,
            uploadedImageUrl: null,
            selectedAVG: null,
            flip: false,
            imageSourceType: 'ia',
            isProcessing: false,
            isUploading: false
        },
        slide7: {
            searchTerm: '',
            currentSearchTermIndex: 0,
            thumbnailImages: [],
            isLoading: false,
            userSearchTerm: '',
            searchTerms: [],
            searchTermsPort: [],
            text: '', // Campo adicionado para o texto
            loaded: false,
            isLoading: false,
            selectedImage: null,
            selectedBankImage: null,
            selectedAIImage: null,
            selectedUploadImage: null,
            uploadedImageUrl: null,
            selectedAVG: null,
            flip: false,
            imageSourceType: 'ia',
            isProcessing: false,
            isUploading: false
        },
        slide8: {
            searchTerm: '',
            currentSearchTermIndex: 0,
            thumbnailImages: [],
            isLoading: false,
            userSearchTerm: '',
            searchTerms: [],
            searchTermsPort: [],
            text: '', // Campo adicionado para o texto
            loaded: false,
            isLoading: false,
            selectedImage: null,
            selectedBankImage: null,
            selectedAIImage: null,
            selectedUploadImage: null,
            uploadedImageUrl: null,
            selectedAVG: null,
            flip: false,
            imageSourceType: 'ia',
            isProcessing: false,
            isUploading: false
        },
        slide9: {
            searchTerm: '',
            currentSearchTermIndex: 0,
            thumbnailImages: [],
            isLoading: false,
            userSearchTerm: '',
            searchTerms: [],
            searchTermsPort: [],
            text: '', // Campo adicionado para o texto
            loaded: false,
            isLoading: false,
            selectedImage: null,
            selectedBankImage: null,
            selectedAIImage: null,
            selectedUploadImage: null,
            uploadedImageUrl: null,
            selectedAVG: null,
            flip: false,
            imageSourceType: 'ia',
            isProcessing: false,
            isUploading: false
        },
        slide10: {
            searchTerm: '',
            currentSearchTermIndex: 0,
            thumbnailImages: [],
            isLoading: false,
            userSearchTerm: '',
            searchTerms: [],
            searchTermsPort: [],
            text: '', // Campo adicionado para o texto
            loaded: false,
            isLoading: false,
            selectedImage: null,
            selectedBankImage: null,
            selectedAIImage: null,
            selectedUploadImage: null,
            uploadedImageUrl: null,
            selectedAVG: null,
            flip: false,
            imageSourceType: 'ia',
            isProcessing: false,
            isUploading: false
        },
        // Adicione mais slides conforme necessário
    });

    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'language') {
                setLanguage(localStorage.getItem('language') || 'pt');
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const [fraseCarousel, setFraseCarousel] = useState(t('main', 'loadingText'));

    useEffect(() => {
        if (!isLoadingCarousel) {
            setFraseCarousel(isStories ? t('main', 'loadingTextStories') : t('main', 'loadingText'));
        }

    }, [language, t]);  // Dependências: `language` e `t`

    const frasesCarousel = [t('main', 'loadingText'), isStories ? t('main', 'loadingTextStories') : t('main', 'loadingText')];


    useEffect(() => {

        let totenId;  // titleFontChanged, textFontChanged;

        try {

            totenId = localStorage.getItem('totenId');
            setIsTotenId(totenId);

        } catch (error) {
            // Se ocorrer um erro no parsing, define colorsFromStorage como null
            console.error('Erro ao parsear cores do localStorage:', error);
        }

        if (totenId !== 'undefined') {

            fetchPCI(totenId);

        }

    }, []);


    const fetchPCI = async (totenId) => {

        try {

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/pciControl/summary`, {
                userId,
                totenId
            });

            let summary = response.data.summary.replace(/\n\n/g, '   ').replace(/\n/g, '   ');

            if (summary) {

                setSummary(summary);
                setIsCheckboxDisabledPCI(false);

            } else {

                setIsCheckboxDisabledPCI(true);

            }

        } catch (error) {

            console.log('Esse é o erro de tentar pegar o resumo ' + error.message);

            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: t('main', 'serverError'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    }



    const handleHamburguerClick = async () => {


        setIsOpen(true); // Abre o Drawer

    };

    const [runJoyride, setRunJoyride] = useState(false);

    const [joyrideSteps, setJoyrideSteps] = useState([
        {
            target: '.tabsListEnter',
            content: t('main', 'joyrideSteps')[0].content,
        },
        {
            target: '.default',
            content: t('main', 'joyrideSteps')[1].content,
        },
        {
            target: '.customText',
            content: t('main', 'joyrideSteps')[2].content,
        },
        {
            target: '.checkBoxEnter',
            content: t('main', 'joyrideSteps')[3].content,
        },
        {
            target: '.slcSlides',
            content: t('main', 'joyrideSteps')[4].content,
        },
        {
            target: '.public',
            content: t('main', 'joyrideSteps')[5].content,
        },
        {
            target: '.topic',
            content: t('main', 'joyrideSteps')[6].content,
        },
        {
            target: '.createBtn',
            content: t('main', 'joyrideSteps')[7].content,
        }
    ]);



    const handleCloseDrawer = () => {
        setIsOpen(false); // Fecha o Drawer
    };


    const isValidUrl = (url) => {
        return url && url !== "undefined"; // ou qualquer outra lógica de validação que você queira.
    };

    const [inputType, setInputType] = useState('default'); //
    const [inputTypeTwo, setInputTypeTwo] = useState('youtube');



    async function thisIsCarousel(swiperRef) {

        let interval; // Mova a declaração para aqui.

        const checkSwiperRef = () => {

            if (swiperRef) {


                fetchUncompletedSession();
                clearInterval(interval);

            }

        };

        const isUserAuthenticated = () => {
            return localStorage.getItem('userId') !== null;
        };

        if (!isUserAuthenticated()) {
            navigate('/login');
        } else {
            // Inicie o intervalo para verificar a existência de swiperRef
            interval = setInterval(checkSwiperRef, 1000);
        }

        // Certifique-se de limpar o intervalo quando o componente for desmontado
        return () => {
            clearInterval(interval);
        };

    }

    useEffect(() => {
        if (swiperRef && canProceed) { // Certifique-se de adicionar a condição canProceed para evitar execução prematura
            thisIsCarousel(swiperRef);
        }
    }, [swiperRef, canProceed]);


    async function startNewCarouselSession() {

        const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/users/history', { userId: userId });

        const newSessionId = response.data.savedUserHistory;
        setSessionId(newSessionId);
        // Agora use newSessionId para qualquer lógica subsequente que dependa deste valor
        return newSessionId;
    };

    useEffect(() => {


        if (topic && targetAudience && sessionId && selectedOutcome && resumingSession && !selectedHeadline) {

            handleSubmit(selectedOutcome);

        }


    }, [topic, targetAudience, sessionId, selectedOutcome, resumingSession]);


    useEffect(() => {


        if (topic && targetAudience && sessionId && selectedHeadline && resumingSession) {

            handleTonePress('', '');

        }


    }, [topic, targetAudience, sessionId, selectedHeadline, resumingSession]);


    useEffect(() => {


        if (sessionId && inputText && resumingSession) {

            handleTonePress('', '');

        }


    }, [sessionId, inputText, resumingSession]);


    const fetchUncompletedSession = async () => {
        try {

            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/users/uncompleted-session', { userId: userId });
            let session;


            if (response.data && response.data.session.outcome) {


                session = response.data.session;

                // Exiba um popup usando Swal
                const result = await Swal.fire({
                    title: t('main', 'pendingCarousel'),
                    text: t('main', 'continuePending'),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: t('main', 'cancel'),
                    confirmButtonText: t('main', 'continue'),
                    customClass: {
                        popup: 'my-swal-special',
                        title: 'myCustomTitle'
                    }
                });

                if (result.isConfirmed) {

                    setSelectedSlides(session.numberSlides);
                    setSessionId(session._id);
                    setTopic(session.topic);
                    setTargetAudience(session.targetAudience);

                    if (session.outcome) {
                        setSelectedOutcome(session.outcome);
                        setResumingSession(true);
                    }

                    if (session.headline) {

                        setSelectedHeadline(session.headline);

                    }

                    if (session.carousel) {
                        if (session.editedCarousel) {

                            setSelectedCarousel(session.editedCarousel);

                        } else {

                            setSelectedCarousel(session.carousel);

                        }
                    }
                }
            }

            if (response.data && (response.data.session.inputType === "customOwnText" || response.data.session.inputType === "carouselFromTemplate" || response.data.session.inputType === "stories" || response.data.session.inputType === "fromUrlYoutube")) {



                session = response.data.session;

                // Exiba um popup usando Swal
                const result = await Swal.fire({
                    title: session.inputType === 'stories' ? t('main', 'pendingStories') : t('main', 'pendingCarousel'),
                    text: session.inputType === 'stories' ? t('main', 'continuePendingStories') : t('main', 'continuePending'),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: t('main', 'cancel'),
                    confirmButtonText: t('main', 'continue'),
                    customClass: {
                        popup: 'my-swal-special',
                        title: 'myCustomTitle'
                    }
                });

                if (result.isConfirmed) {

                    setSessionId(session._id);
                    setSelectedSlides(session.numberSlides);

                    // if (session.carousel) {

                    if (session.inputType === "customOwnText" || session.inputType === "fromUrlYoutube") {
                        setInputTitle(session.titleBase);

                    }

                    setInputText(session.textBase)

                    if (session.editedCarousel) {

                        setSelectedCarousel(session.editedCarousel);

                    } else {

                        setSelectedCarousel(session.carousel);

                    }

                    setResumingSession(true);

                    if (session.inputType === 'stories') {

                        setIsStories(true);
                        setSelectedSlidesStories(session.numberSlides);
                        setSelectedFormat('stories_origin');
                        setSelectedLayValue('vert');

                    }
                    // }
                }
            }



        } catch (error) {
            console.error(error);
        }
    };

    async function handleHeadlineSelect(index) {
        setSelectedHeadline(headlines[index]);

        if (!hasSelectedHeadlineBefore) {
            // setIsLoadingTones(true);
            setHasSelectedHeadlineBefore(true);  // Atualize o estado para indicar que a função já foi chamada.
        }
    }


    async function modelSelect() {

        let userHandle = localStorage.getItem('userHandle');

        if (!userHandle) {

            var span = document.createElement("span");
            span.innerHTML = `<input type="text" name="instagramUsernameInput" placeholder="${t('main', 'placeholderInstagramUsername')}" class="swal-input" >`;

            const result = await Swal.fire({
                title: t('main', 'insertInstagramUsername'),
                html: span.outerHTML,
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                customClass: {
                    popup: 'my-swal-special',
                    title: 'myCustomTitle'
                },
                showCancelButton: true,
                confirmButtonText: t('main', 'save'),
                cancelButtonText: t('main', 'cancel'),
            });

            if (result.isConfirmed) {
                const instagramUsername = document.querySelector('.swal-input').value;

                if (instagramUsername) {
                    localStorage.setItem('userHandle', instagramUsername);
                    setIsntaId(instagramUsername);
                } else {
                    setIsntaId('');
                }
            }
        } else {
            setIsntaId(userHandle);
        }

        handleAdvanceButtonClick();


    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }



    async function storiesSubmit(type) {

        setIsFetchingTemplates(true);
        setIsLoadingOutcomes(true);
        setIsSubmitted(true);

        if (type === 'stories') {

            setSelectedLayValue('vert');
            setSelectedFormat('stories_origin');
            setIsStories(true);

        } else {

            setSelectedFormat('twitter');
            setSelectedLayValue('sqr');
            setIsStories(false);

        }

        if (swiperRef) {

            setTimeout(() => {
                swiperRef.slideTo(1, 1000);
            }, 100);

        }

        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/fetch-templates', {
                language: language
            });

            const templates = response.data.templates;

            setOutcomes(templates);

            setTimeout(() => {
                setIsLoadingOutcomes(false);
                setIsFetchingTemplates(false);
            }, 4000);

        } catch (error) {
            console.error("Erro ao buscar templates:", error);
            setTimeout(() => {
                setIsLoadingOutcomes(false);
                setIsFetchingTemplates(false);
            }, 4000);

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.error || t('main', 'unknownErrorForTitles'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });
        }
    }


    async function handleSubmit(outcome) {

        if (outcome) {
            setIsLoading(true);
        } else {
            setIsLoadingOutcomes(true);
        }
        setIsSubmitted(true);
        //let session;

        if (!selectedSlides) {

            setSelectedSlides('10');

        }

        if (!sessionId) {
            try {
                await startNewCarouselSession();
            } catch (error) {
                console.error("Erro ao iniciar nova sessão:", error);
            }
        }

        if (swiperRef) {

            setTimeout(() => {
                swiperRef.slideTo(1, 1000);
            }, 100);

        }

        let attempt = 0;
        const maxAttempts = 3;
        const delayBetweenAttempts = 105000;


        const interval = setInterval(() => {
            attempt++;
            if (attempt < maxAttempts) {
                toast.warn(t('main', 'attemptFailed').replace('{attempt}', attempt), {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            } else {
                clearInterval(interval);
            }
        }, delayBetweenAttempts);


        if (!outcome) {

            axios.post(process.env.REACT_APP_API_BASE_URL + '/api/generate-outcomes', {
                audience: targetAudience,
                topic: topic,
                userId: userId,
                gpt4: true,
                language: language
            })
                .then(response => {
                    //const outcomes = response.data.outcomes.choices.map(choice => choice.message.content).join('\n').split('\n').filter(line => line.trim() !== '');
                    clearInterval(interval);
                    const newCredits = response.data.credits;
                    localStorage.setItem('credits', JSON.stringify(newCredits));

                    const outcomes = response.data.outcomes.choices.map(choice => choice.message.content).join('\n').split('\n').filter(line => line.trim() !== '').map(line => line.replace('- Para ', '')).map(capitalizeFirstLetter);
                    setOutcomes(outcomes);
                    setIsLoadingOutcomes(false);
                    //  swiperRef.slideTo(/* índice do slide de seleção de outcomes */, 1000);

                    const creditAlert = response.data.creditAlert;
                    if (creditAlert && creditAlert.shouldAlert) {
                        displayCreditAlert(creditAlert.creditsRemaining);
                    }
                })
                .catch(error => {
                    clearInterval(interval);
                    console.error("Erro ao buscar outcomes:", error);
                    setIsLoadingOutcomes(false);

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error.response.data.error || t('main', 'unknownErrorForTitles'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });

                    // Tratar erro com mensagem apropriada
                })
                .finally(() => {
                    clearInterval(interval);
                    setIsLoadingOutcomes(false);
                });

        } else {

            setTimeout(() => {
                swiperRef.slideTo(2, 1000);
            }, 100);

            axios.post(process.env.REACT_APP_API_BASE_URL + '/api/endpoint', {
                audience: targetAudience,
                topic: topic,
                userId: userId,
                savedUserHistoryId: sessionId, //|| session,
                catAnxiety: catAnxiety,
                catHeadline: catHeadline,
                gpt4: true,
                outcome: outcome,
                language: language,
                selectedSlides: selectedSlides,
                totenId: isTotenId
            })
                .then(response => {

                    clearInterval(interval);

                    let lines;
                    let headline;
                    let cleanedHeadlines;


                    if (response.data.claude) {

                        if (response.data.responseApi.content && response.data.responseApi.content.length > 0) {

                            const text = response.data.responseApi.content[0].text;
                            lines = text.split('\n').filter(line => line.trim() !== '');

                        }

                    } else {

                        lines = response.data.responseApi.choices.map(choice => choice.message.content).join('\n').split('\n').filter(line => line.trim() !== '');
                    }

                    if (lines.length > 10) {
                        headline = lines.slice(-10);
                    } else {
                        headline = lines;
                    }

                    cleanedHeadlines = headline.map(line => cleanHeadline(line));

                    if (response.data.updatedUserHistory.profileId) {

                        setProfileId(response.data.updatedUserHistory.profileId);

                    }

                    setHeadlines(cleanedHeadlines);
                    setIsLoading(false);
                })

                .catch(error => {

                    clearInterval(interval);

                    setIsLoading(false);

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: t('main', 'unknownErrorForTitles'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });
                });
        }
    }


    /* async function scrapeInstagramData(session) {
 
         // setIsLoading(true);
 
         setIsLoadingCarousel(true);
 
         if (swiperRef) {
 
             setTimeout(() => {
 
                 swiperRef.slideTo(1, 1000);
 
             }, 360);  // Ajuste este valor conforme necessário
         }
 
 
         try {
 
             const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/analysis/scrapeInstagram', {
                 method: 'POST',
                 headers: {
                     'Content-Type': 'application/json',
                 },
                 body: JSON.stringify({
                     userId: userId,
                     value: instagramUrl,
                     gpt4: isGPT4Enabled,
                     audience: targetAudience
                 }),
             });
 
             if (!response.ok) {
                 throw new Error(await response.text());
             }
 
             const data = await response.json();
 
             console.log("Slides Vision:")
             console.log(data);
 
 
 
             handleTonePress(session, data);
 
 
         } catch (error) {
 
             console.error('There was an error!', error);
 
             if (error.message.includes("Vídeos não são suportados no momento.")) {
 
                 Swal.fire({
                     icon: 'warning',
                     title: t('main', 'attention'),
                     text: t('main', 'videosNotSupported'),
                     customClass: {
                         popup: 'my-swal-alert',
                         title: 'myCustomTitle'
                     }
                 });
             } else {
                 Swal.fire({
                     icon: 'error',
                     title: 'Erro',
                     text: t('main', 'serverError'),
                     customClass: {
                         popup: 'my-swal-alert',
                         title: 'myCustomTitle'
                     }
                 });
             }
             setIsLoadingCarousel(false);
         }
 
     };  */



    function cleanHeadline(line) {
        //return line.replace(/^[0-9]+[.)-]*\s*|- /, '');
        return line.replace(/^Headline \d+:\s*/, '');
    }



    useEffect(() => {
        if (selectedHeadline && swiperRef && !resumingSession) {

            confirmSubmit('#', '2');
            // swiperRef.slideTo(headlines.length + 3, 1000); 
        }
    }, [selectedHeadline, swiperRef, headlines.length]);








    let currentRequestId = null;

    function handleTonePress(session, templateEdited, itemId = '#') {


        let flagInsta = false;
        let title = inputTitle;
        let text = inputText;
        let captionYoutube = '';


        setIsLoadingCarousel(true);
        // setIsToneSelected(true);

        // Gere um novo token para esta requisição
        const requestId = Math.random().toString(36).substring(2);
        currentRequestId = requestId;

        if (!resumingSession) {

            if (swiperRef) {
                setTimeout(() => {
                    swiperRef.slideTo(headlines.length + 3, 1000);
                }, 100);
            }

        } else {

            if (swiperRef) {

                if (!selectedOutcome) {

                    setTimeout(() => {

                        swiperRef.slideTo(1, 1000);

                    }, 360);  // Ajuste este valor conforme necessário
                } else {
                    setTimeout(() => {

                        swiperRef.slideTo(11, 1000);

                    }, 360);  // Ajuste este valor conforme necessário
                }
            }

        }

        if (!selectedCarousel) {
            console.log('Passa pelo 1');

            let attempt = 0;
            let maxAttempts;
            let delayBetweenAttempts;

            if (!youtubeUrl) {

                maxAttempts = 3;
                delayBetweenAttempts = 60000;

            } else {

                maxAttempts = 2;
                delayBetweenAttempts = 180000;

            }


            const interval = setInterval(() => {
                attempt++;
                if (attempt < maxAttempts) {
                    toast.warn(t('main', 'attemptFailed').replace('{attempt}', attempt), {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                } else {
                    clearInterval(interval);
                }
            }, delayBetweenAttempts);



            let timeoutId = setTimeout(async () => {
                // Se o timeout for atingido, mover os slides de volta para a posição original
                if (await handleTimeout()) {
                    handleTonePress('', '');
                } else {
                    window.location.reload();
                }

            }, 210000);


            function extractSlides(text) {
                // Remove o thread_planning se existir
                const cleanText = text.replace(/<thread_planning>[\s\S]*?<\/thread_planning>\s*/g, '');

                // Extrai apenas os tweets numerados
                const tweetRegex = /Tweet \d+[:.;,\-()]\s+(.*?)(?=(?:\n*Tweet \d+[:.;,\-()]\s+)|$)/gs;
                const matches = [...cleanText.matchAll(tweetRegex)];

                // Retorna apenas o conteúdo dos tweets
                return matches.map(match => match[1].trim()).filter(Boolean);
            }


            axios.post(process.env.REACT_APP_API_BASE_URL + '/api/carousel-endpoint', {

                audience: targetAudience,
                topic: topic,
                headline: selectedHeadline == null ? ' ' : selectedHeadline,
                userId: userId,
                totenId: isTotenId,
                savedUserHistoryId: sessionId || session,
                catCarousel: catCarousel,
                requestId: requestId,
                gpt4: true, //isGPT4Enabled
                title: title,
                text: inputTypeTwo === 'customText' ? text : "",
                youtubeUrl: inputTypeTwo === 'youtube' ? youtubeUrl : "",
                characteristic: localStorage.getItem('characteristic'),
                language: language,
                selectedSlides: isStories
                    ? (selectedSlidesStories !== "" ? selectedSlidesStories : (setSelectedSlidesStories('5'), '5'))
                    : (selectedSlides !== "" ? selectedSlides : (setSelectedSlides('10'), '10')),
                templateId: itemId,
                profileId: profileId,
                stories: isStories,
                templateEdited: templateEdited,
                isCheckedPart: isCheckedPart


            })

                .then(response => {
                    console.log(response);
                    if (response.data.requestId !== currentRequestId) {
                        refundCredits();  // Chama a função de reembolso se a resposta é de uma requisição antiga
                        return;
                    }

                    clearInterval(interval);
                    clearTimeout(timeoutId);
                    setTotalTokens(response.data.totalTokens);
                    setTimeApi(response.data.totalTime);

                    let slideStrings;

                    if (response.data.claudeAPI) {

                        slideStrings = extractSlides(response.data.responseApi.content[0].text);

                    } else {

                        slideStrings = extractSlides(response.data.responseApi.choices[0].message.content);  //.split(/Tweet \d+[:.;,\-()]\s+/).filter(slide => slide.trim());

                    }

                    console.log(slideStrings);

                    let slides = slideStrings.map((slideString, index) => {
                        let headline = '';
                        let copy = '';

                        if (index === 0) {
                            // O primeiro item é a headline
                            headline = slideString.trim();
                        } else {
                            // Os itens subsequentes são o corpo do texto
                            copy = slideString.trim();
                        }

                        return {
                            slide: index + 1,
                            headline: headline,
                            copy: copy,
                            image: '' // Deixei o campo de imagem vazio, já que não parece estar incluído no novo formato
                        };
                    });

                    let formattedCarousel = slides.reduce((accumulator, slide) => {
                        if (slide.headline) {
                            return accumulator + `Slide ${slide.slide}:\nHeadline: ${slide.headline}\n\n`;
                        } else {
                            return accumulator + `Slide ${slide.slide}:\nTexto: ${slide.copy}\n\n`;
                        }
                    }, "");

                    const newCredits = response.data.credits;
                    localStorage.setItem('credits', JSON.stringify(newCredits));

                    setCarousels(formattedCarousel);
                    if (sessionId) {
                        updateSessionWithCarousel(sessionId, formattedCarousel);
                    } else {
                        updateSessionWithCarousel(session, formattedCarousel);
                    }

                    const creditAlert = response.data.creditAlert;
                    if (creditAlert && creditAlert.shouldAlert) {
                        displayCreditAlert(creditAlert.creditsRemaining);
                    }

                })

                .catch(error => {

                    if (error.response && error.response.status === 408) {

                        console.log('Erro 408: A operação demorou muito.');
                        return;

                    } else {

                        clearInterval(interval);
                        clearTimeout(timeoutId);
                        console.error(error);


                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.response.data.error || t('main', 'unknownErrorForCarouselText'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            }
                        });

                        setIsLoadingCarousel(false);
                    }
                });
        } else {

            setCarousels(selectedCarousel);
            //   setIsLoadingCarousel(false);
        }

    }

    useEffect(() => {
        // Esta função é chamada quando o estado 'carousels' é atualizado
        setIsLoadingCarousel(false);
    }, [carousels]);

    const updateSessionWithCarousel = (sessionId, carouselData) => {
        axios.put(process.env.REACT_APP_API_BASE_URL + '/users/update-carousel', {
            sessionId: sessionId,
            carousel: carouselData
        })
            .then(response => {
                console.log("Carousel data saved successfully");
            })
            .catch(error => {
                console.error("Error saving carousel data:", error);
            });
    }



    async function handleTimeout() {
        const result = await Swal.fire({
            icon: 'info',
            title: t('main', 'timeExceeded'),
            text: t('main', 'infoProcessDelay'),
            customClass: {
                popup: 'my-swal',
                title: 'myCustomTitle'
            },
            showCancelButton: true,
            confirmButtonText: t('main', 'tryAgain'),
            cancelButtonText: t('main', 'returnToStart')
        });

        if (result.isConfirmed) {

            console.log('Usuário escolheu tentar novamente.');

            return true;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            // O usuário escolheu retornar ao início
            console.log('Usuário escolheu retornar ao início.');

            // Chame o endpoint do servidor para estornar os créditos
            await refundCredits();

            // Recarregar a página para retornar ao início
            return false;
        }
    }

    async function refundCredits() {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + '/api/refund',
                {
                    userId: userId,
                    gpt4: true //isGPT4Enabled
                }
            );

            if (response.status !== 200) {
                throw new Error('Failed to refund credits: ' + response.statusText);
            }

            console.log('Credits refunded successfully');

        } catch (error) {
            console.error(error);
        }
    }


    async function checkSlides(presentationId) {


        toast.info(
            t('main', 'searchingForUpdates'),
            {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000
            }
        );



        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/checkSlides', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ presentationId }), // Enviando o presentationId para a API
            gpt4: isGPT4Enabled
        });

        if (!response.ok) {
            let errMsg = t('main', 'errorUpdatingSlides');  // mensagem padrão

            try {
                const errorData = await response.json();
                if (errorData && errorData.message) {
                    errMsg = errorData.message;
                }
            } catch (e) {
                // não faça nada, mantenha a mensagem padrão
            }

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errMsg,
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });

            throw new Error(errMsg);
        }

        return await response.json();  // Isso retornará { isModified: true/false }
    }

    const updateSlides = () => {

        try {

            setIsLoadingImage(true); // Defina o carregamento para true no início da atualização.

            if (designId) {
                setLoadingMessage(isStories ? t('main', 'processingChanges') : t('main', 'processingChanges'));
                // Se designId estiver presente, chamar a rota de exportação do Canva
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/export-design`, {
                    userId,
                    designId,
                    presentationId: carouselEdit
                })
                    .then(response => {

                        const fullImageUrls = response.data.imageUrls;
                        const updatedImageUrlsWithTimestamp = fullImageUrls.map(url => `${url}?timestamp=${new Date().getTime()}`);
                        const success = response.data.success;
                        const message = response.data.message;

                        if (success) {
                            setImages(updatedImageUrlsWithTimestamp);
                            setIsLoadingImage(false);
                            Swal.fire({
                                icon: 'success',
                                title: t('main', 'success'),
                                text: t('main', 'successUpdatingSlides'),
                                confirmButtonText: 'Ok',
                                customClass: {
                                    popup: 'my-swal-alert',
                                    title: 'myCustomTitle'
                                }
                            });
                        } else {
                            throw new Error(message);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        setIsLoadingImage(false); // Defina o carregamento para false se houver um erro.

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: t('main', 'errorUpdatingSlides'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            }
                        });
                    });

            } else {

                setLoadingMessage(isStories ? t('main', 'processingChangesGoogle') : t('main', 'processingChangesGoogle'));

                axios.post(process.env.REACT_APP_API_BASE_URL + '/api/updateSlides', {
                    userId: userId,
                    presentationId: carouselEdit //,
                    // gpt4: isGPT4Enabled
                })

                    .then(response => {

                        const fullImageUrls = response.data.imageUrls;

                        // Se você não precisa atualizar presentationId, pode comentar a próxima linha.
                        //const updatedPresentationId = response.data.presentationId; 
                        // const newCredits = response.data.credits;
                        // localStorage.setItem('credits', JSON.stringify(newCredits));
                        // Atualize o estado das imagens e da ID da apresentação.

                        setImages(fullImageUrls);
                        setIsLoadingImage(false);

                        /*  const creditAlert = response.data.creditAlert;
                          if (creditAlert && creditAlert.shouldAlert) {
                              displayCreditAlert(creditAlert.creditsRemaining);
                          } 
                         */

                    })
                    .catch(error => {
                        console.error(error);
                        setIsLoadingImage(false); // Defina o carregamento para false se houver um erro.

                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.response?.data?.message || t('main', 'errorUpdatingSlides'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            }
                        });
                    });
            }
        }
        catch (error) {
            console.error('Erro ao atualizar slides:', error);
            setIsLoadingImage(false); // Defina o carregamento para false se houver um erro.

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response?.data?.message || t('main', 'errorUpdatingSlides'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });

        }
    }


    async function askUserToFlipImage(capa) {
        const result = await Swal.fire({
            title: t('main', 'mirrorEffect'),
            text: t('main', 'infoCarouselTextPosition'),
            imageUrl: capa,
            imageAlt: t('main', 'exampleImage'),
            customClass: {
                popup: 'my-swal',
                title: 'myCustomTitle'
            },
            showCancelButton: true,
            cancelButtonText: t('main', 'invert'),
            confirmButtonText: t('main', 'leaveAsIs')
        });

        if (!result.isConfirmed) {
            // Aqui, você chama a função para flipar a imagem
            console.log('Usuário escolheu flipar a imagem.');
            return true;
        } else {  //if (result.dismiss === Swal.DismissReason.cancel) {
            // Aqui, você prossegue sem flipar a imagem
            console.log('Usuário escolheu não flipar a imagem.');
            return false;
        }
    }

    async function carrosselDownload(presentationId) {
        toast.info(
            t('main', 'imagesPreparing'),
            {
                type: toast.TYPE.SUCCESS,
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 5000
            }
        );

        try {
            // Fazemos a chamada para gerar e baixar o ZIP.
            const downloadResponse = await axios({
                url: process.env.REACT_APP_API_BASE_URL + `/api/generate-zip`,
                method: 'POST',
                responseType: 'blob',
                data: { presentationId: presentationId }
            });

            const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${presentationId}.zip`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error("Erro ao baixar o arquivo ZIP:", error);
        }
    }


    useEffect(() => {

    }, [images]);


    /*  const selectCoverSource = async () => {
          const inputOptions = {
              //'IA':'I.A.',
              'Pexels': 'Banco de Imagens',
              'Upload': 'Imagem Própria'
          };
  
          // Adiciona o estilo para centralizar o texto das opções
          const styleElement = document.createElement('style');
          styleElement.innerHTML = `
              .swal2-radio label {
                  display: flex;
                  align-items: center;
                  justify-content: center;
              }
          `;
          document.head.appendChild(styleElement);
  
          const { value: source } = await Swal.fire({
              title: t('main', 'selectImagesForPost'),
              input: 'radio',
              inputOptions: inputOptions,
              customClass: {
                  popup: 'my-swal-alert',
                  title: 'myCustomTitle'
              },
              inputValidator: (value) => {
                  if (!value) {
                      return t('main', 'mustChooseOption');
                  }
              }
          });
  
          // Se desejar, você pode remover o estilo depois de usar
          document.head.removeChild(styleElement);
  
          return source;
      } */


    function confirmSubmit(choise, number) {

        const calculateRequiredSlides = (text) => {
            const avgCharsPerSlide = 300;
            const textLength = text.length;
            return Math.ceil(textLength / avgCharsPerSlide);
        };


        let userChoice = localStorage.getItem('popupChoice');


        if (choise === 'customText' && isCheckedPart) {


            const requiredSlides = calculateRequiredSlides(inputText);
            const currentSelectedSlides = selectedSlides ? parseInt(selectedSlides) : 10;

            if (requiredSlides > currentSelectedSlides) {
                let message = t('main', 'textTooLongForSlides')
                    .replace('[required]', requiredSlides)
                    .replace('[selected]', currentSelectedSlides);

                Swal.fire({
                    icon: 'warning',
                    title: t('main', 'attention'),
                    text: message,
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
                return; // Impede a continuação se o texto for muito longo
            }
        }

        // Alteramos a verificação aqui
        if (userChoice === 'checked') {

            if (number === '1') {
                if (choise === 'default') {

                    if (!targetAudience || !topic) {
                        Swal.fire({
                            icon: 'info',
                            title: t('main', 'attention'),
                            text: t('main', 'specifyAudienceAndTopic'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            },
                        })
                        return;
                    } else {

                        setInputText('');
                        setInputTitle('');
                        setYoutubeUrl('');

                        handleSubmit('');

                    }
                }

                if (choise === 'fromTemplate') {

                    if (!targetAudience) {
                        Swal.fire({
                            icon: 'info',
                            title: t('main', 'attention'),
                            text: t('main', 'specifyAudience'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            },
                        })
                        return;
                    } else {

                        setYoutubeUrl('');
                        setInputText('');
                        setInputTitle('');

                        setTamplateId('');
                        storiesSubmit('template');


                    }

                }

                if (choise === 'customText') {

                    if (!inputText || !inputTitle) {
                        Swal.fire({
                            icon: 'info',
                            title: t('main', 'attention'),
                            text: t('main', 'specifyTitleAndText'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            },
                        })
                        return;
                    } else {

                        setYoutubeUrl('');
                        startNewCarouselSession().then((newSessionId) => {
                            // console.log('PASSOU AQUI? SESSION ID: ' + newSessionId);
                            handleTonePress(newSessionId, ''); // Passe newSessionId como um argumento
                        });
                    }
                }


                if (choise === 'youtube') {

                    if (!youtubeUrl || !inputTitle) {
                        Swal.fire({
                            icon: 'info',
                            title: t('main', 'attention'),
                            text: t('main', 'specifyAudienceAndLink'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            },
                        })
                        return;

                    } else {

                        setInputText('');
                        startNewCarouselSession().then((newSessionId) => {
                            // console.log('PASSOU AQUI? SESSION ID: ' + newSessionId);
                            handleTonePress(newSessionId, ''); // Passe newSessionId como um argumento
                        });
                    }
                }


                if (choise === 'stories') {

                    if (!targetAudience) {
                        Swal.fire({
                            icon: 'info',
                            title: t('main', 'attention'),
                            text: t('main', 'specifyAudience'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            },
                        })
                        return;
                    } else {

                        setInputTitle('');
                        setInputText('');
                        setYoutubeUrl('');

                        setTamplateId('');
                        storiesSubmit('stories');


                    }
                }

            }

            if (number === '2') {
                handleTonePress('', '');
            }
            if (number === '3') {
                handleBankOfImage();


            }
        } else {
            Swal.fire({
                title: t('main', 'confirmCreditUsage'),
                showCancelButton: true,
                confirmButtonText: t('main', 'yes'),
                cancelButtonText: t('main', 'no'),
                input: 'checkbox',
                inputValue: 0,
                inputPlaceholder: t('main', 'doNotShowAgain'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    // E adicionamos a verificação aqui
                    if (result.value === 1) {
                        localStorage.setItem('popupChoice', 'checked');
                    }

                    if (number === '1') {
                        if (choise === 'default') {
                            if (!targetAudience || !topic) {
                                Swal.fire({
                                    icon: 'info',
                                    title: t('main', 'attention'),
                                    text: t('main', 'specifyAudienceAndTopic'),
                                    customClass: {
                                        popup: 'my-swal-alert',
                                        title: 'myCustomTitle'
                                    },
                                })
                            } else {

                                setInputText('');
                                setInputTitle('');
                                setYoutubeUrl('');

                                handleSubmit('');
                            }
                        }
                        if (choise === 'fromTemplate') {

                            if (!targetAudience) {
                                Swal.fire({
                                    icon: 'info',
                                    title: t('main', 'attention'),
                                    text: t('main', 'specifyAudience'),
                                    customClass: {
                                        popup: 'my-swal-alert',
                                        title: 'myCustomTitle'
                                    },
                                })
                            } else {

                                setYoutubeUrl('');
                                setInputText('');
                                setInputTitle('');

                                setTamplateId('');
                                storiesSubmit('template');

                            }

                        }
                        if (choise === 'customText') {

                            if (!inputText || !inputTitle) {
                                Swal.fire({
                                    icon: 'info',
                                    title: t('main', 'attention'),
                                    text: t('main', 'specifyTitleAndText'),
                                    customClass: {
                                        popup: 'my-swal-alert',
                                        title: 'myCustomTitle'
                                    },
                                })

                                return;

                            } else {

                                setYoutubeUrl('');
                                startNewCarouselSession().then((newSessionId) => {
                                    //console.log('PASSOU AQUI? SESSION ID: ' + newSessionId);
                                    handleTonePress(newSessionId, ''); // Passe newSessionId como um argumento
                                });

                            }
                        }

                        if (choise === 'youtube') {

                            if (!youtubeUrl || !inputTitle) {
                                Swal.fire({
                                    icon: 'info',
                                    title: t('main', 'attention'),
                                    text: t('main', 'specifyAudienceAndLink'),
                                    customClass: {
                                        popup: 'my-swal-alert',
                                        title: 'myCustomTitle'
                                    },
                                })
                                return;

                            } else {

                                setInputText('');
                                startNewCarouselSession().then((newSessionId) => {
                                    // console.log('PASSOU AQUI? SESSION ID: ' + newSessionId);
                                    handleTonePress(newSessionId, ''); // Passe newSessionId como um argumento
                                });
                            }
                        }

                    }

                    if (choise === 'stories') {

                        if (!targetAudience || !topic) {
                            Swal.fire({
                                icon: 'info',
                                title: t('main', 'attention'),
                                text: t('main', 'specifyAudienceAndTopic'),
                                customClass: {
                                    popup: 'my-swal-alert',
                                    title: 'myCustomTitle'
                                },
                            })
                        } else {

                            setInputTitle('');
                            setInputText('');
                            setYoutubeUrl('');

                            setTamplateId('');
                            storiesSubmit('stories');

                        }
                    }

                    if (number === '2') {
                        handleTonePress('', '');
                    }
                    if (number === '3') {

                        handleBankOfImage();

                    }
                }
            });
        }
    }



    // Ajuste da função handleBankOfImage para aceitar um identificador de slider
    function handleBankOfImage(oneTime = true, slideId = 'slide1', retries = 3, searchTerm = null, searchTerms_next = [], searchTermPort = null, searchTerms_next_port = [], index = 0) {

        if (oneTime) {

            setIsLoadingThumbnail(true);

        } else {

            setSliderStates(prevState => ({
                ...prevState,
                [slideId]: { ...prevState[slideId], isLoading: true },
            }));
        }


        let slidesTexts = {};


        if (carousels) {

            /*  slidesTexts = carousels.split('\n\n').reduce((acc, slideInfo) => {
                  const slideNumberMatch = slideInfo.match(/Slide (\d+):/);
                  const textMatch = slideInfo.match(/Texto: (.*)/);
                  const headlineMatch = slideInfo.match(/Headline: (.*)/);
  
                  if (slideNumberMatch && (textMatch || headlineMatch)) {
                      const slideNumber = slideNumberMatch[1];
                      // Aqui removemos as tags <p> e </p> do texto
                      let text = textMatch ? textMatch[1] : headlineMatch[1];
                      text = text.replace(/<\/?p>/g, '');
  
                      acc[`slide${slideNumber}`] = text;
                  }
  
                  return acc;
              }, {}); */

            slidesTexts = carousels.split('Slide').reduce((acc, slideInfo) => {
                // Ignora o primeiro elemento vazio que pode vir do split
                if (!slideInfo.trim()) return acc;

                // Extrai o número do slide
                const slideNumberMatch = slideInfo.match(/(\d+):/);
                if (!slideNumberMatch) return acc;

                const slideNumber = slideNumberMatch[1];

                // Procura por Headline ou Texto em todo o conteúdo do slide
                const headlineMatch = slideInfo.match(/Headline:([\s\S]*?)(?=(?:Slide \d+:|$))/);
                const textMatch = slideInfo.match(/Texto:([\s\S]*?)(?=(?:Slide \d+:|$))/);

                // Pega o texto completo, seja do headline ou do texto normal
                let text = '';
                if (headlineMatch) {
                    text = headlineMatch[1].trim();
                } else if (textMatch) {
                    text = textMatch[1].trim();
                }

                // Remove tags HTML se existirem
                text = text.replace(/<\/?p>/g, '');

                // Adiciona ao acumulador apenas se houver texto
                if (text) {
                    acc[`slide${slideNumber}`] = text;
                }

                return acc;
            }, {});

        }

        //console.log('Vamos descobrir o valor do texto');
        // console.log(slidesTexts);

        // Extrai o texto específico para o slide atual
        let slideText = slidesTexts[slideId];

        console.log('DESCOBRINDO O TEXTO ANTES DE SEPARAR POR SLIDES:');
        console.log(carousels)

        console.log('DESCOBRINDO O TEXTO COMPLETO:');
        console.log(slidesTexts);

        console.log('DESCOBRINDO O TEXTO QUE ESTÁ APARECENDO NO CAMPO: ' + slideId);
        console.log(slideText);

        //console.log(slideText);

        if (oneTime) {  //(!searchTerm && !searchTermPort) {
            if (swiperRef) {
                setTimeout(() => {
                    swiperRef.slideTo(swiperRef.activeIndex + 1, 1000);
                }, 100);
            }
        }

        //  console.log('QUANTAS VEZES PASSA POR AQUI');


        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/word-endpoint', {
            headline: selectedHeadline || inputTitle || inputTitleInsta,
            audience: targetAudience,
            searchTerm: searchTerm,
            searchTerms_next: searchTerms_next,
            searchTermPort: searchTermPort,
            searchTerms_next_port: searchTerms_next_port,
            text: slideText,
            index: index,
            language: language
        })
            .then(response => {
                if (response.data.photos.length === 0 && retries > 0) {
                    Swal.fire({
                        icon: 'warning',
                        title: t('main', 'attention'),
                        text: t('main', 'noPhotosRelated'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });

                    setTimeout(() => {
                        handleBankOfImage(false, slideId, retries - 1);
                    }, 500);


                } else {

                    // Preparar os dados das imagens da resposta
                    const imagesData = response.data.photos.map(photo => ({
                        thumbnail: photo.thumbnail,
                        full: photo.original,
                        url: photo.url,
                        photographer: photo.photographer,
                        photographer_url: photo.photographer_url,
                        avg_color: photo.avg_color
                    }));

                    if (response.data.matches) {

                        setSliderStates(prevState => {
                            const newState = { ...prevState };

                            // Verifica se estamos tratando do slide1, oneTime é true, e selectedImage já está preenchido

                            if (!(slideId === 'slide1' && oneTime && newState[slideId].selectedImage)) {

                                newState[slideId] = {
                                    ...newState[slideId],
                                    userSearchTerm: oneTime ? '' : response.data.searchTermPortuguese,
                                    searchTerms: response.data.matches,
                                    searchTermsPort: response.data.matches2,
                                    thumbnailImages: imagesData,
                                    isLoading: false,
                                };


                                // Atualizar o estado de todos os slides com os textos, se houver
                                Object.keys(slidesTexts).forEach(slideKey => {
                                    if (newState[slideKey]) {
                                        newState[slideKey] = {
                                            ...newState[slideKey],
                                            text: slidesTexts[slideKey],
                                        };
                                    }
                                });

                            }

                            return newState;
                        });



                    } else {

                        setSliderStates(prevState => {
                            const newStateChose = { ...prevState };

                            // Atualizar o estado do slide atual com as imagens

                            newStateChose[slideId] = {
                                ...newStateChose[slideId],
                                thumbnailImages: imagesData,
                                isLoading: false,
                            };

                            return newStateChose;
                        });

                    }

                }

                setIsLoadingThumbnail(false);
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.error || t('main', 'serverError'),
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });

                setSliderStates(prevState => ({
                    ...prevState,
                    [slideId]: { ...prevState[slideId], isLoading: false },
                }));

                setIsLoadingThumbnail(false);

            });
    }

    useEffect(() => {
    }, [selectedFormat, sliderStates]);



    const renderTabs = () => {
        const tabs = [];

        // Adiciona o botão 'Anterior' se não estiver na primeira página de abas
        if (currentTabPage > 0) {
            tabs.push(
                <button
                    key="previous"
                    onClick={() => {
                        setCurrentTabPage(currentTabPage - 1);
                        setTabIndex(tabsPerPage - 1); // Mudar para a última aba da página anterior
                        handleTabChange(tabsPerPage - 1, currentTabPage - 1); // Garante que a aba correta seja carregada
                    }}
                    style={{
                        display: 'inline-block', margin: '0 -8px', color: '#757575',
                        padding: '8px 16px', border: 'none', borderRadius: '4px',
                        backgroundColor: 'transparent', boxShadow: 'none',
                        cursor: 'pointer', outline: 'none',
                        WebkitTapHighlightColor: 'transparent' // Remove o brilho ao clicar no mobile
                    }}
                >
                    ←
                </button>
            );
        }

        // Calcular o início e fim dos índices de slides para a página atual
        const startIndex = currentTabPage * tabsPerPage;
        const endIndex = Math.min(startIndex + tabsPerPage, isStories ? selectedSlidesStories : selectedSlides);

        // Adicionar abas de slides
        for (let i = startIndex; i < endIndex; i++) {

            tabs.push(
                <Tab key={`slide-${i}`} onClick={() => handleTabChange(i)}>
                    {isMobile ?
                        `${i + 1}` :
                        (i === 0 ? `${t('main', 'cover')}` : `Slide ${i + 1}`)
                    }
                    {sliderStates[`slide${i + 1}`].selectedImage && <MdCheck />}
                </Tab>
            );


        }


        // Adicionar o botão 'Próximo' se não estiver na última página de abas
        if (endIndex < selectedSlides) {
            tabs.push(
                <button
                    key="next"
                    onClick={() => {
                        setCurrentTabPage(currentTabPage + 1);
                        setTabIndex(0); // Ajuste para iniciar na primeira aba da próxima página
                        handleTabChange(0, currentTabPage + 1);
                    }}
                    style={{
                        display: 'inline-block', margin: '0px -8px', color: '#757575', padding: '8px 16px',
                        border: 'none', borderRadius: '4px', backgroundColor: 'transparent',
                        boxShadow: 'none', cursor: 'pointer', outline: 'none',
                        WebkitTapHighlightColor: 'transparent', // Remove o brilho ao clicar no mobile
                    }}
                >
                    →
                </button>
            );
        }


        return tabs;
    };



    const handleTabChange = (index, newTabPage = currentTabPage) => {

        const globalIndex = newTabPage * tabsPerPage + index;
        //  console.log("Changing tab to index:", index, "Global index:", globalIndex);
        if (globalIndex < (isStories ? selectedSlidesStories : selectedSlides)) {
            const slideId = `slide${globalIndex + 1}`;

            if (!sliderStates[slideId].loaded) {
                handleBankOfImage(false, slideId);
                setSliderStates(prevState => ({
                    ...prevState,
                    [slideId]: {
                        ...prevState[slideId],
                        loaded: true
                    }
                }));
            }
            setTabIndex(index); // Sincroniza o índice local da aba
        }
    };


    const getSelectedImageForType = (slideId) => {
        const currentSlide = sliderStates[slideId];

        switch (currentSlide.imageSourceType) {
            case 'bank':
                return currentSlide.selectedBankImage;
            case 'ai':
                return currentSlide.selectedAIImage;
            case 'own':
                return currentSlide.selectedUploadImage;
            default:
                return null;
        }
    };


    const renderTabPanels = () => {
        const startIndex = currentTabPage * tabsPerPage;
        const endIndex = Math.min(startIndex + tabsPerPage, isStories ? selectedSlidesStories : selectedSlides);
        const tabPanels = [];

        for (let i = startIndex; i < endIndex; i++) {
            const slideId = `slide${i + 1}`;

            tabPanels.push(
                <TabPanel key={`panel-${i}`}>
                    <ImageSelector
                        onSearchTermChange={(e) => handleUserSearchTermChange(slideId, e)}
                        onNewImagesClick={(e) => handleNewImagesClick(slideId, e)}
                        thumbnailImages={sliderStates[slideId].thumbnailImages}
                        onImageSelected={(thumbnailUrl) => handleImageSelected(slideId, thumbnailUrl.full, thumbnailUrl.avg_color)}
                        searchTerm={sliderStates[slideId].userSearchTerm}
                        isLoading={sliderStates[slideId].isLoading}
                        text={`Slide ${i + 1}: ` + sliderStates[slideId].text}
                        imageSourceType={sliderStates[slideId].imageSourceType}
                        selectedAIImage={sliderStates[slideId].selectedAIImage}
                        selectedBankImage={sliderStates[slideId].selectedBankImage}
                        selectedUploadImage={sliderStates[slideId].selectedUploadImage}
                        selectedImage={getSelectedImageForType(slideId)}
                        onSourceTypeChange={(e) => handleImageSourceTypeChange(slideId, e)}
                        isMob={isMobile}
                        onFileUpload={(e) => uploadImage(slideId, e.target.files[0])}
                        selectedOwnImage={sliderStates[slideId].selectedUploadImage}
                        onIaSubmit={(promptData) => handleIaSubmit(promptData)}
                        handleBankOfImage={handleBankOfImage}
                        sessionId={sessionId}
                        slideId={slideId}
                        t={t}
                        sliderStates={sliderStates}
                    />
                </TabPanel>
            );
        }

        return tabPanels;
    };


    function handleUserSearchTermChange(sliderId, e) {
        setSliderStates(prevState => ({
            ...prevState,
            [sliderId]: {
                ...prevState[sliderId],
                userSearchTerm: e.target.value.slice(0, 20),
                currentSearchTermIndex: -1, // Indica busca personalizada
            }
        }));
    }

    const handlePointerMove = (e) => {
        e.stopPropagation();
    };

    const handleIaSubmit = async (promptData) => {
        const { prompt, style, slideId, isInitialLoad } = promptData;

        if (sliderStates[slideId].isProcessing) return null;

        setSliderStates(prevState => ({
            ...prevState,
            [slideId]: { ...prevState[slideId], isLoading: true, isProcessing: true },
        }));

        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/generate-image', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ prompt, style })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.url) {
                setSliderStates(prevState => ({
                    ...prevState,
                    [slideId]: {
                        ...prevState[slideId],
                        selectedAIImage: data.url,
                        selectedImage: data.url,
                        iaImageUrl: data.url,
                        selectedBankImage: null,
                        selectedUploadImage: null,
                        isLoading: false,
                        isProcessing: false
                    },
                }));
                return data.url;
            } else {
                throw new Error('No URL in response');
            }
        } catch (error) {
            console.error('Error generating image:', error);
            setSliderStates(prevState => ({
                ...prevState,
                [slideId]: {
                    ...prevState[slideId],
                    isLoading: false,
                    isProcessing: false
                },
            }));

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: t('main', 'iaGenerationFailure'),
                customClass: {
                    popup: 'my-swal-alert',
                    title: 'myCustomTitle'
                }
            });

            return null;
        }
    };


    const handleImageSourceTypeChange = (slideId, sourceType) => {
        setSliderStates(prevState => {
            const currentState = prevState[slideId];
            return {
                ...prevState,
                [slideId]: {
                    ...currentState,
                    imageSourceType: sourceType,
                    isLoading: false,
                    // Mantenha todos os estados de imagem
                    selectedBankImage: currentState.selectedBankImage,
                    selectedAIImage: currentState.selectedAIImage,
                    selectedUploadImage: currentState.selectedUploadImage,
                    selectedImage: currentState.selectedImage,
                    selectedRadio: 'ai',
                }
            };
        });
    };



    const handleImageSelected = (slideId, thumbnailUrl, AVG) => {
        setSliderStates(prevState => {
            const currentState = prevState[slideId];
            const imageSourceType = currentState.imageSourceType;

            let newState = {
                ...prevState,
                [slideId]: {
                    ...currentState,
                    // Primeiro limpa tudo
                    selectedAIImage: null,
                    selectedBankImage: null,
                    selectedUploadImage: null,
                    selectedImage: null
                }
            };

            // Se não está desselecionando (thumbnailUrl não é null)
            if (thumbnailUrl) {
                switch (imageSourceType) {
                    case 'ai':
                        newState[slideId] = {
                            ...newState[slideId],
                            selectedAIImage: thumbnailUrl,
                            selectedImage: thumbnailUrl,
                            iaImageUrl: thumbnailUrl,
                        };
                        // Força a mudança do tipo para AI

                        break;

                    case 'bank':
                        newState[slideId] = {
                            ...newState[slideId],
                            selectedBankImage: thumbnailUrl,
                            selectedImage: thumbnailUrl
                        };
                        // Força a mudança do tipo para bank

                        break;

                    case 'own':
                        newState[slideId] = {
                            ...newState[slideId],
                            selectedUploadImage: thumbnailUrl,
                            selectedImage: thumbnailUrl,
                            uploadedImageUrl: thumbnailUrl
                        };
                        // Força a mudança do tipo para own

                        break;
                }
            }

            return newState;
        });
    };


    function handleNewImagesClick(sliderId) {
        setSliderStates(prevState => ({
            ...prevState,
            [sliderId]: {
                ...prevState[sliderId],
                isLoading: true,
            }
        }));

        const slideState = sliderStates[sliderId];
        const userSearchTerm = slideState.userSearchTerm;
        const currentSearchTermIndex = slideState.currentSearchTermIndex;
        const searchTerms = slideState.searchTerms;
        const searchTermsPort = slideState.searchTermsPort;

        if (userSearchTerm && currentSearchTermIndex === -1) {
            // Se há um termo de busca personalizado
            handleBankOfImage(false, sliderId, 3, null, [], userSearchTerm, []);
            setSliderStates(prevState => ({
                ...prevState,
                [sliderId]: {
                    ...prevState[sliderId],
                    currentSearchTermIndex: 0,
                }
            }));

        } else {
            // Se não há termo personalizado ou está usando os termos sugeridos
            const nextIndex = currentSearchTermIndex === -1 ? 0 :
                (currentSearchTermIndex + 1) % (searchTerms ? searchTerms.length : 1);

            if (searchTerms && searchTerms.length > 0) {
                setSliderStates(prevState => ({
                    ...prevState,
                    [sliderId]: {
                        ...prevState[sliderId],
                        currentSearchTermIndex: nextIndex,
                        userSearchTerm: searchTermsPort[nextIndex],
                    }
                }));

                handleBankOfImage(
                    false,
                    sliderId,
                    3,
                    searchTerms[nextIndex],
                    searchTerms,
                    searchTermsPort[nextIndex],
                    searchTermsPort,
                    nextIndex
                );
            } else {
                // Se não houver termos sugeridos, recarrega com o termo atual
                handleBankOfImage(false, sliderId, 3, null, [], userSearchTerm, []);
            }
        }
    }


    const handleAdvanceButtonClick = () => {


        setTimeout(() => setShouldShowStylePrompt(false), 4000);
        setIsCarouselType(true);
        setShouldShowStylePrompt(true);

        if (swiperRef) {
            setTimeout(() => {
                swiperRef.slideTo(swiperRef.activeIndex + 1, 1000);
            }, 100);
        }
    }


    /*   const handleImageSourceTypeChange = (slideId, sourceType) => {
           setSliderStates(prevState => ({
               ...prevState,
               [slideId]: {
                   ...prevState[slideId],
                   imageSourceType: sourceType,
               }
           }));
       }; */


    async function uploadImage(slideId, file) {
        let flip = false;
        const reader = new FileReader();

        reader.onload = async (e) => {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('carouselType', selectedFormat);

            try {
                // Primeiro, atualize apenas o estado de loading
                setSliderStates(prevState => ({
                    ...prevState,
                    [slideId]: {
                        ...prevState[slideId],
                        isLoading: true,
                        isUploading: true,
                    }
                }));

                if (selectedFormat === 'standard') {
                    flip = await askUserToFlipImage(e.target.result);
                }
                formData.append('flip', flip);

                const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/upload-image', {
                    method: 'POST',
                    body: formData
                });

                const data = await response.json();

                if (data.url) {

                    // Atualize o estado mantendo todos os estados anteriores
                    setSliderStates(prevState => ({
                        ...prevState,
                        [slideId]: {
                            ...prevState[slideId],
                            selectedUploadImage: data.url,
                            selectedImage: data.url,
                            uploadedImageUrl: data.url,
                            selectedAIImage: null,
                            selectedBankImage: null,
                            iaImageUrl: null,
                            isLoading: false,
                            isUploading: false
                            // Manter os outros estados específicos
                            //selectedAIImage: prevState[slideId].selectedAIImage,
                            // selectedBankImage: prevState[slideId].selectedBankImage,
                        }
                    }));

                    // Notificar sucesso
                    toast.success(t('main', 'imageUploadSuccess'), {
                        position: toast.POSITION.BOTTOM_LEFT,
                        autoClose: 2000
                    });
                }
            } catch (error) {
                // Em caso de erro, mantenha o estado atual
                setSliderStates(prevState => ({
                    ...prevState,
                    [slideId]: {
                        ...prevState[slideId],
                        isLoading: false,
                        isUploading: false,
                    }
                }));

                // Notificar erro
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: t('main', 'loadingFailure'),
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            }
        };

        reader.readAsDataURL(file);
    }





    async function handleImages() {

        // Aqui assumimos que 'sliderStates' tem todas as informações necessárias

        let selectedImages = Object.keys(sliderStates).map(slideId => sliderStates[slideId].selectedImage);
        let avgs = Object.keys(sliderStates).map(slideId => sliderStates[slideId].selectedAVG);
        let flip = Object.keys(sliderStates).map(slideId => sliderStates[slideId].flip);


        setIsLoadingImage(true);
        setLoadingMessage(isStories ? t('main', 'preparingStories') : t('main', 'preparingCarousel'));

        if (swiperRef) {
            setTimeout(() => {
                swiperRef.slideTo(headlines.length + 5, 1000);
            }, 100);
            swiperRef.slideNext();
        }


        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/generate-slides', {
            carousels: carousels,  // Envia o array de textos para o servidor
            images: selectedImages, // Envia as imagens selecionadas
            avgs: avgs,
            //capaImage: capa,
            userId: userId,
            instaId: isntaId,
            savedUserHistoryId: sessionId,
            totalTime: total_time_api,
            totalToken: total_tokens,
            perfilPicture: isValidUrl(localStorage.getItem('perfil_picture')) ? localStorage.getItem('perfil_picture') : `${process.env.REACT_APP_AVATAR_BASE_URL}${userId}${process.env.REACT_APP_AVATAR_PARAMS}`,
            flip: flip,
            //avg_color: avg_color !== null ? avg_color : '',
            name: localStorage.getItem('name') || nameUser,
            lastname: localStorage.getItem('lastname') || lastnameUser,
            carouselType: selectedFormat,
            colors: [
                styleConfig.colors.Titulos,
                styleConfig.colors.Textos,
                styleConfig.colors.Fundo
            ],
            titleFont: styleConfig.useCustomStyle ? styleConfig.fonts.titleFont : '',
            textFont: styleConfig.useCustomStyle ? styleConfig.fonts.textFont : '',
            layoutValue: selectedLayValue,
            gpt4: isGPT4Enabled,
            BackGroundId: selectedBackGroundId
        })


            .then(response => {

                const fullImageUrls = response.data.imageUrls; //.map(imageUrl => process.env.REACT_APP_API_BASE_URL + '/' + imageUrl);
                const presentationId = response.data.presentationId;
                const newCredits = response.data.credits;
                localStorage.setItem('credits', JSON.stringify(newCredits));
                setImages(fullImageUrls);
                setCarouselEdit(presentationId);
                setIsLoadingImage(false);

                const creditAlert = response.data.creditAlert;
                if (creditAlert && creditAlert.shouldAlert) {
                    displayCreditAlert(creditAlert.creditsRemaining);
                }

            })
            .catch(error => {
                console.error(error);
                // Alert //
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    //text: error.response.data.message,
                    text: error.response.data.error || t('main', 'serverError'),
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    }
                });
            });


    }


    const UserProfileImage = ({ userId, perfilPicture }) => {
        const defaultAvatarUrl = `${process.env.REACT_APP_AVATAR_BASE_URL}${userId}${process.env.REACT_APP_AVATAR_PARAMS}`;
        const imageUrl = isValidUrl(perfilPicture) ? perfilPicture : defaultAvatarUrl;
        console.log(imageUrl);
        console.log(perfilPicture);
        console.log(defaultAvatarUrl);
        return (
            <div
                style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',  // Isso fará com que a div seja redonda
                    backgroundSize: 'cover',  // Garante que a imagem cubra toda a div
                    backgroundImage: `url(${imageUrl})`,  // Define a imagem de perfil como background
                    border: '2px solid white'  // Um pequeno detalhe para separar a imagem do fundo, caso necessário
                }}
            ></div>
        );
    };

    const UserInfo = ({ userName, userId }) => {
        const [perfilPicture, setPerfilPicture] = useState(localStorage.getItem('perfil_picture'));
        // const [userCredits, setUserCredits] = useState(parseInt(localStorage.getItem('credits')) || 0);

        const getCreditsSum = () => {
            const creditsString = localStorage.getItem('credits');
            const credits = creditsString ? JSON.parse(creditsString) : { "GPT-3": 0, "GPT-4": 0 };
            return (credits["GPT-3"] || 0) + (credits["GPT-4"] || 0);
        };

        const [userCredits, setUserCredits] = useState(getCreditsSum());

        useEffect(() => {
            const handleStorageChange = (e) => {
                if (e.key === 'perfil_picture') {
                    setPerfilPicture(localStorage.getItem('perfil_picture'));
                } else if (e.key === 'credits') {
                    setUserCredits(parseInt(localStorage.getItem('credits')) || 0);
                }
            };

            window.addEventListener('storage', handleStorageChange);

            return () => {
                window.removeEventListener('storage', handleStorageChange);
            };
        }, []);

        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: '6px',
                    backgroundColor: '#CCCCCC',
                    color: '#fff',
                    fontSize: '17px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                }}
                onClick={() => window.open(`/viewuser?lang=${language}`, '_self')}
            >
                <UserProfileImage userId={userId} perfilPicture={perfilPicture} />
                <div className="userInfoDesktop" style={{ marginLeft: '10px' }}>
                    {userName} |&nbsp;
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation(); // Impede que o evento se propague para a div pai
                            displayCreditPurchaseOptions();  // Chama a função para comprar créditos
                        }}
                    >
                        {userCredits} {t('main', 'credits')}
                    </span>
                </div>
                <div className="userInfoMobile">
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation(); // Impede que o evento se propague para a div pai

                            displayCreditPurchaseOptions(); // Chama a função para comprar créditos

                        }}
                    >
                        {userCredits} {t('main', 'credits')}
                    </span>
                </div>

            </div>
        );
    };

    const mainOpen = () => {
        refreshPage();
        setIsOpen(false);
    }


    const analysisOpen = () => {
        navigate(`/analysis?lang=${language}`);
    }

    const perfilOpen = () => {
        navigate(`/viewuser?lang=${language}`);
    }

    const pciOpen = (language) => {
        navigate(`/pci?lang=${language}`);
    }

    const historyOpen = (language) => {
        navigate(`/history?lang=${language}`);
    }

    const logout = (language) => {
        navigate(`/login?lang=${language}`);
    }



    function ImageComponent({ imageUrl }) {
        return (
            <div className="image-container">
                <img src={imageUrl} alt="" className="slide-image" />
            </div>
        );
    }

    function refreshPage() {
        Swal.fire({
            icon: 'warning',
            title: t('main', 'attention'),
            text: t('main', 'infoProcessRestart'),
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            },
            showCancelButton: true,
            confirmButtonText: t('main', 'tryAgain'),
            cancelButtonText: t('main', 'cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.reload(); // Recarrega a página
            }
        });
    }


    function displayCreditAlert(creditInfo) {

        Swal.fire({
            title: `${t('main', 'creditsRemainingPrefix')} ${creditInfo} ${t('main', 'creditsRemainingSuffix')}`,
            text: /*isNotProductionEnvironment() ? t('main', 'postSuccessWithDiscount') : */ t('main', 'doYouWantToBuyMoreCredits'),
            icon: 'warning',
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            },
            showCancelButton: true,
            confirmButtonText: t('main', 'yes'),
            cancelButtonText: t('main', 'no')
        }).then((result) => {
            /* if (result.isConfirmed && isNotProductionEnvironment()) {
                 // Se for confirmado e isUATEnvironment() for true, redirecione para o link de checkout da Eduzz
                 window.open('https://sun.eduzz.com/2105378?cupom=websummit0424', '_blank');
 
             } else */
            if (result.isConfirmed) {
                // Se for apenas confirmado (independente de isUATEnvironment()), chame displayCreditPurchaseOptions
                displayCreditPurchaseOptions();
            }
        });
    }

    function displayCreditPurchaseOptions() {
        Swal.fire({
            title: t('main', 'chooseCreditAmount'),
            input: 'radio',
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            },
            inputOptions: {
                '50': t('main', 'creditsOption50'),
                '100': t('main', 'creditsOption100'),
                '200': t('main', 'creditsOption200')
            },
            inputValidator: (value) => {
                if (!value) {
                    return t('main', 'mustChooseOption');
                }
            },
            confirmButtonText: t('main', 'buy')
        }).then((result) => {
            if (result.isConfirmed) {
                redirectToEduzzCheckout(result.value);
            }
        });
    }

    function redirectToEduzzCheckout(creditAmount) {
        // Redireciona o usuário para a página de checkout da Eduzz com a quantidade de créditos escolhida
        // Você precisará da URL de checkout da Eduzz para cada pacote de créditos
        const checkoutUrls = {
            '50': 'https://sun.eduzz.com/2230353',
            '100': 'https://sun.eduzz.com/2230366',
            '200': 'https://sun.eduzz.com/2230371'
        };

        window.open(checkoutUrls[creditAmount], '_blank');

    }


    class RetryImage extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                src: props.src,
                attemptCount: 0
            };
        }

        handleError = () => {
            setTimeout(() => {
                if (this.state.attemptCount < 3) {
                    console.log(`Tentando recarregar a imagem: ${this.state.src} (Tentativa ${this.state.attemptCount + 1})`);
                    this.setState(prevState => ({
                        src: `${this.props.src}?retry=${prevState.attemptCount + 1}`,
                        attemptCount: prevState.attemptCount + 1
                    }));
                } else if (this.state.attemptCount >= 3 && this.state.attemptCount < 6) {
                    console.log(`Tentando carregar a imagem do Azure Blob: (Tentativa ${this.state.attemptCount - 2})`);
                    this.setState(prevState => ({
                        src: `https://blob01instasuccess.blob.core.windows.net/carrossel-completo/image${this.props.position.toString().padStart(2, '0')}_${this.props.carouselEdit}.jpg?retry=${prevState.attemptCount - 2}`,
                        attemptCount: prevState.attemptCount + 1
                    }));
                } else {
                    console.log("Falha após várias tentativas");
                }
            }, 500);
        }

        render() {
            const { className, isFirst, isStory } = this.props;
            const imageClass = `${className} ${isFirst ? 'firstImage' : 'subsequentImage'} ${isStory ? 'storyImage' : ''}`;

            let marginStyle = '';
            if (isFirst) {
                if (isStory) {
                    marginStyle = tokenExpiriedFace ? '-50px 0 0px 0' : '-200px 0 0px 0';
                } else {
                    marginStyle = tokenExpiriedFace ? '-210px 0 -130px 0' : '-355px 0 -130px 0';
                }
            }

            return (
                <img
                    src={this.state.src}
                    alt={this.props.alt}
                    onError={this.handleError}
                    onLoad={this.props.onLoad}
                    className={imageClass}
                    style={{ margin: marginStyle }}
                />
            );
        }
    }






    const handleCheckboxChangePCI = (e) => {

        const checked = e.target.checked;
        setIsCheckedPCI(checked);

        if (checked) {

            setTargetAudience(summary);
            //setIsInputDisabled(true);


        } else {

            setTargetAudience('');
            //setIsInputDisabled(false);

        }
    };

    const handleCheckboxChangePart = (e) => {

        const checked = e.target.checked;
        setIsCheckedPart(checked);

    };






    let selectedRating = 0;

    function renderStars(rating) {
        let stars = '';
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars += '<i class="fas fa-star rating-star" data-rating="' + i + '"></i>';
            } else {
                stars += '<i class="far fa-star rating-star" data-rating="' + i + '"></i>';
            }
        }
        return stars;
    }

    function copyToClipboard(text) {
        // Ajustando o texto para que cada slide seja separado por duas quebras de linha e mantenha a formatação interna
        const formattedText = text
            .replace(/Slide (\d+):/g, '\nSlide $1:\n') // Adiciona uma quebra de linha antes de cada "Slide n:"
            .replace(/<p>/g, '') // Substitui <p> por ** para dar ênfase
            .replace(/<\/p>/g, '') // Substitui </p> por ** para fechar a ênfase
            .trim(); // Remove espaços extras no início e no fim do texto

        const tempInput = document.createElement('textarea'); // Usar textarea para manter quebras de linha
        document.body.appendChild(tempInput);
        tempInput.value = formattedText;
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
    }




    var span = document.createElement("span");
    span.innerHTML = `<textarea name="txtBox" placeholder="${t('main', 'placeholderLeaveComment')}"  class="my-textarea" ></textarea>
                    <div class="star-rating">
                    <input type="radio" name="rating" value="5"  id="5"><label for="5">☆</label>
                    <input type="radio" name="rating" value="4"  id="4"><label for="4">☆</label>
                    <input type="radio" name="rating" value="3"  id="3"><label for="3">☆</label>
                    <input type="radio" name="rating" value="2"  id="2"><label for="2">☆</label>
                    <input type="radio" name="rating" value="1"  id="1"><label for="1">☆</label>
                    </div>`;



    function ratePost() {
        Swal.fire({
            title: t('main', 'ratePostPrompt'),
            html: span.outerHTML,
            // input: 'textarea',
            inputPlaceholder: t('main', 'leaveCommentPlaceholder'),
            confirmButtonText: t('main', 'save'),
            cancelButtonText: t('main', 'cancel'),
            showCancelButton: true,
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            },
            preConfirm: () => {
                const comment = document.querySelector('.my-textarea').value;
                const selectedRating = document.querySelector('input[name="rating"]:checked');
                const rating = selectedRating ? selectedRating.value : 0;
                return {
                    comment: comment,
                    rating: rating
                };
            }
        }).then(result => {
            if (result.isConfirmed) {

                const rating = result.value.rating;
                const comment = result.value.comment;

                axios.post(process.env.REACT_APP_API_BASE_URL + '/api/updateRating', {
                    presentationId: carouselEdit,
                    note: rating,
                    comment: comment
                })
                    .then(response => {
                        document.body.style.overflow = 'auto';
                        Swal.fire({
                            title: t('main', 'success'),
                            text: t('main', 'evaluationSuccessText'),
                            icon: 'success',
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            }
                        });
                    })
                    .catch(error => {
                        document.body.style.overflow = 'auto';
                        Swal.fire({
                            title: t('main', 'errorTitle'),
                            text: t('main', 'evaluationErrorText'),
                            icon: 'error',
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            }
                        });
                    });
            }
        });



        // Adicionando um ouvinte de eventos para atualizar a avaliação selecionada
        document.querySelectorAll('.rating-star').forEach(star => {
            star.addEventListener('click', function (e) {
                selectedRating = parseInt(e.target.getAttribute('data-rating'));
                document.getElementById('star-rating').innerHTML = renderStars(selectedRating);
            });
        });
    }


    // Frontend - Função de validação de URL do YouTube
    const isValidYoutubeUrl = (url) => {
        // Lista de padrões válidos de URL do YouTube
        const patterns = [
            /^https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]{11}/, // Desktop
            /^https?:\/\/youtu\.be\/[\w-]{11}/, // Compartilhar
            /^https?:\/\/m\.youtube\.com\/watch\?v=[\w-]{11}/, // Mobile
            /^https?:\/\/(www\.)?youtube\.com\/watch\?.*v=[\w-]{11}/, // Com parâmetros adicionais
            /^https?:\/\/youtu\.be\/[\w-]{11}\?.*/ // Compartilhar com parâmetros
        ];

        return patterns.some(pattern => pattern.test(url));
    };



    useEffect(() => {
        if (isLoadingCarousel && inputTypeTwo === 'youtube') {


            const timerId = setTimeout(() => {
                setFraseCarousel(frasesCarousel[0]); // Muda para a segunda frase após 29 segundos
            }, 13000); // 29 segundos

            return () => clearTimeout(timerId);
        }
    }, [isLoadingCarousel, inputTypeTwo]);


    const handleInputChange = (e, fieldType) => {

        const value = e.target.value;

        switch (fieldType) {

            case 'youtubeUrl':
                if (value === '') {
                    setYoutubeUrl(''); // Se o campo estiver vazio, atualiza o estado para vazio
                } else if (!isValidYoutubeUrl(value)) {
                    Swal.fire({
                        icon: 'error',
                        title: t('main', 'errorTitle'),
                        text: t('main', 'insertValidYoutubeLink'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });
                    e.target.value = ''; // Limpa o campo se o link não for válido
                } else {
                    setYoutubeUrl(value); // Atualiza o estado com o link válido
                }
                break;
            case 'targetAudience':
                if (value.includes('http://') || value.includes('https://')) {
                    Swal.fire({
                        icon: 'warning',
                        title: t('main', 'attention'),
                        text: t('main', 'linksNotAllowed'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });
                    e.target.value = '';
                } else {
                    setTargetAudience(value);
                }
                break;
            case 'topic':
                if (value.includes('http://') || value.includes('https://')) {
                    Swal.fire({
                        icon: 'warning',
                        title: t('main', 'attention'),
                        text: t('main', 'linksNotAllowed'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });
                    e.target.value = '';
                } else {
                    setTopic(value);
                }
                break;
            case 'inputTitle':
                if (value.includes('http://') || value.includes('https://')) {
                    Swal.fire({
                        icon: 'warning',
                        title: t('main', 'attention'),
                        text: t('main', 'linksNotAllowed'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });
                    e.target.value = '';
                } else {
                    setInputTitle(value);
                }
                break;
            case 'inputText':
                if (value.includes('http://') || value.includes('https://')) {
                    Swal.fire({
                        icon: 'warning',
                        title: t('main', 'attention'),
                        text: t('main', 'linksNotAllowed'),
                        customClass: {
                            popup: 'my-swal-alert',
                            title: 'myCustomTitle'
                        }
                    });
                    e.target.value = '';
                } else {
                    setInputText(value);
                }
                break;
            // Adicione mais cases conforme necessário
        }
    };

    const handleCaptionChange = (event) => {
        setCaption(event.target.value);
    };

    const toggleCaptionVisibility = () => {

        if (!caption.trim() && !isCaptionVisible) { // Se a legenda ainda não foi carregada


            setIsLoadingCap(true);

            const userChoice = localStorage.getItem('popupChoice_2');

            if (userChoice === 'checked') {

                setIsCaptionVisible(!isCaptionVisible);

                axios.post(process.env.REACT_APP_API_BASE_URL + '/api/caption', {
                    carousels: carousels,  // Envia o array de textos para o servidor
                    userId: userId,
                    savedUserHistoryId: sessionId,
                    totalTime: total_time_api,
                    totalToken: total_tokens,
                    gpt4: true,
                    language: language
                })
                    .then(response => {
                        setCaption(response.data.caption.choices[0].message.content);
                        setIsLoadingCap(false);

                        const newCredits = response.data.credits;
                        localStorage.setItem('credits', JSON.stringify(newCredits));

                        const creditAlert = response.data.creditAlert;
                        if (creditAlert && creditAlert.shouldAlert) {
                            displayCreditAlert(creditAlert.creditsRemaining);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        Swal.fire({
                            icon: 'error',
                            title: t('main', 'errorTitle'),
                            text: t('main', 'serverError'),
                            customClass: {
                                popup: 'my-swal-alert',
                                title: 'myCustomTitle'
                            }
                        });
                        setIsLoading(false);
                    });

            } else {

                Swal.fire({
                    title: t('main', 'confirmCreditUsage'),
                    showCancelButton: true,
                    confirmButtonText: t('main', 'yes'),
                    cancelButtonText: t('main', 'no'),
                    input: 'checkbox',
                    inputValue: 0,
                    inputPlaceholder: t('main', 'doNotShowAgain'),
                    customClass: {
                        popup: 'my-swal-alert',
                        title: 'myCustomTitle'
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (result.value === 1) {
                            localStorage.setItem('popupChoice_2', 'checked');

                        }

                        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/caption', {
                            carousels: carousels,  // Envia o array de textos para o servidor
                            userId: userId,
                            savedUserHistoryId: sessionId,
                            totalTime: total_time_api,
                            totalToken: total_tokens,
                            gpt4: true,
                            language: language
                        })
                            .then(response => {
                                setCaption(response.data.caption.choices[0].message.content);
                                setIsLoadingCap(false);

                                const newCredits = response.data.credits;
                                localStorage.setItem('credits', JSON.stringify(newCredits));

                                const creditAlert = response.data.creditAlert;
                                if (creditAlert && creditAlert.shouldAlert) {
                                    displayCreditAlert(creditAlert.creditsRemaining);
                                }
                            })
                            .catch(error => {
                                console.error(error);
                                Swal.fire({
                                    icon: 'error',
                                    title: t('main', 'errorTitle'),
                                    text: t('main', 'serverError'),
                                    customClass: {
                                        popup: 'my-swal-alert',
                                        title: 'myCustomTitle'
                                    }
                                });
                                setIsLoading(false);
                            });

                        setIsCaptionVisible(!isCaptionVisible);
                    }

                });
            }
        } else {
            setIsCaptionVisible(!isCaptionVisible);
        }

    };


    /* function isNotProductionEnvironment() {
         return !window.location.href.includes('app.postsucesso.com.br');
     }
 
     function isUATEnvironment() {
         return window.location.href.includes('postsucesso-uat.azurewebsites.net');
     } */

    /* const handleRedirect = () => {
         window.open('https://sun.eduzz.com/2105378?cupom=websummit0424', '_blank');
     }; */


    /* useEffect(() => {
         if (!isLoadingImage && images.length > 0 && isNotProductionEnvironment()) {
             // Aguarda 15 segundos após o carrossel estar pronto
             const timer = setTimeout(() => {
                 rateApp(); // Chama a função que exibe o popup de avaliação
             }, 15000); // 15000ms = 15 segundos
 
             return () => clearTimeout(timer); // Limpa o timer se o componente for desmontado
         }
     }, [isLoadingImage, images]); */

    /*  function rateApp() {
  
          Swal.fire({
              icon: 'success',
              title: t('main','postSuccessWithDiscount'),
              confirmButtonText: t('main','wantDiscount'),
              cancelButtonText: t('main','willThinkAboutIt'),
              heightAuto: false,
              showCancelButton: true,
              customClass: {
                  popup: 'my-swal-alert',
                  title: 'myCustomTitle'
              },
          }).then((result) => {
              if (result.isConfirmed) {
                  // Se o usuário clicar em "Eu quero!", redirecione para o link de checkout
                  window.open('https://sun.eduzz.com/2105378?cupom=websummit0424', '_blank');
  
              } else {
                  // Se o usuário cancelar ou fechar o pop-up, você pode colocar alguma lógica aqui se necessário
                  console.log('Usuário vai pensar mais um pouco.');
              }
          });
      } */



    const customStyles = {
        options: {
            arrowColor: '#8E59FF', // Cor do balão de dicas
            backgroundColor: '#FFFFFF', // Cor de fundo do balão de dicas
            primaryColor: '#8E59FF', // Cor primária usada para o botão de ação
            textColor: '#757575', // Cor do texto
            zIndex: 1, // Z-index para o elemento sobrepor outros componentes
        },
        buttonNext: {
            backgroundColor: '#8E59FF' // Cor do botão "Próximo"
        },
        buttonBack: {
            backgroundColor: '#CCCCCC',
            color: '#FFFFFF' // Cor do botão "Voltar"
        },
        buttonSkip: {
            backgroundColor: '#CCCCCC',
            color: '#FFFFFF' // Cor do botão "Pular"
        }
    };


    const handleTemplateEdit = async (item, startNewCarouselSession, handleTonePress) => {

        console.log(item);

        const { value: editedTemplate } = await Swal.fire({
            title: t('main', 'titleTemplate'),
            text: t('main', 'textTemplate'),
            input: "textarea",
            inputValue: item.template,
            inputPlaceholder: t('main', 'editTemplate'),
            showCancelButton: true,
            confirmButtonText: t('main', 'advance'),
            cancelButtonText: t('main', 'cancel'),
            customClass: {
                popup: 'my-swal-alert',
                title: 'myCustomTitle'
            },
            didOpen: () => {
                const textarea = Swal.getInput(); // Obtém o textarea do SweetAlert
                if (textarea) {
                    textarea.selectionStart = 0; // Define o cursor no início
                    textarea.selectionEnd = 0; // Define o cursor no início
                    textarea.scrollTop = 0; // Garante que o texto inicie na parte superior da caixa de texto
                }
            }
        });

        if (editedTemplate) {
            // Chama a função de início da sessão com o template editado
            const newSessionId = await startNewCarouselSession();
            handleTonePress(newSessionId, editedTemplate, item.id);
        }
    };



    const [contentType, setContentType] = useState('carousel');


    const handleToggle = () => {
        setContentType(contentType === 'carousel' ? 'stories' : 'carousel');
    };


    return (

        <>
            <Joyride
                run={runJoyride}
                steps={joyrideSteps}
                styles={customStyles}
                continuous={true}
                showSkipButton={true}
                locale={{ skip: t('main', 'joyrideLabelsSkip'), next: t('main', 'joyrideLabelsNext'), back: t('main', 'joyrideLabelsBack'), last: t('main', 'joyrideLabelsLast') }}
                callback={(data) => {
                    if (data.status === 'finished' || data.status === 'skipped') {
                        setRunJoyride(false);
                    }
                }}
            />

            <Swiper
                onSwiper={(swiper) => setSwiperRef(swiper)}
                // allowSlideNext={isSubmitted} 

                onSlideChange={(swiper) => {

                    let traditionalStartIndex; // O índice 11 representa o slide 12
                    let resumingStartIndex; // O índice 1 representa o slide 2

                    if (inputType === 'default') {
                        traditionalStartIndex = 3; //12;
                        resumingStartIndex = 1;
                    } else {
                        traditionalStartIndex = 1;
                        resumingStartIndex = 0;
                    }


                    if (resumingSession) {

                        if (!selectedOutcome) {
                            if (swiper.activeIndex < resumingStartIndex) {
                                // Se estivermos na retomada de uma operação interrompida e tentarem ir para um slide antes do 12
                                swiper.slideTo(resumingStartIndex, 1000);
                            }
                        } else {
                            if (!selectedCarousel) {

                                traditionalStartIndex = 2;//11;

                                if (swiper.activeIndex < traditionalStartIndex && selectedHeadline) {
                                    // Se estivermos na retomada de uma operação interrompida e tentarem ir para um slide antes do 12
                                    swiper.slideTo(traditionalStartIndex, 1000);
                                }
                            } else {

                                traditionalStartIndex = 1;

                                if (swiper.activeIndex < traditionalStartIndex) {
                                    // Se estivermos no caminho tradicional e tentarem ir para um slide antes do 21
                                    swiper.slideTo(traditionalStartIndex, 1000);
                                }
                            }
                        }


                    } else {
                        if (inputType === 'default') {
                            if (swiper.activeIndex < traditionalStartIndex && selectedHeadline) {
                                // Se estivermos no caminho tradicional e tentarem ir para um slide antes do 21
                                swiper.slideTo(traditionalStartIndex, 1000);
                            }
                        } else {
                            if (swiper.activeIndex < traditionalStartIndex) {
                                // Se estivermos no caminho tradicional e tentarem ir para um slide antes do 21
                                swiper.slideTo(traditionalStartIndex, 1000);
                            }
                        }

                    }

                    if (inputType === 'default' && !resumingSession) {

                        if (isSubmitted && swiper.activeIndex < 2) {
                            swiper.slideTo(3, 1000);
                        }

                    } else {

                        if (isSubmitted && swiper.activeIndex < 1) {
                            swiper.slideTo(2, 1000);
                        }
                    }
                }}

                navigation
                pagination={{ clickable: true }}
                className="mySwiper"
                slidesPerView={1}
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                    "--swiper-navigation-size": "20px"
                }}

            /*        onSwiper={(swiper) => setSwiperRef(swiper)}
                    allowSlideNext={isSubmitted} // Só permite navegar para o próximo se isSubmitted for true
                    //allowSlidePrev={false} // Desabilita voltar para o slide anterior
                    onSlideChange={(swiper) => {
                        let traditionalStartIndex;
                        let resumingStartIndex;
    
                        if (inputType === 'default') {
                            traditionalStartIndex = 2;
                            resumingStartIndex = 1;
                        } else {
                            traditionalStartIndex = 1;
                            resumingStartIndex = 0;
                        }
    
                        if (resumingSession) {
                            if (!selectedOutcome) {
                                if (swiper.activeIndex < resumingStartIndex) {
                                    swiper.slideTo(resumingStartIndex, 1000);
                                }
                            } else {
                                if (!selectedCarousel) {
                                    traditionalStartIndex = 2;
                                    if (swiper.activeIndex < traditionalStartIndex && selectedHeadline) {
                                        swiper.slideTo(traditionalStartIndex, 1000);
                                    }
                                } else {
                                    traditionalStartIndex = 1;
                                    if (swiper.activeIndex < traditionalStartIndex) {
                                        swiper.slideTo(traditionalStartIndex, 1000);
                                    }
                                }
                            }
                        } else {
                            if (inputType === 'default') {
                                if (swiper.activeIndex < traditionalStartIndex && selectedHeadline) {
                                    swiper.slideTo(traditionalStartIndex, 1000);
                                }
                            } else {
                                if (swiper.activeIndex < traditionalStartIndex) {
                                    swiper.slideTo(traditionalStartIndex, 1000);
                                }
                            }
                        }
    
                        if (inputType === 'default' && !resumingSession) {
                            if (isSubmitted && swiper.activeIndex < 2) {
                                swiper.slideTo(3, 1000);
                            }
                        } else {
                            if (isSubmitted && swiper.activeIndex < 1) {
                                swiper.slideTo(2, 1000);
                            }
                        }
                    }}
                    navigation
                    pagination={{ clickable: true }}
                    className="mySwiper"
                    slidesPerView={1}
                    style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                        "--swiper-navigation-size": "20px"
                    }} */


            >
                {/*renderSlides()}
            {selectedNiche && ( */
                    <SwiperSlide>
                        <UserInfo userName={userName} credits={credits} userId={userId} />

                        <div className="image-container_01">
                            <MdMenu
                                className="menu-icon"
                                onClick={handleHamburguerClick}
                            />
                        </div>
                        <ImageComponent imageUrl={"https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"} />
                        <div className="firstEnter">
                            <ChakraProvider>
                                <Tabs variant='enclosed' colorScheme='purple' className="tabsContainerEnter">
                                    {typeofPlan !== "Starter" && (
                                        <TabList className="tabsListEnter">
                                            <Tab _selected={{ color: '#8E59FF' }} color="#757575" >{t('main', 'criation')}</Tab>

                                            <Tab _selected={{ color: '#8E59FF' }} color="#757575" >{t('main', 'inspiration')}</Tab>
                                        </TabList>
                                    )}
                                    <TabPanels>
                                        <TabPanel key="main">
                                            <div className="publicContainer" >
                                                <div style={{ display: 'flex', marginLeft: 10 }}>
                                                    <label className="categoryTitle-box">
                                                        {t('main', 'inputInfo')}
                                                    </label>
                                                </div>
                                                <ChakraProvider>
                                                    <RadioGroup onChange={setInputType} value={inputType}>
                                                        <Stack
                                                            spacing={{ base: 4, md: 12 }}
                                                            direction='row'
                                                            ml={{ base: 2, md: 2 }}  // Ajusta a margem à esquerda (ml)
                                                            mb={4}  // Margem na parte inferior (mb)
                                                        >
                                                            <Radio value="default" colorScheme="purple">
                                                                <span className="default" style={{ color: '#757575' }}>{t('main', 'defaultModel')}</span>
                                                            </Radio>

                                                            {/* <Radio value="customText" colorScheme="purple">
                                                                <span className="customText" style={{ color: '#757575' }}>{t('main', 'customText')}</span>
                                                            </Radio> */}

                                                            <Radio value="fromTemplate" colorScheme="purple">
                                                                <span style={{ color: '#757575' }}>{t('main', 'postTemplate')}</span>
                                                            </Radio>

                                                        </Stack>
                                                    </RadioGroup>
                                                </ChakraProvider>

                                                <ChakraProvider>
                                                    <Center width='97%'>
                                                        <Divider className="dividerFirst" />
                                                    </Center>
                                                </ChakraProvider>

                                                {inputType === 'default' && (
                                                    <>

                                                        <ChakraProvider>

                                                            <Stack className="stkBegin" spacing={4} direction='row' paddingRight="10px">
                                                                {/* Condicionalmente renderiza o Checkbox PCI somente se o plano não for "Starter" */}
                                                                {typeofPlan !== "Starter" && (
                                                                    <Checkbox
                                                                        size='lg'
                                                                        colorScheme='purple'
                                                                        isChecked={isCheckedPCI}
                                                                        onChange={handleCheckboxChangePCI}
                                                                        isDisabled={isCheckboxDisabledPCI}
                                                                        className="checkBoxEnter"
                                                                    >
                                                                        <Text color="#757575" className="textChack" fontSize={{ base: "sm", md: "md" }}>
                                                                            {t('main', 'yourPublic')}
                                                                        </Text>
                                                                    </Checkbox>
                                                                )}
                                                                <Select
                                                                    className='slcSlides'
                                                                    color={'#757575'}
                                                                    size='sm'
                                                                    marginLeft={typeofPlan === "Starter" ? { base: '0px' } : { base: '60px' }}
                                                                    width={{ base: '160px', md: '140px' }}
                                                                    // ml={{ base: -4, md: 16 }}
                                                                    ml={typeofPlan === "Starter" ? { base: '0px', md: '0px' } : 'auto'}
                                                                    value={selectedSlides}
                                                                    onChange={handleSlideChange}
                                                                    placeholder={t('main', 'NSlides')}
                                                                >
                                                                    <option value="3">3</option>
                                                                    <option value="5">5</option>
                                                                    <option value="7">7</option>
                                                                    <option value="10">10</option>
                                                                </Select>
                                                            </Stack>

                                                        </ChakraProvider>
                                                        <label className="labelTopicFirst" style={{ color: '#757575', marginLeft: 10 }}>
                                                            {t('main', 'targetAudience')}
                                                            <input
                                                                type="text"
                                                                onChange={(e) => handleInputChange(e, 'targetAudience')}
                                                                value={targetAudience}
                                                                className='inputFocusFirst'
                                                                placeholder={t('main', 'placeholderAudienceExamples')}

                                                            />
                                                        </label>
                                                        <ChakraProvider>
                                                            <Center width='97%'>
                                                                <Divider className="dividerFirst" />
                                                            </Center>
                                                        </ChakraProvider>
                                                        <label className="labelTopic topic" style={{ color: '#757575', marginLeft: 10 }}>
                                                            {t('main', 'topic')}
                                                            <input
                                                                type="text"
                                                                className='inputFocusFirst'
                                                                onChange={(e) => handleInputChange(e, 'topic')}
                                                                value={topic}
                                                                placeholder={t('main', 'placeholderTopicExamples')}
                                                            />
                                                        </label>


                                                    </>
                                                )}

                                                {inputType === 'fromTemplate' && (
                                                    <>
                                                        <ChakraProvider>

                                                            <Stack className="stkBegin" spacing={4} direction='row' paddingRight="10px">


                                                                {typeofPlan !== "Starter" && (
                                                                    <Checkbox
                                                                        size='lg'
                                                                        colorScheme='purple'
                                                                        isChecked={isCheckedPCI}
                                                                        onChange={handleCheckboxChangePCI}
                                                                        isDisabled={isCheckboxDisabledPCI}
                                                                        className="checkBoxEnter"
                                                                    >
                                                                        <Text color="#757575" className="textChack" fontSize={{ base: "sm", md: "md" }}>
                                                                            {t('main', 'yourPublic')}
                                                                        </Text>
                                                                    </Checkbox>
                                                                )}

                                                                {/* Display labels for Carrossel and Stories */}

                                                                {/* Switch Component */}

                                                                <Select

                                                                    className='slcSlides'
                                                                    color={'#757575'}
                                                                    size='sm'
                                                                    marginLeft={typeofPlan === "Starter" ? { base: '0px' } : { base: '60px' }}
                                                                    width={{ base: '160px', md: '140px' }}
                                                                    // ml={{ base: -4, md: 16 }}
                                                                    ml={typeofPlan === "Starter" ? { base: '0px', md: '0px' } : 'auto'}
                                                                    value={selectedSlides}
                                                                    onChange={handleSlideChange}
                                                                    placeholder={t('main', 'NSlides')}

                                                                >
                                                                    {contentType === 'carousel' ? (
                                                                        <>
                                                                            <option value="7">7</option>
                                                                            <option value="10">10</option>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {/*  <option value="3">3</option> */}
                                                                            <option value="5">5</option>
                                                                        </>
                                                                    )}
                                                                </Select>
                                                            </Stack>

                                                            <label className="labelTopicFirst" style={{ color: '#757575', marginLeft: 10 }}>
                                                                {t('main', 'targetAudience')}
                                                                <input
                                                                    type="text"
                                                                    onChange={(e) => handleInputChange(e, 'targetAudience')}
                                                                    value={targetAudience}
                                                                    className='inputFocusFirst'
                                                                    placeholder={t('main', 'placeholderAudienceExamples')}

                                                                />
                                                            </label>

                                                            <ChakraProvider>
                                                                <Center width='97%'>
                                                                    <Divider className="dividerFirst" />
                                                                </Center>
                                                            </ChakraProvider>

                                                            <label className="labelTopicFirst" style={{ color: '#757575', marginLeft: 10 }}>
                                                                {t('main', 'defineCarouselType')}
                                                            </label>
                                                            <Stack className="stkBegin mobile-zero-margin" spacing={2} direction='row' paddingleft="10px" marginTop={2}>
                                                                <RadioGroup onChange={setContentType} value={contentType}>
                                                                    <Stack direction="row" spacing={6}>
                                                                        <Radio value="carousel" colorScheme="purple">
                                                                            <span style={{ color: '#757575', fontSize: 16 }}> {t('main', 'carrossel')}</span>
                                                                        </Radio>
                                                                        <Radio value="stories" colorScheme="purple">
                                                                            <span style={{ color: '#757575', fontSize: 16 }}>{t('main', 'stories')}</span>
                                                                        </Radio>
                                                                    </Stack>
                                                                </RadioGroup>
                                                            </Stack>
                                                        </ChakraProvider>

                                                        {/*   <label className="labelTopic" style={{ color: '#757575', marginLeft: 10 }}>
                                                            {t('main', 'targetAudience')}
                                                            <input
                                                                type="text"
                                                                onChange={(e) => handleInputChange(e, 'targetAudience')}
                                                                value={targetAudience}
                                                                className='inputFocusFirst'
                                                                placeholder={t('main', 'placeholderAudienceExamples')}
                                                            />
                                                        </label>
                                                        <ChakraProvider>
                                                            <Center width='97%'>
                                                                <Divider className="dividerFirst" />
                                                            </Center>
                                                        </ChakraProvider> 

                                                         <label className="labelTopic" style={{ color: '#757575', marginLeft: 10 }}>
                                                            {t('main', 'topic')}
                                                            <input
                                                                type="text"
                                                                className='inputFocusFirst'
                                                                onChange={(e) => handleInputChange(e, 'topic')}
                                                                value={topic}
                                                                placeholder={t('main', 'placeholderTopicExamples')}
                                                            /> 
                                                                </label>  */}

                                                    </>
                                                )}
                                                {/*  {inputType === 'customText' && (
                                                    <>
                                                        <ChakraProvider>

                                                            <Stack className="stkBegin" spacing={4} direction='row'>
                                                                <Select
                                                                    className='slcSlides'
                                                                    size='sm'
                                                                    color={'#757575'}
                                                                    marginLeft={{ base: '60px' }}
                                                                    width={{ base: '130px', md: '140px' }}
                                                                    ml={{ base: 0, md: 0 }}
                                                                    value={selectedSlides}
                                                                    onChange={handleSlideChange}
                                                                    placeholder={t('main', 'NSlides')}
                                                                >
                                                                    <option value="3">3</option>
                                                                    <option value="5">5</option>
                                                                    <option value="7">7</option>
                                                                    <option value="10">10</option>
                                                                </Select>
                                                            </Stack>

                                                        </ChakraProvider>
                                                        <label className="labelTopicFirst" style={{ color: '#757575', marginLeft: 10 }}>
                                                            {t('main', 'defineCarouselTitle')}
                                                            <input
                                                                type="text"
                                                                className='inputFocusFirst'
                                                                onChange={(e) => handleInputChange(e, 'inputTitle')}
                                                                value={inputTitle}
                                                                placeholder={t('main', 'placeholderExampleCarouselTitle')}
                                                            />
                                                        </label>
                                                        <ChakraProvider>
                                                            <Center width='97%'>
                                                                <Divider className="dividerFirst" />
                                                            </Center>
                                                        </ChakraProvider>
                                                        <label className="labelTopic" style={{ color: '#757575', marginLeft: 10 }}>
                                                            {t('main', 'insertCarouselText')}
                                                            <textarea
                                                                className='inputFocusAr'
                                                                onChange={(e) => handleInputChange(e, 'inputText')}
                                                                value={inputText}
                                                                placeholder={t('main', 'placeholderExampleCarouselText')}
                                                                style={{ width: '96%', minHeight: '70px' }} // Ajuste o tamanho conforme necessário
                                                            />
                                                        </label>

                                                    </>
                                                )} */}
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 10, marginBottom: '10px', marginTop: '20px' }}>
                                                    <button
                                                        style={{ backgroundColor: '#8E59FF', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', width: isMobile ? '52%' : '35%' }}
                                                        className='googleButtonNew createBtn'
                                                        onClick={() => {
                                                            if (inputType === 'fromTemplate') {
                                                                // Se for 'fromTemplate', a lógica de acordo com contentType é aplicada
                                                                if (contentType === 'carousel') {
                                                                    confirmSubmit(inputType, '1');  // Envia como carrossel
                                                                } else if (contentType === 'stories') {
                                                                    confirmSubmit('stories', '1');  // Envia como stories
                                                                }
                                                            } else {
                                                                // Para outros tipos de input, o comportamento padrão
                                                                confirmSubmit(inputType, '1');
                                                            }
                                                        }}
                                                    >
                                                        <MdBolt className="buttonIcon" />
                                                        <span className="buttonContent">
                                                            {inputType === 'fromTemplate'
                                                                ? (contentType === 'carousel' ? t('main', 'sendCarousel') : t('main', 'sendStories'))
                                                                : t('main', 'sendCarousel')} {/* Aqui você pode colocar um texto padrão para os outros inputTypes */}
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            {/* <div className="btnPromo" hidden={!isNotProductionEnvironment()} >
                                            <button
                                             style={{ backgroundColor: 'white', color: '#8E59FF', width: t('main', 'subscribeNow').length > 22 ? '235px' : '220px', border: '2px solid #8E59FF' }}
                                             className='googleButtonNew'
                                             onClick={handleRedirect}
                                             >
                                            <MdFavorite className="buttonIcon" />
                                            <span className="buttonContent" >{t('main', 'subscribeNow')}</span>
                                            </button>
                                         </div>  */}
                                        </TabPanel>
                                        {/* Condicionalmente renderiza o conteúdo da aba "Stories" somente se o plano não for "Starter" */}
                                        {typeofPlan !== "Starter" && (
                                            <TabPanel key='stories'>
                                                <div className="publicContainer" >
                                                    <div style={{ display: 'flex', marginLeft: 10 }}>
                                                        <label className="categoryTitle-box">
                                                            {t('main', 'inputInfo')}
                                                        </label>
                                                    </div>
                                                    <ChakraProvider>

                                                        <RadioGroup onChange={setInputTypeTwo} value={inputTypeTwo}>
                                                            <Stack
                                                                spacing={{ base: 4, md: 12 }}
                                                                direction='row'
                                                                ml={{ base: 2, md: 2 }}  // Ajusta a margem à esquerda (ml)
                                                                mb={4}  // Margem na parte inferior (mb)
                                                                className="stkBegin mobile-zero-margin"
                                                            >
                                                                <Radio value="youtube" colorScheme="purple">
                                                                    <span className="youtube" style={{ color: '#757575' }}>{t('main', 'youtubeVideo')}</span>
                                                                </Radio>

                                                                <Radio value="customText" colorScheme="purple">
                                                                    <span className="customText" style={{ color: '#757575' }}>{t('main', 'customText')}</span>
                                                                </Radio>

                                                            </Stack>
                                                        </RadioGroup>

                                                    </ChakraProvider>

                                                    <ChakraProvider>
                                                        <Center width='97%'>
                                                            <Divider className="dividerFirstStry" />
                                                        </Center>
                                                    </ChakraProvider>

                                                    {/* <ChakraProvider>
                                                        <Stack className="stkBegin" spacing={4} direction='row' paddingRight="10px">
                                                            <Checkbox
                                                                size='lg'
                                                                colorScheme='purple'
                                                                isChecked={isCheckedPCI}
                                                                onChange={handleCheckboxChangePCI}
                                                                isDisabled={isCheckboxDisabledPCI}
                                                                className="checkBoxEnter"
                                                            >
                                                                <Text color="#757575" className="textChack" fontSize={{ base: "sm", md: "md" }}>
                                                                    {t('main', 'yourPublic')}
                                                                </Text>
                                                            </Checkbox>
                                                            <Select
                                                                className='slcSlides'
                                                                size='sm'
                                                                color={'#757575'}
                                                                marginLeft={{ base: '60px' }}
                                                                width={{ base: '160px', md: '140px' }}
                                                                ml={{ base: -4, md: 16 }}
                                                                value={selectedSlidesStories}
                                                                onChange={handleSlideChangeStories}
                                                                placeholder={t('main', 'NSlides')}
                                                            >
                                                                <option value="3">3</option>
                                                                <option value="5">5</option>
                                                            </Select>
                                                        </Stack>
                             </ChakraProvider> */}



                                                    {inputTypeTwo === 'youtube' && (
                                                        <>

                                                            <ChakraProvider>

                                                                <Stack className="stkBegin" spacing={4} direction='row'>
                                                                    <Select
                                                                        className='slcSlides'
                                                                        size='sm'
                                                                        color={'#757575'}
                                                                        marginLeft={{ base: '60px' }}
                                                                        width={{ base: '130px', md: '140px' }}
                                                                        ml={{ base: 0, md: 0 }}
                                                                        value={selectedSlides}
                                                                        onChange={handleSlideChange}
                                                                        placeholder={t('main', 'NSlides')}
                                                                    >
                                                                        <option value="3">3</option>
                                                                        <option value="5">5</option>
                                                                        <option value="7">7</option>
                                                                        <option value="10">10</option>
                                                                    </Select>
                                                                </Stack>

                                                            </ChakraProvider>

                                                            <label className="labelTopicFirst" style={{ color: '#757575', marginLeft: 10 }}>
                                                                {t('main', 'defineCarouselTitle')}
                                                                <input
                                                                    type="text"
                                                                    className='inputFocusFirst'
                                                                    onChange={(e) => handleInputChange(e, 'inputTitle')}
                                                                    value={inputTitle}
                                                                    placeholder={t('main', 'placeholderExampleCarouselTitle')}
                                                                />
                                                            </label>
                                                            <ChakraProvider>
                                                                <Center width='97%'>
                                                                    <Divider className="dividerFirst" />
                                                                </Center>
                                                            </ChakraProvider>
                                                            <label className="labelTopic" style={{ color: '#757575', marginLeft: 10 }}>
                                                                {t('main', 'insertValidYoutubeLink')}
                                                                <input
                                                                    type="text"
                                                                    className='inputFocusFirst'
                                                                    onChange={(e) => handleInputChange(e, 'youtubeUrl')}
                                                                    value={youtubeUrl}
                                                                    placeholder={t('main', 'placeholderYouTubeURL')}
                                                                />
                                                            </label>
                                                        </>
                                                    )}

                                                    {inputTypeTwo === 'customText' && (
                                                        <>

                                                            <ChakraProvider>

                                                                <Stack className="stkBegin" spacing={4} direction='row' paddingRight="10px">

                                                                    <Checkbox
                                                                        size='lg'
                                                                        colorScheme='purple'
                                                                        isChecked={isCheckedPart}
                                                                        onChange={handleCheckboxChangePart}

                                                                        className="checkBoxEnter"
                                                                    >
                                                                        <Text color="#757575" className="textChack" fontSize={{ base: "sm", md: "md" }}>
                                                                            {t('main', 'partiText')}
                                                                        </Text>
                                                                    </Checkbox>


                                                                    <Select
                                                                        className='slcSlides'
                                                                        color={'#757575'}
                                                                        size='sm'
                                                                        marginLeft={typeofPlan === "Starter" ? { base: '0px' } : { base: '60px' }}
                                                                        width={{ base: '160px', md: '140px' }}
                                                                        // ml={{ base: -4, md: 16 }}
                                                                        ml={typeofPlan === "Starter" ? { base: '0px', md: '0px' } : 'auto'}
                                                                        value={selectedSlides}
                                                                        onChange={handleSlideChange}
                                                                        placeholder={t('main', 'NSlides')}
                                                                    >
                                                                        <option value="3">3</option>
                                                                        <option value="5">5</option>
                                                                        <option value="7">7</option>
                                                                        <option value="10">10</option>
                                                                    </Select>
                                                                </Stack>

                                                            </ChakraProvider>
                                                            <label className="labelTopicFirst" style={{ color: '#757575', marginLeft: 10 }}>
                                                                {t('main', 'defineCarouselTitle')}
                                                                <input
                                                                    type="text"
                                                                    className='inputFocusFirst'
                                                                    onChange={(e) => handleInputChange(e, 'inputTitle')}
                                                                    value={inputTitle}
                                                                    placeholder={t('main', 'placeholderExampleCarouselTitle')}
                                                                />
                                                            </label>
                                                            <ChakraProvider>
                                                                <Center width='97%'>
                                                                    <Divider className="dividerFirst" />
                                                                </Center>
                                                            </ChakraProvider>
                                                            <label className="labelTopic" style={{ color: '#757575', marginLeft: 10 }}>
                                                                {t('main', 'insertCarouselText')}
                                                                <textarea
                                                                    className='inputFocusAr'
                                                                    onChange={(e) => handleInputChange(e, 'inputText')}
                                                                    value={inputText}
                                                                    placeholder={t('main', 'placeholderExampleCarouselText')}
                                                                    style={{ width: '96%', minHeight: '70px' }} // Ajuste o tamanho conforme necessário
                                                                />
                                                            </label>

                                                        </>
                                                    )}

                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 10, marginBottom: '10px' }}>
                                                        <button
                                                            style={{ backgroundColor: '#8E59FF', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', width: isMobile ? '52%' : '35%' }}
                                                            className='googleButtonNew'
                                                            onClick={() => { confirmSubmit(inputTypeTwo, '1'); setInputType('inspiration'); }}
                                                        >
                                                            <MdBolt className="buttonIcon" />
                                                            <span className="buttonContent" >{t('main', 'sendCarousel')}</span>
                                                        </button>
                                                    </div>

                                                </div>

                                            </TabPanel>
                                        )}
                                    </TabPanels>
                                </Tabs>
                            </ChakraProvider>
                        </div>
                    </SwiperSlide>
           /* ) */}
                {isLoadingOutcomes ? (

                    <SwiperSlide className="blink">

                        {isFetchingTemplates ? (isStories ? t('main', 'loadingTemplates') : t('main', 'loadingTemplatesCarousel')) : t('main', 'loadingBestThemes')}

                    </SwiperSlide>

                ) : (
                    outcomes.length > 0 && (
                        <SwiperSlide >
                            <UserInfo userName={userName} credits={credits} userId={userId} />
                            <div className="image-container_01">
                                <MdMenu className="menu-icon" onClick={handleHamburguerClick} />
                            </div>
                            <ImageComponent imageUrl={"https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"} />

                            <div className="divMaster">
                                <h2 className="categoryTitleTema">
                                    {outcomes[0]?.id ? (isStories ? t('main', 'selectTemplate') : t('main', 'selectTemplateCarousel')) : t('main', 'selectTheme')}
                                </h2>
                                <div className="outcome-grid-container">
                                    {outcomes.map((item, index) => (
                                        item.id ? (
                                            <button
                                                key={index}
                                                className={`outcome-button ${item ? '' : 'second-button'}`}
                                                onClick={() => handleTemplateEdit(item, startNewCarouselSession, handleTonePress)}

                                            // })}
                                            >
                                                <div className="button-content">
                                                    <span className="emoji">{item.emoji}</span>
                                                    <div className="text-content">
                                                        <span className="headlines-section__title">{item.title}</span>
                                                        <p className="headlines-section__text">{item.summary}</p>
                                                    </div>
                                                </div>
                                            </button>
                                        ) : (
                                            <button
                                                key={index}
                                                className={`outcome-button ${item ? '' : 'second-button'}`}
                                                onClick={() => handleSubmit(item)}
                                            >
                                                <div className="text-content">

                                                    <span className="headlines-section__title">{t('main', 'theme') + parseInt(index + 1)}</span>
                                                    <p className="headlines-section__text">{item}</p>
                                                </div>
                                            </button>
                                        )
                                    ))}
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                )}
                {isLoading ? (
                    <SwiperSlide className="blink">{t('main', 'loadingBestTitles')}</SwiperSlide>
                ) : headlines.length > 0 && (

                    /* headlines.map((headline, index) => (
                          <SwiperSlide key={index}>
                              <UserInfo userName={userName} credits={credits} userId={userId} />
                              <div className="image-container_01">
                                  <MdMenu
                                      className="menu-icon"
                                      onClick={handleHamburguerClick}
                                  />
                              </div>
                              <ImageComponent imageUrl={"https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"} />
                              <div className="textContainer ">
                                  <p className="textHeadline">{headline}</p>
                                  <button
                                      className='selectButton'
                                      onClick={() => handleHeadlineSelect(index)}
                                  >
                                      {t('main', 'choose')}
                                  </button>
                              </div>
                          </SwiperSlide>
                      )) */

                    <SwiperSlide>
                        <UserInfo userName={userName} credits={credits} userId={userId} />
                        <div className="image-container_01">
                            <MdMenu
                                className="menu-icon"
                                onClick={handleHamburguerClick}
                            />
                        </div>
                        <ImageComponent imageUrl={"https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"} />
                        <div className="divMaster">
                            <h2 className="categoryTitleTema">
                                {t('main', 'selectHeadline')}
                            </h2>
                            <div className="headline-grid-container">
                                {headlines.map((headline, index) => (
                                    <button
                                        key={index}
                                        className={`headline-button ${headline ? '' : 'second-button'}`}
                                        onClick={() => handleHeadlineSelect(index)}
                                    >
                                        <div className="headlines-section__content">
                                            <span className="headlines-section__title">{t('main', 'headline') + parseInt(index + 1)}</span>
                                            <p className="headlines-section__text">{headline}</p>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </SwiperSlide>
                )}

                {isLoadingCarousel ? (
                    <SwiperSlide className="blink">{fraseCarousel}</SwiperSlide>
                ) : (
                    carousels && carousels.length > 0 &&
                    <SwiperSlide>
                        <UserInfo userName={userName} credits={credits} userId={userId} />
                        <div className="image-container_01">
                            <MdMenu
                                className="menu-icon"
                                onClick={handleHamburguerClick}
                            />
                        </div>
                        <ImageComponent imageUrl={"https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"} />
                        <div className="divTextArea">
                            {!isEditing ? (
                                <>
                                    <textarea readOnly value={carousels.replace(/<\/?p>/g, '')} className="textAreaSpace" />
                                    <div className="buttonGroup">
                                        <button className="googleButtonTxt buttonStyle" onClick={handleEdit}><MdEdit className="buttonIcon" /><span className="buttonContent">{t('main', 'edit')}</span></button>
                                        <button className="googleButtonTxt buttonStyle" onClick={() => copyToClipboard(carousels)}><MdCopyAll className="buttonIcon" /><span className="buttonContent">{t('main', 'copy')}</span></button>
                                        <button className="googleButtonTxt buttonStyle" style={{ backgroundColor: '#8E59FF', color: 'white' }} onClick={modelSelect}><MdBolt className="buttonIcon" /><span className="buttonContent">{t('main', 'advance')}</span></button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="slidesContainer">
                                        {textParts.map((part, index) => (
                                            <div key={index} className="editable-slide">
                                                <label style={{ color: '#757575', fontSize: 16, marginLeft: isMobile ? 10 : 40 }} htmlFor={`textArea-${index}`}>{part.prefix}</label>
                                                <textarea
                                                    id={`textArea-${index}`}
                                                    value={part.suffix}
                                                    onChange={(e) => handleTextChange(index, e.target.value)}
                                                    className="textAreaSpaceDin"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <p style={{
                                        fontSize: isMobile ? '12px' : '14px',
                                        color: '#666',
                                        marginTop: isMobile ? '0px' : '0px',
                                        marginLeft: isMobile ? '-20px' : '20px',
                                        marginRight: isMobile ? '-20px' : '20px',
                                        fontStyle: 'italic'
                                    }}
                                        dangerouslySetInnerHTML={{ __html: t('main', 'Obs_1') }}
                                    >
                                        {/* <strong> Obs: </strong> a tag <code>&lt;p&gt;&lt;/p&gt;</code> serve para deixar o trecho do texto em destaque no carrossel.
                                    Ex: Hoje o sol <code>&lt;p&gt;brilhou forte&lt;/p&gt;</code> = Hoje o sol <strong>brilhou forte</strong> */}

                                    </p>
                                    <div className="buttonGroupSave">
                                        <button className="googleButton buttonStyle" onClick={handleSave} style={{ backgroundColor: '#8E59FF', color: 'white' }}> <MdSave className="buttonIcon" /> <span className="buttonContent">{t('main', 'save')}</span></button>
                                    </div>
                                </>
                            )}
                            <div className="footer-btn-wrapper">
                                <button onClick={() => refreshPage()}>
                                    <span className="buttonContent" > <MdRestartAlt className="buttonIcon" /> {t('main', 'goBack')}</span> </button>
                            </div>
                        </div>
                    </SwiperSlide>
                )}
                {isCarouselType && shouldShowStylePrompt ? (
                    <SwiperSlide className="blink"> {isStories ? t('main', 'defineStoriesStyle') : t('main', 'defineCarouselStyle')}</SwiperSlide>
                ) : isCarouselType && !shouldShowStylePrompt ? (
                    <SwiperSlide>
                        <UserInfo userName={userName} credits={credits} userId={userId} />
                        <div className="image-container_01">
                            <MdMenu
                                className="menu-icon"
                                onClick={handleHamburguerClick}
                            />
                        </div>
                        <ImageComponent imageUrl={"https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"} />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                            {!isStories && (
                                <h2 className="catDesing">
                                    Selecione o Template do Carrossel
                                </h2>
                            )}

                            <div className={`carouselBox ${isStories ? 'carouselBoxStories' : 'nonStoriesII'}`}>

                                {isStories && (
                                    <h2 className="categoryTitle">
                                        {t('main', 'selectStyleStories')}
                                    </h2>
                                )}
                                <div className="carouselContent">
                                    <div className={`previewImage ${isStories ? 'previewImageStories' : 'nonStories'}`}>
                                        {isStories ? (
                                            <img src={imageUrl} alt="Preview" />
                                        ) : (
                                            <TemplateGrid
                                                setSelectedFormat={setSelectedFormat}
                                                selectedFormat={selectedFormat}
                                            />
                                        )}
                                    </div>

                                    <div className="selectionArea">

                                        {!isStories ? (
                                            <>
                                                {/* <div className="previewContainer">
                                                    <div className="previewContent"
                                                        style={{
                                                            backgroundColor: previewColors.backgroundColor
                                                        }}
                                                    >
                                                        <div className="postPreview">
                                                            <div className="titleWithIcon">
                                                                <h4 style={{
                                                                    color: previewColors.textColor
                                                                }}>
                                                                    Título do seu Post
                                                                </h4>

                                                                <MdRemoveRedEye
                                                                    className="iconEye"
                                                                    style={{
                                                                        color: previewColors.textColor
                                                                    }}
                                                                />
                                                            </div>
                                                            <p style={{
                                                                color: previewColors.secondaryText
                                                            }}>
                                                                Texto do seu Post.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <Tabs variant="enclosed" className="tabSilverDesign" colorScheme="purple">
                                                    <TabList className="tablist_control_design">
                                                        <Tab className="tab_control_design">Padrão</Tab>
                                                        <Tab className="tab_control_design">Estilo Próprio</Tab>
                                                    </TabList>
                                                    <TabPanels className="tabPanels_control_design">
                                                        <TabPanel className="tabpanel_control_design">
                                                            <div className="colorGroup">
                                                                <ColorPalette
                                                                    onCombinationSelect={handlePreviewColorSelection} // Usando a nova função
                                                                    selectedComboId={selectedComboId}
                                                                    colorCombinations={colorCombinations}
                                                                />
                                                            </div>
                                                        </TabPanel>
                                                        <TabPanel className="tabpanel_control_design">
                                                            <div className="style-customization-container">
                                                                
                                                                <div className="color-section">
                                                                    <div className="header-with-checkbox">
                                                                        <label className="style-label">Paleta de Cores</label>
                                                                    </div>
                                                                    <div className="color-grid">
                                                                        <div className="color-item">
                                                                            <span className="color-name">Títulos</span>
                                                                            <div
                                                                                className="color-picker"
                                                                                style={{ backgroundColor: colors.Titulos }}
                                                                                onClick={() => openColorPicker('Titulos')}
                                                                            />
                                                                        </div>
                                                                        <div className="color-item">
                                                                            <span className="color-name">Textos</span>
                                                                            <div
                                                                                className="color-picker"
                                                                                style={{ backgroundColor: colors.Textos }}
                                                                                onClick={() => openColorPicker('Textos')}
                                                                            />
                                                                        </div>
                                                                        <div className="color-item">
                                                                            <span className="color-name">Fundo</span>
                                                                            <div
                                                                                className="color-picker"
                                                                                style={{ backgroundColor: colors.Fundo }}
                                                                                onClick={() => openColorPicker('Fundo')}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="font-selection">
                                                                    <div className="font-row">
                                                                        <div className="font-group">
                                                                            <label className="font-label">Título:</label>
                                                                            <select
                                                                                className="font-select"
                                                                                value={titleFont}
                                                                                onChange={(e) => setTitleFont(e.target.value)}
                                                                            >
                                                                                {fontList.map(font => (
                                                                                    <option key={font.value} value={font.value}>{font.name}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="font-group">
                                                                            <label className="font-label">Texto:</label>
                                                                            <select
                                                                                className="font-select"
                                                                                value={textFont}
                                                                                onChange={(e) => setTextFont(e.target.value)}
                                                                            >
                                                                                {fontList.map(font => (
                                                                                    <option key={font.value} value={font.value}>{font.name}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ChakraProvider>
                                                                    <Stack className="stk" spacing={4} direction='row'>
                                                                        <Checkbox
                                                                            size='lg'
                                                                            colorScheme='purple'
                                                                            isChecked={saveAsDefault}
                                                                            onChange={(e) => setSaveAsDefault(e.target.checked)}
                                                                        >
                                                                            <Text color="#757575" fontSize="sm">
                                                                                Usar este estilo como padrão
                                                                            </Text>
                                                                        </Checkbox>
                                                                    </Stack>
                                                                </ChakraProvider>
                                                            </div>

                                                            {showColorPicker && (
                                                                <ChakraProvider>
                                                                    <Modal isOpen={showColorPicker} onClose={() => setShowColorPicker(false)}>
                                                                        <ModalOverlay />
                                                                        <ModalContent>
                                                                            <ModalBody display="flex" justifyContent="center">
                                                                                <SketchPicker color={selectedColor} onChange={handleColorChange} />
                                                                            </ModalBody>
                                                                            <ModalFooter>
                                                                                <Button onClick={handleConfirmColor} sx={{
                                                                                    backgroundColor: '#8E59FF',
                                                                                    color: 'white',
                                                                                }}>Confirmar</Button>
                                                                            </ModalFooter>
                                                                        </ModalContent>
                                                                    </Modal>
                                                                </ChakraProvider>
                                                            )}
                                                        </TabPanel>
                                                    </TabPanels>
                                                        </Tabs> */}
                                                <StyleCustomization
                                                    fontList={fontList}
                                                    initialColors={{
                                                        Titulos: colors.Titulos,
                                                        Textos: colors.Textos,
                                                        Fundo: colors.Fundo
                                                    }}
                                                    initialFonts={{
                                                        titleFont: titleFont || fontList[0].value,
                                                        textFont: textFont || fontList[0].value
                                                    }}
                                                    colorCombinations={colorCombinations}
                                                    onStyleChange={handleStyleChange}
                                                    setStyleConfig={setStyleConfig}

                                                />
                                            </>
                                        ) : (
                                            <>
                                                <div className="colorGroupStories">
                                                    <ColorPaletteStories
                                                        onCombinationSelect={handleColorSelection}
                                                        selectedComboId={selectedComboId}
                                                        colorCombinations={colorCombinationsStories}
                                                    />
                                                </div>
                                                {/*  <div className="containerComboBox">
                                                    <ChakraProvider>
                                                        <Checkbox
                                                            size='lg'
                                                            colorScheme='purple'
                                                            isChecked={isChecked}
                                                            onChange={handleCheckboxChange}
                                                            isDisabled={isCheckboxDisabled}
                                                            width={100}
                                                        >
                                                            <Text color="#757575" fontSize="md">
                                                                {t('main', 'yourStyle')}
                                                            </Text>

                                                        </Checkbox>

                                                    </ChakraProvider>

                                                    <div style={{
                                                        fontSize: isMobile ? '12px' : '14px',
                                                        color: '#757575',
                                                        marginTop: isMobile ? '0px' : '0px',
                                                        marginLeft: isMobile ? '0px' : '0px',
                                                        marginRight: isMobile ? '0px' : '0px',
                                                        fontStyle: 'italic'
                                                    }} dangerouslySetInnerHTML={{ __html: t('main', 'Obs_2') }} >
                                                    </div>
                                                </div> */}
                                            </>
                                        )}

                                        <div className={`btnSelect ${isStories ? 'btnSelectStories' : 'btnNonStories'}`} >

                                            <div className="radioLay">
                                                {!isStories ? (
                                                    <ChakraProvider>
                                                        <RadioGroup defaultValue="sqr" onChange={handleRadioChange}>
                                                            <Stack direction='row'>
                                                                <Radio value="sqr" colorScheme="purple">
                                                                    {isTablet ? '(1:1)' : t('main', 'square')}
                                                                </Radio>
                                                                <Radio value="ret" colorScheme="purple">
                                                                    {isMobile ? '(4:5)' : t('main', 'rectangle')}
                                                                </Radio>
                                                            </Stack>
                                                        </RadioGroup>
                                                    </ChakraProvider>
                                                ) : null}
                                            </div>


                                            <button
                                                // disabled={!selectedFormat}
                                                style={{ backgroundColor: '#8E59FF', color: 'white', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}
                                                className='googleButton'
                                                onClick={() => confirmSubmit('#', '3')}
                                            >
                                                <MdBolt className="buttonIcon" />
                                                <span className="buttonContent" >{t('main', 'advance')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*  <div className="btnPromo2" hidden={!isNotProductionEnvironment()} >
                            <button
                                style={{ backgroundColor: 'white', color: '#8E59FF', width: t('main', 'subscribeNow').length > 22 ? '235px' : '220px', border: '2px solid #8E59FF' }}
                                className='googleButtonNew'
                                onClick={handleRedirect}
                            >
                                <MdFavorite className="buttonIcon" />
                                <span className="buttonContent" >{t('main', 'subscribeNow')}</span>
                            </button>
            </div> */}
                        </div>
                    </SwiperSlide >
                ) : null
                }

                {
                    isLoadingThumbnail ? (
                        <SwiperSlide className="blink">{isStories ? t('main', 'uploadingImagesStories') : t('main', 'uploadingImages')}</SwiperSlide>
                    ) : (
                        sliderStates.slide1.thumbnailImages.length > 0 && (
                            <SwiperSlide>
                                <UserInfo userName={userName} credits={credits} userId={userId} />
                                <div className="image-container_01">
                                    <MdMenu
                                        className="menu-icon"
                                        onClick={handleHamburguerClick}
                                    />
                                </div>
                                <ImageComponent imageUrl={"https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"} />

                                <div className="divAreaImage">
                                    <ChakraProvider>
                                        <Tabs index={tabIndex} onChange={handleTabChange} variant='enclosed' color="#757575" colorScheme='purple' className="tabsContainer">
                                            <TabList className="tabsList">
                                                {renderTabs()}
                                            </TabList>
                                            <TabPanels>
                                                {renderTabPanels()}
                                            </TabPanels>
                                        </Tabs>
                                    </ChakraProvider>
                                    <div className="buttonStepper">
                                        <button className="googleButton" style={{ backgroundColor: '#8E59FF', color: 'white' }} onClick={() => handleImages()} > <span className="buttonContent" > <MdWhatshot className="buttonIcon" /> {t('main', 'createButton')}</span></button>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    )
                }

                {
                    isLoadingImage ? (
                        <SwiperSlide className="blink">{loadingMessage}</SwiperSlide>
                    ) : (
                        images.length > 0 && (
                            <SwiperSlide>
                                <UserInfo userName={userName} credits={credits} userId={userId} />
                                <div className="image-container_01">
                                    <MdMenu
                                        className="menu-icon"
                                        onClick={handleHamburguerClick}
                                    />
                                </div>
                                <ImageComponent imageUrl={"https://blob01instasuccess.blob.core.windows.net/fotos-logos/postSucessoLogo.png"} />
                                <div className="outerContainer" >


                                    <div className={isStories ? "innerContainerStories" : "innerContainer"}>

                                        <div className={isStories ? "floatingButtonsContainerStories" : "floatingButtonsContainer"} >

                                            <button className={"floatingButton"} title={t('main', 'baixar')} onClick={() => carrosselDownload(carouselEdit)}>
                                                <MdDownload className="buttonIcon" />
                                            </button>


                                            {!tokenExpiriedFace && (

                                                <button className="floatingButton" title={t('main', 'agendar')} onClick={() => agendamento(userId, localStorage.getItem('totenId'))}>
                                                    <MdDateRange className="buttonIcon" />
                                                </button>

                                            )}

                                            {!tokenExpiriedFace && (

                                                <button className="floatingButton" title={t('main', 'avaliar')} onClick={() => ratePost()}>
                                                    <MdStarHalf className="buttonIcon" />
                                                </button>

                                            )}

                                        </div>

                                        {images.map((imageUrl, index) => (
                                            <RetryImage
                                                key={index}
                                                src={imageUrl}
                                                alt={`Slide ${index + 1}`}
                                                onLoad={() => console.log('Image loaded')}
                                                className={"slideImage"}
                                                position={index + 1}
                                                carouselEdit={carouselEdit}
                                                isFirst={index === 0}
                                                isStory={isStories}
                                            />
                                        ))}

                                    </div>

                                    {isCaptionVisible && (
                                        <div className="captionContainer">
                                            {isLoadingCap ? (
                                                <div className="loading-text-2 blinking-text">{t('main', 'loadingCaption')}</div>
                                            ) : (
                                                <textarea onChange={handleCaptionChange} onPointerMove={handlePointerMove} style={{ width: '74%', minHeight: '100px' }} value={caption} className="inputFocus" placeholder={t('main', 'insertCaption')} />
                                            )}
                                        </div>
                                    )}
                                    <div className={isStories ? "buttonFinalStories" : "buttonFinal"}>
                                        <button
                                            style={{ backgroundColor: '#CCCCCC', color: 'white' }}
                                            className="lastButton"
                                            onClick={async () => {

                                                let updateTitle = '';
                                                let noUpdateTitle = '';
                                                let editPromptText = '';
                                                let editButtonText = '';

                                                if (designId && canvasUrl) {
                                                    updateTitle = t('main', 'updateDetectedCanva');
                                                    noUpdateTitle = t('main', 'noUpdateDetectedCanva');
                                                    editPromptText = t('main', 'editPromptCanva');
                                                    editButtonText = t('main', 'editCanvaButton');
                                                } else {
                                                    updateTitle = t('main', 'updateDetectedGoogleSlides');
                                                    noUpdateTitle = t('main', 'noUpdateDetectedGoogleSlides');
                                                    editPromptText = t('main', 'editPromptGoogleSlides');
                                                    editButtonText = t('main', 'editGoogleSlidesButton');
                                                }


                                                if (isEditMode) {
                                                    setIsUpdating(true);
                                                    try {

                                                        const checkData = await checkSlides(carouselEdit);

                                                        if (checkData.isModified) {

                                                            Swal.fire({
                                                                icon: 'info',
                                                                title: updateTitle,
                                                                text: t('main', 'editOrUpdate'),
                                                                customClass: {
                                                                    popup: 'my-swal-alert',
                                                                    title: 'myCustomTitle'
                                                                },
                                                                showCancelButton: true,
                                                                confirmButtonText: t('main', 'updateButton'),
                                                                cancelButtonText: editButtonText

                                                            }).then(async (result) => {
                                                                if (result.isConfirmed) {
                                                                    try {
                                                                        updateSlides();
                                                                    } catch (error) {
                                                                        console.error("Erro ao atualizar slides:", error);
                                                                    }
                                                                } else {

                                                                    if (designId && canvasUrl) {

                                                                        window.open(canvasUrl, '_blank');


                                                                    } else {
                                                                        window.open("https://docs.google.com/presentation/d/" + carouselEdit, '_blank');

                                                                    }

                                                                }
                                                            });
                                                        } else {

                                                            toast.info(
                                                                t('main', 'googleSlidesUpdates'), {
                                                                type: toast.TYPE.SUCCESS,
                                                                position: toast.POSITION.BOTTOM_LEFT,
                                                                autoClose: 3000 // Defina um tempo para o toast se fechar automaticamente após alguns segundos
                                                            });

                                                            Swal.fire({
                                                                icon: 'info',
                                                                title: noUpdateTitle, //t('main', 'noUpdateDetected'),
                                                                text: editPromptText, //t('main', 'editPrompt'),
                                                                customClass: {
                                                                    popup: 'my-swal-alert',
                                                                    title: 'myCustomTitle'
                                                                },
                                                                showCancelButton: true,
                                                                confirmButtonText: t('main', 'yes'),
                                                                cancelButtonText: t('main', 'no')
                                                            }).then((result) => {
                                                                if (result.isConfirmed) {
                                                                    // Redireciona para o Google Slides
                                                                    window.open("https://docs.google.com/presentation/d/" + carouselEdit, '_blank');
                                                                }
                                                            });
                                                        }
                                                    } catch (error) {
                                                        console.error("Erro ao verificar:", error);
                                                    } finally {
                                                        setIsUpdating(false);
                                                    }
                                                } else {

                                                    if (await validateCanvaToken(userId)) {

                                                        handleEditChoice();

                                                    } else {

                                                        window.open("https://docs.google.com/presentation/d/" + carouselEdit, '_blank');

                                                    }

                                                    setIsEditMode(true);
                                                }
                                            }}
                                        >
                                            <>
                                                {isUpdating ? (
                                                    // Mostra o ícone de verificação e o texto "Verificando..." quando isUpdating é true
                                                    <>
                                                        <MdOutlineCompareArrows className="buttonIcon" /> {" "}
                                                        <span style={{ marginLeft: '5px' }}> {t('main', 'verify')} </span>
                                                    </>
                                                ) : isEditMode ? (
                                                    // Mostra o ícone de edição e o texto "Verificar" quando isEditMode é true
                                                    <>
                                                        <MdOutlineCompareArrows className="buttonIcon" /> {" "}
                                                        <span style={{ marginLeft: '5px' }}> {t('main', 'verify')} </span>
                                                    </>
                                                ) : (
                                                    // Mostra o ícone de edição e o texto "Editar" como estado padrão
                                                    <>
                                                        <MdEdit className="buttonIcon" /> {" "}
                                                        <span style={{ marginLeft: '5px' }}> {t('main', 'edit')} </span>
                                                    </>
                                                )}
                                            </>
                                        </button>
                                        <button style={{ backgroundColor: '#CCCCCC', color: 'white', display: isStories ? 'none' : '' }} className="lastButton" onClick={toggleCaptionVisibility}>  <MdAssistant className="buttonIcon" />  <span className="buttonContent">{isCaptionVisible ? t('main', 'collapse') : t('main', 'caption')}</span> </button>
                                        {/* <button style={{ backgroundColor: '#CCCCCC', color: 'white' }} className="lastButton" onClick={() => ratePost()}>  <MdStarHalf className="buttonIcon" /> 
                                        <span style={{ marginLeft: '5px' }} className="buttonContent" >{t('main', 'ratePost')}</span>

                                    </button> */}
                                        <div>
                                            <button
                                                style={{ backgroundColor: '#8E59FF', color: 'white' }}
                                                className="lastButton"
                                                onClick={() => {
                                                    if (tokenExpiriedFace) {
                                                        ratePost();
                                                    } else {
                                                        showInstagramAccount(userId, localStorage.getItem('totenId'));
                                                        //publishCarrossel(userId, localStorage.getItem('totenId'));
                                                    }
                                                }}
                                            >
                                                <span style={{ marginLeft: '5px' }} className="buttonContent">
                                                    {tokenExpiriedFace ? (
                                                        <>
                                                            <MdStarHalf className="buttonIcon" /> {t('main', 'ratePost')}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FaInstagram size={25} className="iconInsta" /> {t('main', 'publish')}
                                                        </>
                                                    )}
                                                </span>
                                            </button>

                                        </div>
                                    </div>
                                    <div className="footer-btn-wrapper">
                                        <button onClick={() => refreshPage()}>
                                            <span className="buttonContent" > <MdRestartAlt className="buttonIcon" /> {t('main', 'goBack')}</span> </button>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    )
                }
                <ChakraProvider>
                    <CustomDrawer
                        isOpen={isOpen}
                        onClose={handleCloseDrawer}
                        handleChange={handleChange}
                        isGPT4Enabled={isGPT4Enabled}
                        onContentOpen={() => mainOpen()}
                        onAnalysisOpen={() => analysisOpen(language)}
                        onPerfilOpen={() => perfilOpen(language)}
                        onLogout={() => logout(language)}
                        onPCI={() => pciOpen(language)}
                        onHistoryOpen={() => historyOpen(language)}
                        t={t}
                        language={language}
                        setLanguage={setLanguage}
                    // isCanvaConnected={localStorage.getItem('accessToken') === 'true'}
                    />
                </ChakraProvider>
            </Swiper >
        </>
    );
}
